import React, { Component } from 'react'
import { convertPace } from 'lib-athlinks-units'
import { ICombinedInterval, IMapTrackingPresentationModel } from 'athlinks-map-model'
import moment from 'moment-timezone'

import ElevationProfile from '../elevationProfile/ElevationProfile'
import { getTimePassedFormatted, getCalendarTimeData } from '../../util/timeFormatHelper'
// import PizzaTracker from '../pizzaTracker/PizzaTracker'

const styles = require('./AthleteInfo.scss')

interface Props {
  presentationModel: IMapTrackingPresentationModel
}

export default class AthleteInfo extends Component<Props> {
  renderAthleteInfo() {
    const {
      bibNumber,
      currentEventCourseName,
      firstName,
      lastName
    } = this.props.presentationModel

    return (
      <div className={styles['athlete-info-container']}>
        <div className={styles['name-icon']}>
          {firstName[0] + lastName[0]}
        </div>
        <div className={styles['name-text']}>
          {firstName + ' ' + lastName}
        </div>
        <div className={styles['sub-text-container']}>
          <div className={styles['bib-number']}>
            {`BIB: ${bibNumber}`}
          </div>
          <div className={styles['course-name']}>
            {currentEventCourseName}
          </div>
        </div>
      </div>
    )
  }

  renderProgress() {
    const {presentationModel} = this.props
    const {overallPace, overallTime} = presentationModel
    const pace = convertPace(overallPace)

    return (
      <div className={styles['progress-container']}>
        <div className={styles['time']}>
          {overallTime.timeInMillis ? getTimePassedFormatted(overallTime.timeInMillis) : '--'}
        </div>
        <div className={styles['pace-container']}>
          <div className={styles['pace-time']}>
            {overallPace.time.timeInMillis ? pace.value : '--'}
          </div>
          <div className={styles['pace-unit']}>
            {overallPace.time.timeInMillis ? `${pace.label.toLowerCase()} avg pace` : ''}
          </div>
        </div>
        {/* <div className={styles['pizza-tracker-container']}>
          <PizzaTracker presentationModel={presentationModel}/>
        </div> */}
        <div className={styles['title']}>
          Course Elevation
        </div>
        <ElevationProfile presentationModel={this.props.presentationModel}/>
      </div>
    )
  }

  renderInterval(interval: ICombinedInterval, index) {
    const {pace, time} = interval
    const convertedPace = convertPace(pace)

    return (
      <div className={styles['split-row']} key={index + 'split'}>
        <div className={`${styles['split-name']} ${styles['split-name-text']}`}>
          {interval.splitName}
        </div>
        <div className={styles['split-time-of-day']}>
          {this.renderTimeOfDay(interval)}
          <div className={styles['split-sub-text']}>
            {
              interval.crossingDateTime
                ? moment(interval.crossingDateTime, 'x').fromNow()
                : '--'
            }
          </div>
        </div>
        <div className={styles['split-time']}>
          <div className={styles['split-time-text']}>
            {time.timeInMillis ? getTimePassedFormatted(time.timeInMillis) : '--'}
          </div>
          <div className={styles['split-sub-text']}>
            {pace.time.timeInMillis ? `${convertedPace.value} ${convertedPace.label}` : '--'}
          </div>
        </div>
      </div>
    )
  }

  renderIntervals() {
    const {racerIntervals} = this.props.presentationModel
    return (
      <div className={styles['split-container']}>
        <div className={styles['split-title']}>
          Split Timing
        </div>
        <div className={styles['split-header']}>
          <div className={`${styles['split-name']} ${styles['split-header-text']}`}>
            Split Name
          </div>
          <div className={`${styles['split-header-time-of-day']}`}>
            Time of Day
          </div>
          <div className={`${styles['split-time']} ${styles['split-header-text']}`}>
            Time
          </div>
        </div>
        {racerIntervals.map((interval, idx) => this.renderInterval(interval, idx))}
      </div>
    )
  }

  renderTimeOfDay(interval: ICombinedInterval) {
    const { eventTimeZone } = this.props.presentationModel

    if (interval.crossingDateTime) {

      const calendarData = getCalendarTimeData(interval.crossingDateTime, eventTimeZone)

      return (
        <div>
          <div className={`${styles['split-time-text']} ${styles['inline-block']}`}>
            {calendarData.time}
          </div>
          {' '}
          <div className={`${styles['split-header-text']} ${styles['inline-block']}`}>
            {calendarData.meridiem} {calendarData.timeZone}
          </div>
        </div>
      )
    }
    return (
      <div className={`${styles['split-time-text']} ${styles['inline-block']}`}>
        --
      </div>
    )
  }

  render() {
    return (
      <div className={styles['container']} style={{
        // ugh.. iOS chrome hides content behind the navbar
        paddingBottom: navigator.userAgent.match('CriOS') ? 100 : 0
      }}>
        {this.renderAthleteInfo()}
        {this.renderProgress()}
        {this.renderIntervals()}
      </div>
    )
  }
}
