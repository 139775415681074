
import { flow, isNumber, join, partialRight } from 'lodash'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'
import {
  getPassedSplitSvg,
  getUpcomingSplitSvg,
  getNextSplitSvg,
  getAnimationSvg
} from './svgHelpers'
import {
  ICombinedInterval, ICoordinate
} from 'athlinks-map-model'
import {
  ICourseSplit
} from 'athlinks-map-model/dist/models/presentation/CourseSplits'
import {
  Map,
  Marker,
  PopupOptions,
  MarkerOptions,
  Popup
} from 'mapbox-gl'
import {
  Maybe
} from 'monet'
import {
  reduce,
  union
} from 'ramda'
import { getFinishedAgoLabel, formatTimeWithTimeZone } from './timeFormatHelper'

export const splitIndicatorPositing = {
  offset: [17, -20],
  anchor: 'top-left'
} as MarkerOptions

const createMarker = (innerHtml: string): HTMLElement => {
  const markerElement = document.createElement('div')
  markerElement.innerHTML = innerHtml
  markerElement.className = 'marker'
  return markerElement
}

const getStartText = () => 'START'

const getFinishText = () => 'FINISH'

export const getStartIndicator = () =>
  createMarker(`<div class="startIndicator">${getStartText()}</div>`)

export const getFinishIndicator = () =>
  createMarker(`<div class="finishIndicator">${getFinishText()}</div>`)

export const getSplitIconMarker = (passed: boolean) => flow(
  () => passed ? getPassedSplitSvg() : getUpcomingSplitSvg(),
  createMarker
)()

export const getNextSplitIconMarker = () =>
  flow(getNextSplitSvg, createMarker)()

export const passedSplitTimeSummary = (
  crossingTime: number,
  timeZone: string) =>
    [
      `<div class="passedSplitTimeSummary">
        ${crossingTime > 0
          ? `PASSED AT: ${formatTimeWithTimeZone(crossingTime, timeZone)}<br/>
            (${getFinishedAgoLabel(crossingTime)})`
          : 'NO TIMING DATA AVAILABLE'
        }
      </div>`
    ]

export const passedSplitSummary = (
  eventTimeZone: string,
  racerInitials: string,
  crossingTimeInMillis: number,
  splitName: string,
  nextSplitName?: string
): HTMLElement => flow([
  partialRight(join, ''),
  createMarker
])(
  reduce(
    (acc, item) => union(acc, item),
    [],
    [
      [
        `<div class="passedSplitArrowIcon"></div>`
      ],
      (nextSplitName || splitName)
        ? [
            `<div title="${nextSplitName || splitName}" class="passedSplitIntervalSummary">
              ${splitName}
            </div>`
          ]
        : [],
      racerInitials
        ? [
            `<span class="initialsBadge">${racerInitials}</span>`
          ]
        : [],
      isNumber(crossingTimeInMillis)
        ? passedSplitTimeSummary(crossingTimeInMillis, eventTimeZone)
        : []
    ]
  )
)

export const getJustPassedSplitSummary = ({
  nextIntervalName,
  racerInitials,
  justPassedIntervalTimeMillis,
  eventTimeZone
}: IMapTrackingPresentationModel,
  justPassedIntervalName: string
) => passedSplitSummary(
  eventTimeZone,
  racerInitials,
  justPassedIntervalTimeMillis,
  justPassedIntervalName,
  nextIntervalName)

export const getNextSplitSummary = (nextIntervalName: string): HTMLElement => flow([
  partialRight(join, ''),
  createMarker
])([
  `<div class="passedSplitArrowIcon"></div>`,
  `<div class="passedSplitIntervalSummary">${nextIntervalName}</div>`
])

export const getAnimationMarker = (bearing: number) => flow(
  () => getAnimationSvg(bearing),
  createMarker
)()

export const markerAdder = (map: Map) => (marker: Marker): Marker =>
  marker.addTo(map)

export const createMapboxMarker = (
  coord: ICoordinate
) => (
  html: HTMLElement,
  options?: MarkerOptions
): Marker =>
    new Marker(html, options)
      .setLngLat([coord.long, coord.lat])

export const locateRacerInterval = (
  racerIntervals: ICombinedInterval[]
) => (
  splitName: string
): Maybe<ICombinedInterval> =>
    Maybe.fromUndefined(
      racerIntervals
        .filter((racerInterval: ICombinedInterval) =>
          racerInterval.splitName === splitName
        )[0]
    )

export const createPopupMarker = (
  popUpOptions: PopupOptions
) => (
  marker: Marker
) => (
  popUpHtml: HTMLElement
): Marker =>
      marker.setPopup(
        new Popup(popUpOptions)
          .setHTML(popUpHtml.innerHTML)
      )

export const attachPopupMarker =
  createPopupMarker({
    ...splitIndicatorPositing,
    className: 'split-context-popup'
  } as PopupOptions)

export const createRacerSplitMarker = (
  eventTimeZone: string,
  racerInitials: string,
  racerIntervals: ICombinedInterval[]
) => (
  split: ICourseSplit
): Maybe<Marker> =>
    locateRacerInterval(racerIntervals)(split.intervalName)
      .map((racerInterval: ICombinedInterval) => {
        const marker =
          createMapboxMarker(split.coordinates)(
            getSplitIconMarker(split.hasCompleted)
          )

        return (split.hasCompleted) ?
          attachPopupMarker
            (marker)
            (passedSplitSummary(
              eventTimeZone,
              racerInitials,
              racerInterval.crossingDateTime,
              split.intervalName)
            )
          : marker
      })
