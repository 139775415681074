import { Action } from 'redux'
import { actionTypes } from './types'

export interface IUpdateShowLegendAction extends Action {
  type: actionTypes.UPDATE_SHOW_LEGEND,
  showLegend: boolean,
}

export const updateShowLegend = (
  showLegend: boolean
): IUpdateShowLegendAction => ({
  type: actionTypes.UPDATE_SHOW_LEGEND,
  showLegend
})
