import React from 'react'

export default function() {
  return (
    <svg width='106px' height='28px' viewBox='0 0 106 28'>
      <title>athlinks-logo</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <filter x='-10.5%' y='-47.7%' width='121.0%' height='195.5%'
            filterUnits='objectBoundingBox' id='filter-1'>
            <feOffset dx='0' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset>
            <feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1'
                result='shadowBlurOuter1'></feGaussianBlur>
            <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.35 0'
                type='matrix' in='shadowBlurOuter1'
                result='shadowMatrixOuter1'></feColorMatrix>
            <feMerge>
                <feMergeNode in='shadowMatrixOuter1'></feMergeNode>
                <feMergeNode in='SourceGraphic'></feMergeNode>
            </feMerge>
        </filter>
      </defs>
      <g id='Map-Exploration-Athlinks-Logo' stroke='none'
        strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Map-Tracking-Full-Screen'
          transform='translate(-1722.000000, -34.000000)' fill='#FFFFFF' fillRule='nonzero'>
          <g id='athlinks-logo' filter='url(#filter-1)'
            transform='translate(1725.000000, 35.000000)'>
            <path d='M4.93975904,21.2666667 C3.97590361,20.2277778 3.01204819,18.3944444
            4.25301205,15.7972222 C4.25301205,15.7972222 4.87951807,22.1711111
            9.84337349,21.9938889 C14.8192771,21.8166667 17.4698795,17.9666667
            17.4698795,13.5055556 C17.4698795,13.5055556 17.5,12.1855556
            16.8493976,10.5966667 C16.8493976,10.5966667 16.9457831,13.31 14.2409639,14.7033333
            C14.2409639,14.7033333 19.1325301,7.84666667 13,3.27555556 C13,3.27555556
            13.9759036,5.89111111 12.2168675,6.75888889 C12.2168675,6.75888889
            11.7590361,0.831111111 5.28915663,0.464444444 C5.28915663,0.464444444
            9.44578313,4.24111111 6.14457831,9.735 C6.14457831,9.735 6.43373494,5.76277778
            2.68072289,5.92777778 C2.68072289,5.92777778 4.53012048,7.63888889
            2.51807229,9.83888889 C0.0361445783,12.5522222 0.512048193,15.9194444
            0.963855422,16.9888889 C1.76506024,18.8344444 3.1746988,20.3377778
            4.93975904,21.2666667' id='Shape'></path>
            <path d='M25.9457831,8.17666667 C25.9457831,8.02388889 25.8855422,7.35777778
            25.6807229,7.35777778 C25.5301205,7.35777778 25.4698795,8.11555556
            25.4698795,8.23777778 C25.3192771,10.5966667 25.2289157,12.1122222
            25.0843373,14.0433333 C25.0843373,14.2572222 25.2289157,14.2877778
            25.686747,14.2877778 C26.0662651,14.2877778 26.3674699,14.3488889
            26.3373494,14.0738889 C26.186747,11.8372222 26.0963855,10.5661111
            25.9457831,8.17666667 M28.6566265,21.67 C27.7951807,21.67
            27.2289157,21.7311111 27.1686747,21.3033333 C26.9277108,19.6105556
            26.8072289,18.4616667 26.5662651,16.7994444 C26.5421687,16.4938889
            26.186747,16.555 25.7108434,16.555 C25.1445783,16.555 24.8433735,16.4938889
            24.8072289,16.7994444 C24.6385542,18.4861111 24.5180723,19.6105556
            24.3674699,21.3583333 C24.3373494,21.6944444 23.6807229,21.6638889
            22.7289157,21.6638889 C22.2771084,21.6638889 21.2349398,21.6638889
            21.2650602,21.3888889 C22.3674699,13.5055556 23.1445783,8.20722222
            24.126506,0.916666667 C24.186747,0.488888889 25.0301205,0.464444444
            25.6807229,0.464444444 C26.4216867,0.464444444 27.1987952,0.495
            27.2891566,1.01444444 C28.4216867,8.75111111 29.1084337,13.4688889
            30.3012048,21.3644444 C30.3614458,21.7005556 29.4578313,21.67
            28.6626506,21.67 M38.3975904,3.45888889 L37.1084337,3.45888889
            C36.7831325,3.45888889 36.3975904,3.45888889 36.246988,3.52 C35.9819277,3.72777778
            36.0421687,4.25333333 36.0421687,4.63833333 L36.0421687,21.3583333
            C36.0421687,21.6333333 35.7108434,21.6638889 34.313253,21.6638889
            C33.060241,21.6638889 32.7891566,21.6638889 32.7891566,21.3583333
            L32.7891566,4.64444444 C32.7891566,4.24722222 32.8493976,3.72777778
            32.5843373,3.52 C32.4277108,3.45888889 32.0421687,3.45888889 31.7108434,3.45888889
            L30.6084337,3.45888889 C30.1626506,3.45888889 30.0662651,3.245
            30.0662651,1.97388889 C30.0662651,0.702777778 30.126506,0.495 30.4879518,0.495
            L38.373494,0.495 C38.7228916,0.495 38.753012,0.77 38.753012,1.91888889
            C38.753012,3.31222222 38.7228916,3.465 38.3915663,3.465 M47.4879518,21.6333333
            C45.9096386,21.6333333 45.8493976,21.6088889 45.8493976,21.0344444
            L45.8493976,13.0166667 C45.8493976,12.65 45.8493976,12.3444444 45.5903614,12.32
            C45.2289157,12.2283333 43.9216867,12.2894444 43.8012048,12.32 C43.560241,12.4116667
            43.560241,12.6866667 43.560241,13.0777778 L43.560241,21.1566667
            C43.560241,21.6455556 43.4698795,21.6455556 41.9216867,21.6455556
            C40.4578313,21.6455556 40.3975904,21.6455556 40.3975904,21.2177778
            L40.3975904,1.06944444 C40.3975904,0.617222222 40.4879518,0.519444444
            41.9216867,0.519444444 C43.5,0.519444444 43.5903614,0.617222222 43.5903614,1.1
            L43.5903614,8.17666667 C43.5903614,8.69 43.5903614,9.03222222 43.9759036,9.15444444
            C44.2409639,9.17888889 45.2831325,9.23388889 45.6144578,9.15444444
            C45.8554217,9.05666667 45.8554217,8.72666667 45.8554217,8.26833333
            L45.8554217,0.977777778 C45.8554217,0.464444444 46.0361446,0.525555556
            47.4578313,0.525555556 C48.9819277,0.525555556 49.0722892,0.525555556
            49.0722892,1.01444444 L49.0722892,21.0222222 C49.0722892,21.5966667
            49.0722892,21.6333333 47.4879518,21.6333333 M58.313253,21.67 L52.5903614,21.67
            C52.1686747,21.67 51.746988,21.67 51.6024096,21.4866667 C51.4216867,21.2727778
            51.4578313,20.8144444 51.4578313,20.4294444 L51.4578313,0.977777778
            C51.4578313,0.55 51.6024096,0.488888889 52.9457831,0.488888889
            C54.4638554,0.488888889 54.6325301,0.525555556 54.6325301,1.00833333
            L54.6325301,17.5266667 C54.6325301,17.9177778 54.6084337,18.4311111
            54.813253,18.5838889 C54.9698795,18.6816667 55.3253012,18.7061111
            55.626506,18.7061111 L58.2771084,18.7061111 C58.6626506,18.7061111
            58.6987952,18.8588889 58.6987952,20.13 C58.6987952,21.5844444
            58.6626506,21.6761111 58.3072289,21.6761111 M61.8674699,21.6333333
            C60.4036145,21.6333333 60.3433735,21.6333333 60.3433735,21.2177778
            L60.3433735,1.00833333 C60.3433735,0.556111111 60.4638554,0.519444444
            61.8614458,0.519444444 C63.4277108,0.519444444
            63.5240964,0.556111111 63.5240964,1.03888889 L63.5240964,21.1566667
            C63.5240964,21.6088889 63.4939759,21.6455556 61.8554217,21.6455556
            M73.5783133,21.67 C72.8072289,21.67 72.4759036,21.6088889 72.2710843,20.8511111
            C71.2831325,17.3433333 70.9277108,15.7422222 69.8855422,11.9288889
            C69.7048193,11.2566667 69.4638554,10.1444444 69.2650602,10.1444444
            C69.0542169,10.1444444 69.0542169,10.78 69.0542169,11.1711111
            L69.0542169,21.1322222 C69.0542169,21.6088889 68.9638554,21.67
            67.4156627,21.67 C65.9216867,21.67 65.9216867,21.6088889
            65.9216867,21.1566667 L65.9216867,0.916666667 C65.9216867,0.488888889
            65.9819277,0.488888889 67.2951807,0.488888889 C68.5783133,0.488888889
            68.753012,0.611111111 68.9638554,1.46666667 C69.626506,3.94166667
            70.4879518,7.18055556 71.373494,10.3888889 C71.4698795,10.6944444
            71.5542169,11.1711111 71.7349398,11.1711111 C71.9457831,11.1711111
            71.9457831,10.5966667 71.9457831,10.2666667 L71.9457831,1.03888889
            C71.9457831,0.55 72.0361446,0.488888889 73.5301205,0.488888889
            C75.0481928,0.488888889 75.0783568,0.525555556 75.0783568,1.00833333
            L75.0783568,20.79 C75.0783568,21.5844444 75.1084337,21.67 73.5843373,21.67
            M85.3313253,21.67 C83.9879518,21.67 83.7168675,21.7311111 83.6024096,21.2422222
            C82.7951807,17.9788889 82.2891566,15.9866667 81.5120482,12.7172222
            C81.4518072,12.4483333 81.246988,12.3872222 80.8915663,13.2061111
            C80.7108434,13.6338889 80.6506024,14.0188889 80.6506024,14.4772222
            L80.6506024,21.1383333 C80.6506024,21.615 79.9096386,21.6761111
            79.0421687,21.6761111 C78.2590361,21.6761111 77.4638554,21.615
            77.4638554,21.1872222 L77.4638554,0.959444444 C77.4638554,0.562222222
            78.3554217,0.531666667 79.0120482,0.531666667 C79.6987952,0.531666667
            80.6506024,0.531666667 80.6506024,0.983888889 L80.6506024,8.305
            C80.6506024,8.51888889 80.626506,8.97722222 80.8012048,8.91611111
            C80.8915663,8.87944444 80.9518072,8.54944444 81.0120482,8.33555556
            C81.8433735,5.43277778 82.2349398,3.85611111 83.060241,0.922777778
            C83.1566265,0.592777778 83.3373494,0.617222222 84.7349398,0.617222222
            C86.1987952,0.617222222 86.4698795,0.739444444 86.4096386,0.922777778
            C85.3855422,4.16166667 85.0060241,5.43277778 83.9638554,8.51888889
            C83.8433735,8.84888889 83.753012,9.20944444 83.753012,9.515
            C83.753012,9.78388889 83.813253,10.0283333 83.9638554,10.6027778
            C85.126506,14.7155556 85.6927711,16.8361111 86.9156627,21.3094444
            C86.9457831,21.4927778 86.9156627,21.6761111 85.3313253,21.6761111
            M96.2650602,19.1583333 C95.2590361,21.0038889 94.5783133,21.6638889
            92.4277108,21.6638889 C89.9819277,21.6638889 88.1024096,20.185
            88.1024096,17.435 L88.1024096,15.7361111 C88.1024096,15.4672222
            88.3674699,15.4305556 89.560241,15.4305556 C90.753012,15.4305556
            91.3192771,15.4305556 91.3192771,15.675 L91.3192771,16.9583333
            C91.3192771,18.0705556 91.5240964,18.7305556 92.4216867,18.7305556
            C93.2228916,18.7305556 93.4337349,18.1316667 93.4939759,17.6122222
            C93.5301205,17.0988889 93.5542169,16.6466667 93.5542169,16.225
            C93.5542169,13.5972222 87.9216867,9.93055556 87.9216867,5.39611111
            C87.9216867,4.54055556 88.2831325,3.15333333 88.6686747,2.46277778
            C89.0481928,1.76611111 90.1144578,0.495 92.1506024,0.495
            C94.6807229,0.495 96.3855422,2.40777778 96.3855422,4.61388889
            L96.3855422,6.09277778 C96.3855422,6.30666667 95.9638554,6.27611111
            94.9578313,6.27611111 C93.8253012,6.27611111 93.2289157,6.27611111
            93.2289157,6.06833333 L93.2289157,4.66888889 C93.2289157,3.81333333
            92.6626506,3.30611111 92.1566265,3.30611111 C91.6144578,3.30611111
            91.2048193,3.795 91.1445783,4.28388889 C91.0843373,4.73611111
            91.0542169,5.09666667 91.0542169,5.49388889 C91.0542169,9.06277778
            96.746988,11.5072222 96.746988,16.4327778 C96.746988,17.3494444
            96.626506,18.4983333 96.2650602,19.1644444 M96.7228916,21.2055556
            L96.9036145,21.1811111 C96.9036145,21.2544444 96.9277108,21.3155556
            96.9638554,21.3644444 C96.9939759,21.4072222 97.0421687,21.4438889
            97.1024096,21.4744444 C97.1626506,21.505 97.2349398,21.5172222
            97.3192771,21.5172222 C97.3915663,21.5172222 97.4518072,21.5172222
            97.5,21.4927778 C97.560241,21.4683333 97.6024096,21.4316667
            97.6325301,21.4011111 C97.6566265,21.3705556 97.6746988,21.3277778
            97.6746988,21.2788889 C97.6746988,21.2361111 97.6566265,21.1994444
            97.6325301,21.1566667 C97.6084337,21.1322222 97.5722892,21.0955556
            97.5120482,21.0772222 L97.253012,21.0161111 C97.1204819,20.9794444
            97.0301205,20.955 96.9759036,20.9183333 C96.9036145,20.8877778
            96.8554217,20.845 96.8192771,20.7961111 C96.7831325,20.735
            96.7590361,20.6738889 96.7590361,20.6127778 C96.7590361,20.5333333
            96.7891566,20.4661111 96.8313253,20.405 C96.8674699,20.3438889
            96.9277108,20.2827778 97.0120482,20.2583333 C97.0843373,20.2277778
            97.1746988,20.2094444 97.2710843,20.2094444 C97.3674699,20.2094444
            97.4638554,20.2277778 97.5421687,20.2705556
            C97.626506,20.295 97.686747,20.35 97.7228916,20.4111111
            C97.7710843,20.4844444 97.7951807,20.5577778 97.8012048,20.6433333
            L97.6204819,20.6555556 C97.6084337,20.5638889 97.5783133,20.4966667
            97.5180723,20.4477778 C97.4698795,20.3988889 97.3855422,20.3866667
            97.2771084,20.3866667 C97.1566265,20.3866667 97.0783133,20.3988889
            97.0361446,20.4477778 C96.9759036,20.4844444 96.9518072,20.5333333
            96.9518072,20.5944444 C96.9518072,20.6555556 96.9698795,20.6922222
            97.0120482,20.7166667 C97.0421687,20.7594444 97.1325301,20.79
            97.2891566,20.8266667 C97.4457831,20.8633333 97.5481928,20.8877778
            97.6024096,20.9183333 C97.686747,20.9611111 97.746988,21.01
            97.7831325,21.065 C97.8313253,21.1261111 97.8433735,21.1872222
            97.8433735,21.2727778 C97.8433735,21.3522222 97.8253012,21.4255556
            97.7831325,21.4927778 C97.7409639,21.5538889 97.6746988,21.615
            97.6024096,21.6516667 C97.5180723,21.6883333 97.4216867,21.7127778
            97.3192771,21.7127778 C97.1987952,21.7127778 97.0783133,21.6883333
            96.9939759,21.6516667 C96.9096386,21.6088889 96.8433735,21.5538889
            96.7891566,21.4805556 C96.7289157,21.4011111 96.7108434,21.3155556
            96.7048193,21.2177778 M98.1144578,21.6638889 L98.1144578,20.2155556
            L98.3975904,20.2155556 L98.7349398,21.2422222 C98.7650602,21.34
            98.7951807,21.4072222 98.7951807,21.4561111 L98.8795181,21.23
            L99.2228916,20.2216667 L99.4819277,20.2216667 L99.4819277,21.67
            L99.3012048,21.67 L99.3012048,20.46 L98.8795181,21.67
            L98.7168675,21.67 L98.2951807,20.4355556 L98.2951807,21.67
            L98.1144578,21.67' id='Shape'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}