import React from 'react'

import mapboxgl, {
  Marker
} from 'mapbox-gl'
import { pipe } from 'ramda'
import { Dispatch } from 'redux'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  getCompletedSplitsLine,
  getCurrentSplitLine,
  getUpcomingSplitsLine
} from '../../util/mapHelpers'
import {
  getAnimationMarker,
  getFinishIndicator,
  getJustPassedSplitSummary,
  getNextSplitIconMarker,
  getNextSplitSummary,
  getStartIndicator,
  createMapboxMarker,
  markerAdder,
  createRacerSplitMarker,
  getSplitIconMarker,
  splitIndicatorPositing
} from '../../util/markerHelpers'
import {
  IMapTrackingPresentationModel,
  ICourseSplit,
  TrackPoint
} from 'athlinks-map-model'
import { head, isEqual } from 'lodash'
import { renderCenterIcon } from './renderCenterIcon'
import { getAppConfig } from '../../config'
import { IAppState } from '../../models/appState'
import { updateShowLegend } from '../../actions/updateShowLegendAction'

const styles = require('./Mapbox.scss')
require('./PopupMarker.scss')

const appConfig = getAppConfig()

mapboxgl.accessToken = appConfig.accessToken

interface Props {
  appState: IAppState
  dispatch: Dispatch
  height?: number
  presentationModel: IMapTrackingPresentationModel
}

const delayBetweenAnimations = 100

type MarkerMaker = (x0: HTMLElement, x1: mapboxgl.MarkerOptions) => Marker

export class Mapbox extends React.Component<Props> {
  animationCounter = 0
  animationMarkers = []
  intervalMarkers = []
  map: any
  mapboxGL: any
  mapHasLoaded = false
  mapRef: any
  mapResizeTimeout: any

  constructor(props: Props) {
    super(props)
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    const { centerCoordinate } = this.props.presentationModel

    this.map = new mapboxgl.Map({
      container: this.mapRef,
      style: appConfig.styleUrl,
      center: [centerCoordinate.long, centerCoordinate.lat],
      zoom: appConfig.zoomLevel,
      maxZoom: 17,
      minZoom: 10
    })

    this.map.on('load', () => {
      this.drawMap()
      this.animateCurrentInterval()
    })
  }

  componentDidUpdate(prev: Props) {
    if (prev.height !== this.props.height) {
      if (this.mapResizeTimeout) {
        clearTimeout(this.mapResizeTimeout)
      }
      this.mapResizeTimeout = setTimeout(() => {
        this.map.resize()
      }, 100)
    }
    if (isEqual(prev.presentationModel, this.props.presentationModel)) {
      this.drawSplits()
      return
    }
    this.drawMap()
  }

  componentWillUnmount() {
    this.map.remove()
  }

  clearCanvas() {
    ['current-split', 'upcoming-split', 'past-split']
      .forEach((layerName) => {
        if (this.map.getLayer(layerName)) {
          this.map.removeLayer(layerName)
          this.map.removeSource(layerName)
        }
      })
  }

  clearSplits() {
    this.intervalMarkers.forEach((marker) => { marker.remove() })
    this.intervalMarkers = []
  }

  mapMarkerMaker = (markerMaker: MarkerMaker): MarkerMaker =>
    pipe(
      markerMaker,
      markerAdder(this.map)
    )

  renderSplit = (
    model: IMapTrackingPresentationModel
  ) => (
    split: ICourseSplit
  ) => {
    const markerMaker =
      this.mapMarkerMaker(createMapboxMarker(split.coordinates))

    const ivName = split.intervalName

    if (split.id === model.nextIntervalId) {
      this.intervalMarkers.push(markerMaker(getNextSplitIconMarker(), {}))
      this.intervalMarkers.push(
        markerMaker(getNextSplitSummary(ivName), splitIndicatorPositing)
      )
      return
    }

    if (split.id === model.justPassedIntervalId) {

      this.intervalMarkers.push(markerMaker(getSplitIconMarker(true), {}))
      this.intervalMarkers.push(
        markerMaker(getJustPassedSplitSummary(model, ivName), splitIndicatorPositing)
      )
      return
    }

    createRacerSplitMarker(
      model.eventTimeZone,
      model.racerInitials,
      model.racerIntervals)(split)
      .map((splitMarker) => splitMarker.addTo(this.map)
    )
  }

  drawMap() {
    const model = this.props.presentationModel

    this.clearCanvas()
    this.map.addLayer(getCurrentSplitLine(model))
    this.map.addLayer(getCompletedSplitsLine(model))
    this.map.addLayer(getUpcomingSplitsLine(model))

    this.drawSplits()

    if (this.mapHasLoaded) {
      return
    }

    this.centerMap()

    this.mapMarkerMaker(createMapboxMarker(model.startingCoordinate))
      (getStartIndicator(), { offset: [-15, 15] })
    this.mapMarkerMaker(createMapboxMarker(model.endingCoordinate))
      (getFinishIndicator(), { offset: [-20, 15] })

    this.mapHasLoaded = true
  }

  drawSplits() {
    const model = this.props.presentationModel

    this.clearSplits()

    const renderSplitFunc = this.renderSplit(model)
    model.courseSplits.forEach(renderSplitFunc)
  }

  centerMap = () => {
    const { boundingBox, centerCoordinate } = this.props.presentationModel
    // skip for [0, 0, 0, 0]
    if (boundingBox && boundingBox.filter(x => x).length) {
      this.map.fitBounds(
        new mapboxgl.LngLatBounds(boundingBox as [number, number, number, number]),
        { padding: 75 }
      )
    }
    else this.map.setCenter([centerCoordinate.long, centerCoordinate.lat])
  }

  clearAnimations = () => {
    this.animationMarkers.forEach((animationMarker) => { animationMarker.remove() })
    this.animationMarkers = []
  }

  addAnimationMarker = (trackingPoints: TrackPoint[]) => {
    const trackPoint = trackingPoints[this.animationCounter]
    if (!trackPoint) {
      return
    }

    const marker = this.mapMarkerMaker(createMapboxMarker(trackPoint))
      (getAnimationMarker(trackPoint.bearing), {})

    this.animationMarkers.push(marker)
  }

  animateCurrentInterval = () => {

    const { trackingPoints } = this.props.presentationModel

    if (trackingPoints.length === 0) {
      setTimeout(this.animateCurrentInterval, 2000)
      return
    }

    if (this.animationCounter === head(trackingPoints).length) {
      this.animationCounter = 0
    }

    this.clearAnimations()
    trackingPoints.forEach(this.addAnimationMarker)

    this.animationCounter++
    setTimeout(this.animateCurrentInterval, delayBetweenAnimations)
  }

  onLegendToggleClicked = () =>
    this.props.dispatch(updateShowLegend(!this.props.appState.showLegend))

  onRecenterClicked = () => {
    this.centerMap()
  }

  render() {
    return (
      <div className={styles.container}>
        <div ref={div => this.mapRef = div} className={styles.mapBoxContainer} />
        <div onClick={() => this.map.zoomIn()} className={styles.zoomInBtn}>+</div>
        <div onClick={() => this.map.zoomOut()} className={styles.zoomOutBtn}>-</div>
        <div onClick={this.onRecenterClicked} className={styles.centerBtn}>
          {renderCenterIcon()}
        </div>
        <div onClick={this.onLegendToggleClicked} className={styles.legendToggle}>
          {this.props.appState.showLegend ? 'X' : '?'}
        </div>
      </div>
    )
  }
}
