import React, { Component } from 'react'
import { Button } from 'athlinks-component-library'
import { Cell, Grid } from 'react-foundation'
import { Dispatch } from 'redux'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'
import moment from 'moment'
import Dialog from './Dialog'
import {
  getDurationUntilNow,
  formatTimeWithTimeZone
} from '../../util/timeFormatHelper'
import { IAppState } from '../../models/appState'
import { updateRaceNotStartedDismissed } from '../../actions/updateRaceNotStartedDismissed'

const genericStyles = require('./Dialogs.scss')
const styles = require('./RaceHasNotStartedDialog.scss')

interface Props {
  appState: IAppState
  dispatch: Dispatch
  hasGottenTrackingInfo: boolean
  isMobile?: boolean
  model: IMapTrackingPresentationModel
  returnUrl: string
}

export class RaceHasNotStartedDialog extends Component<Props> {
  onViewMapClicked = () => this.props.dispatch(updateRaceNotStartedDismissed(true))

  raceNotStarted = () => this.props.model.courseStartTimeInMilliseconds > new Date().getTime()

  showDialog = () => this.props.hasGottenTrackingInfo
    && this.raceNotStarted()
    && !this.props.appState.raceNotStartedDismissed

  renderBackToMapBtn = () => (
    <button className='clearButton' onClick={this.onViewMapClicked}>VIEW MAP</button>
  )

  renderViewMapOnlyBtns = () => (
    <div className={styles.buttonsHeader}>{this.renderBackToMapBtn()}</div>
  )

  renderViewMapAndResultsBtns = () => (
    <Grid className={styles.buttonsHeader}>
      <Cell medium={6}>
        <div className={genericStyles.viewMapBtnContainer}>
          {this.renderBackToMapBtn()}
        </div>
      </Cell>
      <Cell medium={6}>
        <div className={genericStyles.viewResultsBtnContainer}>
          <Button target='_blank' text='GO BACK'
             route={this.props.returnUrl} color='dark-blue' />
        </div>
      </Cell>
    </Grid>
  )

  renderCountdown = (duration: moment.Duration) => (
    <Grid className={genericStyles.textCenter}>
      <Cell small={4}>
        <div className={styles.countDownBorderRight}>{duration.days()}</div>
        <div className={styles.coundDownLabel}>DAYS</div>
      </Cell>
      <Cell small={4}>
        <div className={styles.countDownBorderRight}>{duration.hours()}</div>
        <div className={styles.coundDownLabel}>HOURS</div>
      </Cell>
      <Cell small={4}>
        <div className={styles.countDown}>{duration.minutes()}</div>
        <div className={styles.coundDownLabel}>MINUTES</div>
      </Cell>
    </Grid>
  )

  renderButtons() {
    return this.props.returnUrl
      ? this.renderViewMapAndResultsBtns()
      : this.renderViewMapOnlyBtns()
  }

  renderContent() {
    const {
      model: {
        courseStartTimeInMilliseconds,
        eventTimeZone
      }
    } = this.props

    const duration = getDurationUntilNow(courseStartTimeInMilliseconds)

    return (
      <div>
        <div className={genericStyles.mainHeader}>
          The race hasn't started yet
        </div>
        <div className={genericStyles.detailsHeader}>
          The race is scheduled to start in...
        </div>
        <div className={styles.startTimeText}>
          {formatTimeWithTimeZone(courseStartTimeInMilliseconds, eventTimeZone)}
        </div>
        {this.renderCountdown(duration)}
      </div>
    )
  }

  render() {
    const {isMobile} = this.props

    if (this.showDialog()) {
      return (
        <Dialog
          buttons={this.renderButtons()}
          closeAction={this.onViewMapClicked}
          content={this.renderContent()}
          isMobile={isMobile}/>
      )
    }

    return null
  }
}
