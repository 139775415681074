import React, { Component } from 'react'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'

import AthleteInfo from '../../components/athleteInfo/AthleteInfo'
import { extractQueryParams, extractUrlParams } from '../../util/urlHelpers'
import { IRouteProps } from '../../models/routeProps'
import { miamiCourseJson } from '../../data/miamiGeoJson'

const styles = require('./TestPage.scss')

// Feel free to use this page to do any sort of testing you need

interface ObjectParams {
  [index: string]: any
}
interface Props extends IRouteProps {}

export default class TestPage extends Component<Props> {
  combineIntervalCoordinates(intervalFeatures) {
    return [].concat.apply([], intervalFeatures.map(feature => {
      return feature.geometry.coordinates
    }))
  }

  makePresentationModel(): IMapTrackingPresentationModel {
    const intervalFeatures = miamiCourseJson.features.filter(feature => {
      return feature.properties.athlinksFeatureType === 'INTERVAL'
    })

    return {
      coordinatesForCompletedIntervals: this.combineIntervalCoordinates(
        intervalFeatures.slice(0, 3)
      ),
      coordinatesForCurrentInterval: this.combineIntervalCoordinates(
        intervalFeatures.slice(3, 7)
      ),
      coordinatesForUpcomingIntervals: this.combineIntervalCoordinates(
        intervalFeatures.slice(7, 11)
      )
    } as IMapTrackingPresentationModel
  }

  renderObject(objectParams: ObjectParams) {
    return Object.keys(objectParams).map((param, idx) => {
      return (
        <div key={idx}>
          {`${param}: ${objectParams[param]}`}
        </div>
      )
    })
  }

  render() {
    const queryParams = extractQueryParams(this.props)
    const urlParams = extractUrlParams(this.props)
    const testPresentationModel = this.makePresentationModel()

    console.log('--- Props ---> ', {props: this.props, queryParams, urlParams})

    return (
      <div className={styles['container']}>
        <div className={styles['athlete-info']}>
          <AthleteInfo presentationModel={testPresentationModel}/>
        </div>
        <div className={styles['map-container']}>
          <h2>PARAMS</h2>
          {this.renderObject(urlParams)}
          <h2>QUERY PARAMS</h2>
          {this.renderObject(queryParams)}
        </div>
      </div>
    )
  }
}
