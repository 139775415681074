import React, { Component } from 'react'
import { Button } from 'athlinks-component-library'
import { Cell, Grid } from 'react-foundation'
import { Dispatch } from 'redux'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'

import Dialog from './Dialog'
import {
  getFinishedAgoLabel,
  getTimePassedFormatted,
  formatTimeWithTimeZone
} from '../../util/timeFormatHelper'
import { IAppState } from '../../models/appState'
import { updateRacerHasFinishedDismissed } from '../../actions/updateRacerHasFinishedDismissed'

const genericStyles = require('./Dialogs.scss')
const styles = require('./RacerHasFinishedDialog.scss')

interface Props {
  appState: IAppState
  dispatch: Dispatch
  hasGottenTrackingInfo: boolean
  isMobile?: boolean
  model: IMapTrackingPresentationModel
  returnUrl: string
}

export class RacerHasFinishedDialog extends Component<Props> {
  onViewMapClicked = () => this.props.dispatch(updateRacerHasFinishedDismissed(true))

  renderBackToMapBtn = () => (
    // TODO: Use the button component here
    <button className='clearButton' onClick={this.onViewMapClicked}>BACK TO MAP</button>
  )

  renderViewMapOnlyBtns = () => (
    <div className={styles.buttonsHeader}>{this.renderBackToMapBtn()}</div>
  )

  renderViewMapAndResultsBtns = () => (
    <Grid className={styles.buttonsHeader}>
      <Cell medium={6}>
        <div className={genericStyles.viewMapBtnContainer}>
          {this.renderBackToMapBtn()}
        </div>
      </Cell>
      <Cell medium={6}>
        <div className={genericStyles.viewResultsBtnContainer}>
          <Button target='_blank' text='View Result'
            route={this.props.returnUrl} color='dark-blue' />
        </div>
      </Cell>
    </Grid>
  )

  renderButtons() {
    return this.props.returnUrl
      ? this.renderViewMapAndResultsBtns()
      : this.renderViewMapOnlyBtns()
  }

  renderContent() {
    const {
      model: {
        firstName,
        justPassedIntervalTimeMillis,
        overallTime,
        overallBracketRankType,
        eventTimeZone
      }
    } = this.props

    return (
      <div>
        <div className={genericStyles.mainHeader}>
          <strong>{firstName}</strong> has finished!
        </div>
        <div className={styles.finalTimeHeader}>
          FINAL TIME ({overallBracketRankType})
        </div>
        <div className={styles.timeHeader}>
          {getTimePassedFormatted(overallTime.timeInMillis)}
        </div>
        <div className={styles.timeAgoHeader}>
           {formatTimeWithTimeZone(justPassedIntervalTimeMillis, eventTimeZone)}
          &nbsp;
          ({getFinishedAgoLabel(justPassedIntervalTimeMillis)} ago)
        </div>
      </div>
    )
  }

  render() {
    const {
      appState: { racerHasFinishedDismissed },
      hasGottenTrackingInfo,
      isMobile,
      model: { racerHasFinished }
    } = this.props

    if (hasGottenTrackingInfo && racerHasFinished && !racerHasFinishedDismissed) {
      return (
        <Dialog
          buttons={this.renderButtons()}
          closeAction={this.onViewMapClicked}
          content={this.renderContent()}
          isMobile={isMobile}/>
      )
    }

    return null
  }
}
