import { getEnvironment, Environment } from './getEnvironment'

// This is maintained by devops.
const accessToken = `pk.eyJ1IjoiYXRobGlua3MiLCJhIjoiY2p2Z` +
 `nNzNzJrMDBkZTN5cDYwenE4Z3YyaiJ9.zgW2fOwjxK1ojuwXvtKHBg`

const mapboxConfig = {
  accessToken,
  styleUrl: 'mapbox://styles/athlinks/ck12mid7c09n71cqpo2l77c7u',
  zoomLevel: 12
}

export interface IAppConfig {
  resultsLambdaBaseUrl: string,
  s3BucketName: string,
  accessToken: string,
  styleUrl: string,
  zoomLevel: number
}

const localAndStageConfig: IAppConfig = {
  ...mapboxConfig,
  resultsLambdaBaseUrl: 'https://results-stage.athlinks.com',
  s3BucketName: 'athlinks-stage-maps-event-processed'
}

const prodConfig: IAppConfig = {
  ...mapboxConfig,
  resultsLambdaBaseUrl: 'https://results.athlinks.com',
  s3BucketName: 'athlinks-prod-maps-event-processed'
}

export const getAppConfig = () => {
  switch (getEnvironment()) {
    case Environment.PROD:
      return prodConfig
    default:
      return localAndStageConfig
  }
}