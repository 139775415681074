import { Dispatch } from 'redux'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'

import { extractQueryParams } from '../../util/urlHelpers'
import { IAppState } from '../../models/appState'
import { IRouteProps } from '../../models/routeProps'
import {
  ITrackingInfo,
  IIncompatibleVersionInfo
} from '../../models/trackingInfo'

export interface ITrackingExtracted {
  appState: IAppState
  dispatch: Dispatch
  entryId: number[]
  eventCourseId: number
  eventId: number
  failedGettingTrackingInfo: boolean
  gettingTrackingInfo: boolean
  hasGottenTrackingInfo: boolean
  incompatibleVersionInfo: IIncompatibleVersionInfo
  presentationModel: IMapTrackingPresentationModel
  returnUrl: string
}

export interface ITrackingProps extends IRouteProps {
  appState: IAppState
  dispatch: Dispatch
  trackingInfo: ITrackingInfo
}

export function extract(props: ITrackingProps): ITrackingExtracted {
  const { appState, dispatch, trackingInfo } = props
  const queryParams = extractQueryParams(props)

  const {
    entryId,
    eventCourseId = 0,
    eventId = 0,
    returnUrl = ''
  } = queryParams

  const {
    failedGettingTrackingInfo,
    gettingTrackingInfo,
    hasGottenTrackingInfo,
    incompatibleVersionInfo,
    presentationModel
  } = trackingInfo

  return {
    appState,
    dispatch,
    entryId: entryId ? [].concat(entryId) : [],
    eventCourseId: eventCourseId as number,
    eventId: eventId as number,
    failedGettingTrackingInfo,
    gettingTrackingInfo,
    hasGottenTrackingInfo,
    incompatibleVersionInfo,
    presentationModel,
    returnUrl: returnUrl as string
  }
}
