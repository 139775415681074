import React from 'react'

export default function() {
  return (
    <svg width='15px' height='15px' viewBox='0 0 15 15'>
      <g id='Map-Exploration' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Map-Tracking-Split-Overlay' transform='translate(-345.000000, -56.000000)'
          fill='#9B9B9B' fillRule='nonzero'>
          <path d='M359.609375,68.6171875 C359.869793,68.9036473 360,69.2421855 360,69.6328125
            C360,70.0234395 359.869793,70.348957 359.609375,70.609375 C359.348957,70.869793
            359.023439,71 358.632812,71 C358.242186,71 357.903647,70.869793
            357.617188,70.609375 L352.5,65.4921875 L347.382812,70.609375 C347.096353,70.869793
            346.770835,71 346.40625,71 C346.041665,71 345.716147,70.869793
            345.429688,70.609375 C345.143228,70.348957 345,70.0234395 345,69.6328125
            C345,69.2421855 345.143228,68.9036473 345.429688,68.6171875 L350.507812,63.5
            L345.429688,58.3828125 C345.143228,58.0963527 345,57.7578145 345,57.3671875
            C345,56.9765605 345.143228,56.651043 345.429688,56.390625 C345.716147,56.130207
            346.041665,56 346.40625,56 C346.770835,56 347.096353,56.130207 347.382812,56.390625
            L352.5,61.5078125 L357.617188,56.390625 C357.903647,56.130207 358.242186,56
            358.632812,56 C359.023439,56 359.348957,56.130207 359.609375,56.390625
            C359.869793,56.651043 360,56.9765605 360,57.3671875 C360,57.7578145
            359.869793,58.0963527 359.609375,58.3828125 L354.492188,63.5
            L359.609375,68.6171875 Z' id='Close'>
          </path>
        </g>
      </g>
    </svg>
  )
}