
import { Maybe } from 'monet'

export const findHotJar = (document: Document): Maybe<HTMLElement> =>
  Maybe.fromNull(document.getElementById('_hj_feedback_container'))

export const hideHotJar = (hj: HTMLElement): HTMLElement => {
  hj.setAttribute('style', 'display:none')
  return hj
}

export const revealHotJar = (hj: HTMLElement): HTMLElement => {
  if (hj.getAttribute('style')) {
    hj.removeAttribute('style')
  }
  return hj
}
