import {
  getMapPreviewAction
} from 'athlinks-map-tracking-client'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Legend } from '../../components/legend/Legend'
import { IAppState } from '../../models/appState'
import { IReduxState } from '../../models/reduxState'
import { IRouteProps } from '../../models/routeProps'
import AthlinksLogo from '../../svg/AthlinksLogo'
import { PreviewMapbox } from '../../components/maps/PreviewMapbox'
import {
  IMonitoredMapPreviewState
} from '../../reducers/mapPreviewReducer'

const styles = require('./Preview.scss')

interface State {
}

export interface IPreviewProps extends IRouteProps {
  appState: IAppState,
  mapPreviewState: IMonitoredMapPreviewState,
  dispatch: Dispatch
}

class PreviewComponent extends Component<IPreviewProps, State> {
  windowHeight: number = 0
  windowWidth: number = 0

  constructor(props: IPreviewProps) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const eventCourseId: number =
      parseInt(this.props.match.params.eventCourseId, 10)
    this.props.dispatch(getMapPreviewAction({ eventCourseId }))
    this.updateWindowSize()
    window.addEventListener('resize', this.updateWindowSize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize)
  }

  updateWindowSize() {
    const currWidth = this.windowWidth
    const width = window.innerWidth
    this.windowHeight = window.innerHeight
    this.windowWidth = width

    const isLower = currWidth > 800 && width <= 800
    const isGreater = currWidth <= 800 && width > 800

    if (isLower || isGreater || currWidth === 0) {
      this.forceUpdate()
    }
  }

  renderLegend() {
    return (
      <div className={styles['container']}>
        <div className={styles.mapContainer}>
          <PreviewMapbox
            appState={this.props.appState}
            dispatch={this.props.dispatch}
            previewPresentationModel={
              this.props.mapPreviewState.previewPresentationModel
            }
          />
        </div>
        {this.legend()}
        <div className={styles.athlinksLogoContainer}>
          <AthlinksLogo />
        </div>
      </div >
    )
  }

  legend() {
    const {
      showLegend
    } = this.props.appState

    return (
      showLegend ?
        <div className={styles.legend}>
          <Legend
            racerInitials=''
            isMobile={false}
            showAthlete={false}
            showCompletedSegment={false}
            showUpcomingSegment={true}
            showCurrentSegment={false}
            showSplitLabel={true}
            showSplitMarkerPassed={false}
            showSplitMarkerCurrent={false}
            showSplitMarkerUpcoming={true}
          />
        </div>
        : null
    )
  }

  render() {
    if (this.props.mapPreviewState.error) {
      return (
        <div className={styles['loading-container']}>
          <h2 className={styles['text']}>
            Failed to get data for map tracking
          </h2>
        </div>
      )
    }
    if (this.props.mapPreviewState.inProgress) {
      return (
        <div className={styles['loading-container']}>
          <h2 className={styles['text']}>Loading....</h2>
        </div>
      )
    }

    return (
      this.props.mapPreviewState.hasCompleted ?
        this.renderLegend() : null
    )
  }
}

export default connect((state: IReduxState): Partial<IPreviewProps> => ({
  appState: state.appState,
  mapPreviewState: state.mapPreviewState,
  dispatch: undefined
}))(PreviewComponent)
