import moment from 'moment-timezone'
import { convertTime, convertCalendarTime, ICalendarTimeData } from 'lib-athlinks-units'
import { ITime, TimeUnit, ICalendarTime } from 'athlinks-results-model'

export const getCalendarTimeData = (
  milliseconds: number,
  timeZone: string
): ICalendarTimeData => {

  const calendarTime: ICalendarTime = {
    timeInMillis: milliseconds,
    timeZone
  }

  const data = {capitalize: true, timeFormat: 'h:mm'}
  return convertCalendarTime(calendarTime, data)
}

export const formatTimeWithTimeZone = (
  milliseconds: number,
  timeZone: string
): string => {
  const calendarTimeData = getCalendarTimeData(milliseconds, timeZone)
  return `${calendarTimeData.time} ${calendarTimeData.meridiem} ${calendarTimeData.timeZone}`
}

export const getDurationUntilNow = (timeInMilliseconds: number) =>
  moment.duration(timeInMilliseconds - new Date().getTime())

export const getFinishedAgoLabel = (timeInMilliseconds: number) =>
  moment(timeInMilliseconds, 'x').fromNow()

export const getTimePassedFormatted = (timeInMilliseconds: number) => {

  const time: ITime = {
    timeInMillis: timeInMilliseconds,
    timeUnit: TimeUnit.HOURS
  }

  return convertTime(time).value
}
