export interface IAppState {
  showLegend: boolean
  racerHasFinishedDismissed: boolean
  raceNotStartedDismissed: boolean
}

export class AppState implements IAppState {
  showLegend = false
  racerHasFinishedDismissed = false
  raceNotStartedDismissed = false
}