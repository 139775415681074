import React, { Component } from 'react'
import { Cell, Grid, GridContainer } from 'react-foundation'
import { Trans } from 'react-i18next'
import athlinksFlame from '../../shared/images/athlinks-flame.svg'

const styles = require('./404.scss')

class Error404 extends Component {
  renderFooter() {
    return (
      <div className={styles['footer-container']}>
      </div>
    )
  }

  renderContent() {
    return (
      <div className={styles['error-header']}>
        <div className={styles['grid-container-wrapper']}>
          <div className={styles['grid-container']}>
            <GridContainer>
              <Grid>
                <Cell medium={12}>
                  <img className={styles['athlinks-flame']} src={athlinksFlame} />
                </Cell>
                <Cell medium={12}>
                  <div className={styles['error-title']}>
                    <Trans>This page does not exist</Trans>
                  </div>
                </Cell>
              </Grid>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default Error404
