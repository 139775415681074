import { applyMiddleware, createStore, Store, Action } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { combinedEpics } from '../epics/index'
import { combinedReducers } from '../reducers/index'
import { mapTrackingMiddleWare } from 'athlinks-map-tracking-client'

const epicMiddleware = createEpicMiddleware()

let store: Store = undefined

/**
 * This is a utility function only for use in special cases,
 * like keycloak.  DO NOT USE THIS for 99.99% of async ops.
 *
 * It is an anti-pattern
 *
 * @param action Any action
 */
export function dispatchOnStore(action: Action) {
  if (store) {
    store.dispatch(action)
  } else {
    console.error(
      'STORE NOT CONFIGURED, COULD NOT DISPATCH ACTION: ',
      action.type
    )
  }
}

export function configureStore() {
  store = createStore(
    combinedReducers,
    applyMiddleware(mapTrackingMiddleWare, epicMiddleware)
  )

  epicMiddleware.run(combinedEpics)
  return store
}
