
import React, { Component } from 'react'
import CloseX from '../../svg/CloseX'
import { findHotJar, hideHotJar, revealHotJar } from '../../util/hotjarUtils'
const genericStyles = require('./Dialogs.scss')

interface Props {
  // TODO: Get correct types
  buttons: any
  closeAction?: any
  content: any
  isMobile?: boolean
  showClose?: boolean
  testId?: string
}

export default class Dialog extends Component<Props> {
  renderCloseX() {
    const { closeAction, isMobile, showClose = true } = this.props
    if (isMobile && showClose && closeAction) {
      return (
        <div className={genericStyles.closeX} onClick={closeAction}>
          <CloseX />
        </div>
      )
    }
    return null
  }

  componentDidMount() {
    findHotJar(document).map(hideHotJar)
  }

  componentWillUnmount() {
    findHotJar(document).map(revealHotJar)
  }

  render() {
    const { buttons, content, isMobile, testId = '' } = this.props

    const containerStyle = isMobile ? 'modalContainerMobile' : 'modalContainer'
    const contentStyle = isMobile ? 'modalContentMobile' : ''
    const buttonsStyle = isMobile ? 'modalButtonsMobile' : ''

    return (
      <div data-testid={testId} className={'modalBackdrop'}>
        <div className={containerStyle}>
          <div className={contentStyle}>
            {content}
          </div>
          <div className={buttonsStyle}>
            {buttons}
          </div>
          {this.renderCloseX()}
        </div>
      </div>
    )
  }
}
