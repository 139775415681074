import { IAppState, AppState } from '../models/appState'
import { IUpdateShowLegendAction } from '../actions/updateShowLegendAction'
import { IUpdateRacerHasFinishedDismissed } from '../actions/updateRacerHasFinishedDismissed'
import { IUpdateRaceNotStartedDismissed } from '../actions/updateRaceNotStartedDismissed'
import { actionTypes } from '../actions/types'

type actions =
  IUpdateShowLegendAction |
  IUpdateRacerHasFinishedDismissed |
  IUpdateRaceNotStartedDismissed

export default (state: IAppState = new AppState(), action: actions): IAppState => {

  if (action.type === actionTypes.UPDATE_SHOW_LEGEND) {
    return {
      ...state,
      showLegend: action.showLegend
    }
  }

  if (action.type === actionTypes.UPDATE_RACER_HAD_FINISHED_DISMISSED) {
    return {
      ...state,
      racerHasFinishedDismissed: action.dismissed
    }
  }

  if (action.type === actionTypes.UPDATE_RACE_NOT_STARTED_DISMISSED) {
    return {
      ...state,
      raceNotStartedDismissed: action.dismissed
    }
  }

  return state
}
