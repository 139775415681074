import { Action } from 'redux'
import { actionTypes } from './types'

export interface IUpdateRaceNotStartedDismissed extends Action {
  type: actionTypes.UPDATE_RACE_NOT_STARTED_DISMISSED,
  dismissed: boolean
}

export const updateRaceNotStartedDismissed = (
  dismissed: boolean
): IUpdateRaceNotStartedDismissed => ({
  type: actionTypes.UPDATE_RACE_NOT_STARTED_DISMISSED,
  dismissed
})
