export const getPassedSplitSvg = () => `<svg width="25px" height="25px" viewBox="0 0 33 33">
    <defs>
        <circle id="path-1" cx="322.31984" cy="766.77968" r="12.97296"></circle>
    </defs>
    <g id="Map-Exploration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map-Tracking-Full-Screen" transform="translate(-1178.000000, -893.000000)">
            <g id="Group-4" transform="translate(872.000000, 143.000000)">
                <g id="Oval-Copy-3">
                    <use stroke="#2B314E" stroke-width="6" fill="#16A9E1"
                      fill-rule="evenodd" xlink:href="#path-1"></use>
                    <use stroke="#16A9E1" stroke-width="2" xlink:href="#path-1"></use>
                </g>
            </g>
        </g>
    </g>
</svg>`

export const getUpcomingSplitSvg = () => `<svg width="25px" height="25px" viewBox="0 0 33 33">
    <g id="Map-Exploration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map-Tracking-Full-Screen-Copy-6" transform="translate(-932.000000, -648.000000)"
           fill="#E4F3F9" stroke="#2B314E" stroke-width="6">
            <g id="Group-4" transform="translate(872.000000, 143.000000)">
                <circle id="Oval-Copy-2" cx="76.83152" cy="521.29136" r="12.97296"></circle>
            </g>
        </g>
    </g>
</svg>`

export const getNextSplitSvg = () => `<svg width="25px" height="25px" viewBox="0 0 33 33">
    <defs>
        <circle id="path-3" cx="76.83152" cy="521.29136" r="12.97296"></circle>
    </defs>
    <g id="Map-Exploration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map-Tracking-Full-Screen-Copy7"
        transform="translate(-932.000000, -648.000000)">
            <g id="Group-4" transform="translate(872.000000, 143.000000)">
                <g id="Oval-Copy-5">
                    <use stroke="#2B314E" stroke-width="6" fill="#E4F3F9"
                    fill-rule="evenodd" xlink:href="#path-3"></use>
                    <use stroke="#16A9E1" stroke-width="2" xlink:href="#path-3"></use>
                </g>
            </g>
        </g>
    </g>
</svg>`

export const getAnimationSvg = (
  bearing: number
) => `<svg width="8px" height="8px" viewBox="0 0 45 34">
    <g id="arrow" stroke="none" stroke-width="1"
    fill="none" fill-rule="evenodd" stroke-linecap="square">
        <polyline id="Path-2-Copy-44" stroke="white" stroke-width="16"
        transform="translate(22.368066, 16.736133) rotate(-270.000000) rotate(${bearing})
        translate(-22.368066, -16.736133) "
        points="27.7361328 6 17 16.7361328 27.7361328 27.4722655"></polyline>
    </g>
</svg>`

export const getCourseRouteSvg = () => `<svg width="53px" height="7px" viewBox="0 0 53 7">
    <defs>
        <rect id="path-course-route-1" x="0" y="0" width="53" height="7"></rect>
    </defs>
    <g id="Map-Exploration" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map-Tracking-Full-Screen-Copy-11"
          transform="translate(-1479.000000, -579.000000)">
            <g id="Side-Jawn-Copy" transform="translate(1454.000000, 408.000000)">
                <g id="Group">
                    <g id="Group-10" transform="translate(25.000000, 171.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlink:href="#path-course-route-1"></use>
                        </mask>
                        <use id="Rectangle-7-Copy" fill-opacity="0.1"
                            fill="#16A9E1" xlink:href="#path-course-route-1"></use>
                        <polyline id="Path-2" stroke="#16A9E1"
                            stroke-width="7.5" opacity="0.6" mask="url(#mask-2)"
                            points="19.4084507 -3.5 26.1267606 3.5 19.4084507 10.5">
                        </polyline>
                        <polyline id="Path-2-Copy-3" stroke="#16A9E1" stroke-width="7.5"
                            opacity="0.6" mask="url(#mask-2)"
                            points="32.8450704 -3.5 39.5633803 3.5 32.8450704 10.5">
                        </polyline>
                        <polyline id="Path-2-Copy-4" stroke="#16A9E1" stroke-width="7.5"
                            opacity="0.6" mask="url(#mask-2)"
                            points="46.2816901 -3.5 53 3.5 46.2816901 10.5">
                        </polyline>
                        <polyline id="Path-2-Copy" stroke="#16A9E1" stroke-width="7.5"
                            opacity="0.6" mask="url(#mask-2)"
                            points="5.97183099 -3.5 12.6901408 3.5 5.97183099 10.5">
                        </polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`
