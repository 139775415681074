
import React, { Component } from 'react'
import { Button } from 'athlinks-component-library'
import { Cell, Grid } from 'react-foundation'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'

import Dialog from './Dialog'

const styles = require('./RacerHasChangedCourseDialog.scss')

interface Props {
  isMobile?: boolean
  model: IMapTrackingPresentationModel
  returnUrl: URL
}

export class RacerHasChangedCourseDialog
  extends Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  racerHasChangedCourseMessage = (
    first: string,
    last: string,
    course: string
  ) => (
    `${first} ${last} is now running the ${course} course.  ` +
    `Clicking below will update the course map to match the athlete's new course`
  )

  renderButtons() {
    return (
      <Grid className={styles.buttonsHeader}>
        <Cell medium={12}>
          <div data-testid='course-update-btn'
            className={styles.viewResultsBtnContainer}>
            <Button target='_self' text='Update'
              route={this.props.returnUrl.href} color='dark-blue' />
          </div>
        </Cell>
      </Grid>
    )
  }

  renderContent() {
    const {
      model: {
        currentEventCourseName,
        previousEventCourseName,
        firstName,
        lastName
      }
    } = this.props

    return (
      <div>
        <div className={styles.changedCourseHeader}>Racer has switched courses</div>
          <div data-testid='racer-message' className={styles.changedCourseBody}>
            <strong>{firstName} {lastName}</strong> has switched
            from the <strong>{previousEventCourseName}</strong> course
            to the <strong>{currentEventCourseName}</strong> course.<br/><br/>
            Clicking below will update the course map to match the athlete's new course.
          </div>
      </div>
    )
  }

  render() {
    const {
      isMobile,
      model: { racerHasChangedCourse }
    } = this.props

    if (racerHasChangedCourse) {
      return (
        <Dialog
          buttons={this.renderButtons()}
          content={this.renderContent()}
          isMobile={isMobile}
          showClose={false}
          testId={'racerHasChangedCourseDialog'}/>
      )
    }

    return null
  }
}
