import { IMapTrackingPresentationModel } from 'athlinks-map-model'

export interface IIncompatibleVersionInfo {
  incompatibleVersion: boolean,
  error: string
}

export interface ITrackingInfo {
  failedGettingTrackingInfo: boolean
  gettingTrackingInfo: boolean
  hasGottenTrackingInfo: boolean
  incompatibleVersionInfo: IIncompatibleVersionInfo
  presentationModel: IMapTrackingPresentationModel
}

export class TrackingInfo implements ITrackingInfo {
  failedGettingTrackingInfo = false
  gettingTrackingInfo = false
  hasGottenTrackingInfo = false
  incompatibleVersionInfo = {
    incompatibleVersion: false,
    error: undefined
  }
  presentationModel = {} as IMapTrackingPresentationModel
}