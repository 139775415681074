import React, { Component } from 'react'
import { IMapTrackingPresentationModel } from 'athlinks-map-model'

import Chart from '../chart/Chart'
import { extractElevationData } from '../../util/elevationHelpers'
import { IChartData } from '../../util/chartHelpers'

interface Props {
  presentationModel: IMapTrackingPresentationModel
}

export default class ElevationProfile extends Component<Props> {
  data: IChartData[]

  componentWillMount() {
    this.data = extractElevationData(this.props.presentationModel)
  }

  componentWillUpdate() {
    this.data = extractElevationData(this.props.presentationModel)
  }

  render() {
    const {
      maxElevation,
      minElevation
    } = this.props.presentationModel.overallElevationSummary

    return (
      <Chart
        data={this.data}
        height={100}
        max={maxElevation}
        min={minElevation}
        yAxisLabel={'m'}
      />
    )
  }
}
