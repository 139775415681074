import { flow, over, partialRight, reduce } from 'lodash'

export enum Environment {
  LOCAL = 'LOCAL',
  STAGE = 'STAGE',
  PROD = 'PROD'
}

export const buildRegExp = (
  baseUrl: string
) => new RegExp(`(https:\/\/${baseUrl})`)

export const matchEnvironment = (
  environment: Environment,
  baseUrl: string
) => (
  path: string
): Environment => buildRegExp(baseUrl).test(path) ? environment : undefined

export const determineEnvironment: (path: string) => Environment = flow(
   over(
    [
      matchEnvironment(
        Environment.LOCAL,
        'localhost'
      ),
      matchEnvironment(
        Environment.STAGE,
        'map-stage.athlinks.com'
      ),
      matchEnvironment(
        Environment.PROD,
        'map.athlinks.com'
      )
    ]),
  partialRight(
    reduce,
    (acc: Environment, val: Environment) => val || acc,
    undefined
  )
)

let environ: Environment = undefined

export const getEnvironment = (path: string = window.location.href) => {
  if (!environ) {
    environ = determineEnvironment(path)
  }
  return environ
}