
import React from 'react'

export const renderCenterIcon = () => (
    <svg width='14px' height='14px' viewBox='0 0 14 14'>
      <g id='Map-Exploration' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Map-Tracking-Full-Screen-Copy-10'
          transform='translate(-1803.000000, -891.000000)'
          fill='#4A4A4A' fillRule='nonzero'>
          <g id='Group-7' transform='translate(1795.000000, 839.000000)'>
            <path d='M13.21875,57.25 C13.7187525,56.7499975 14.3124966,56.5 15,56.5
              C15.6875034,56.5 16.2760392,56.7447892 16.765625,57.234375
              C17.2552108,57.7239608 17.5,58.3124966 17.5,59 C17.5,59.6875034
              17.2552108,60.2760392 16.765625,60.765625 C16.2760392,61.2552108
              15.6875034,61.5 15,61.5 C14.3124966,61.5 13.7239608,61.2552108
              13.234375,60.765625 C12.7447892,60.2760392 12.5,59.6875034 12.5,59
              C12.5,58.3124966 12.7395809,57.7291691 13.21875,57.25 Z M20.96875,58.34375
              L22,58.34375 L22,59.65625 L20.96875,59.65625 C20.8229159,61.0520903
              20.250005,62.249995 19.25,63.25 C18.249995,64.250005 17.0520903,64.8229159
              15.65625,64.96875 L15.65625,66 L14.34375,66 L14.34375,64.96875
              C12.9479097,64.8229159 11.750005,64.250005 10.75,63.25 C9.749995,62.249995
              9.17708406,61.0520903 9.03125,59.65625 L8,59.65625 L8,58.34375
              L9.03125,58.34375 C9.17708406,56.9479097 9.749995,55.750005
              10.75,54.75 C11.750005,53.749995 12.9479097,53.1770841 14.34375,53.03125
              L14.34375,52 L15.65625,52 L15.65625,53.03125 C17.0520903,53.1770841
              18.249995,53.749995 19.25,54.75 C20.250005,55.750005 20.8229159,56.9479097
              20.96875,58.34375 Z M11.6875,62.3125 C12.6041713,63.2083378 13.7083269,63.65625
              15,63.65625 C16.2916731,63.65625 17.3906205,63.2031295 18.296875,62.296875
              C19.2031295,61.3906205 19.65625,60.2916731 19.65625,59 C19.65625,57.7083269
              19.2031295,56.6093795 18.296875,55.703125 C17.3906205,54.7968705 16.2916731,
              54.34375 15,54.34375 C13.7083269,54.34375 12.6093795,54.7968705 11.703125,
              55.703125 C10.7968705,56.6093795 10.34375,57.7083269 10.34375,59 C10.34375,
              60.2916731 10.7916622,61.3958287 11.6875,62.3125 Z'>
            </path>
          </g>
        </g>
      </g>
    </svg>
    )
