import {
  ISubscribeToTrackingDataAction,
  ISubscribeToTrackingDataErrorAction,
  ISubscribeToTrackingDataUpdateAction,
  TrackingActionTypes,
  IIncompatibleMapVersionError
} from 'athlinks-map-tracking-client'

import { ITrackingInfo, TrackingInfo } from '../models/trackingInfo'

type actions = ISubscribeToTrackingDataAction | ISubscribeToTrackingDataErrorAction
             | ISubscribeToTrackingDataUpdateAction | IIncompatibleMapVersionError

export default (state: ITrackingInfo = new TrackingInfo(), action: actions) => {
  const applyToState = (changes: Partial<ITrackingInfo>) => ({
    ...state,
    ...changes
  })

  if (action.type === TrackingActionTypes.INCOMPATIBLE_MAP_VERSION_ERROR) {
    console.warn('ERROR IS', action)
    return applyToState({
      incompatibleVersionInfo: {
        incompatibleVersion: true,
        error: action.error
      }
    })
  }

  if (action.type === TrackingActionTypes.SUBSCRIBE_TO_TRACKING_DATA) {
    return applyToState({
      gettingTrackingInfo: true
    })
  }
  if (action.type === TrackingActionTypes.SUBSCRIBE_TO_TRACKING_DATA_UPDATE) {
    return applyToState({
      gettingTrackingInfo: false,
      hasGottenTrackingInfo: true,
      presentationModel: action.presentationModel
    })
  }
  if (action.type === TrackingActionTypes.SUBSCRIBE_TO_TRACKING_DATA_ERROR) {
    return applyToState({
      failedGettingTrackingInfo: true,
      gettingTrackingInfo: false,
      hasGottenTrackingInfo: true
    })
  }

  return state
}
