
import {
  IGetMapPreviewAction,
  IGetMapPreviewActionCompleted,
  IGetMapPreviewActionError,
  IMapPreviewState,
  MapPreviewActionTypes
} from 'athlinks-map-tracking-client'

export interface IMonitoredMapPreviewState extends IMapPreviewState {
  inProgress: boolean
  hasCompleted: boolean
}

export default (
  state: IMonitoredMapPreviewState = ({
    inProgress: true,
    hasCompleted: false,
    eventCourseId: undefined,
    previewPresentationModel: undefined
  }),
  action: IGetMapPreviewAction |
    IGetMapPreviewActionCompleted |
    IGetMapPreviewActionError
) => {
  const applyToState = (changes: Partial<IMonitoredMapPreviewState>) => ({
    ...state,
    ...changes
  })

  if (action.type === MapPreviewActionTypes.GET_MAP_PREVIEW) {
    return applyToState({
      hasCompleted: false,
      inProgress: true,
      eventCourseId: action.eventCourseId
    })
  }

  if (action.type === MapPreviewActionTypes.GET_MAP_PREVIEW_COMPLETED) {
    return applyToState({
      inProgress: false,
      hasCompleted: true,
      eventCourseId: action.eventCourseId,
      previewPresentationModel: action.previewPresentationModel
    })
  }

  if (action.type === MapPreviewActionTypes.GET_MAP_PREVIEW_ERROR) {
    return applyToState({
      inProgress: false,
      hasCompleted: true,
      eventCourseId: action.eventCourseId,
      error: action.error
    })
  }

  return state
}
