import {
  IMapTrackingPreviewPresentationModel, ICourseSplit
} from 'athlinks-map-model'
import { isEqual } from 'lodash'
import mapboxgl, { Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { pipe } from 'ramda'
import React from 'react'
import { Dispatch } from 'redux'
import { updateShowLegend } from '../../actions/updateShowLegendAction'
import { getAppConfig } from '../../config'
import { IAppState } from '../../models/appState'
import {
  createMapboxMarker,
  getFinishIndicator,
  getStartIndicator,
  markerAdder,
  getNextSplitSummary,
  getSplitIconMarker
} from '../../util/markerHelpers'
import {
  renderCenterIcon
} from './renderCenterIcon'
import { lineString } from '../../util/mapHelpers'

const styles = require('./Mapbox.scss')
require('./PopupMarker.scss')

const appConfig = getAppConfig()
const splitLine = lineString(4, '#DDDDDD')('upcoming-splits')

mapboxgl.accessToken = appConfig.accessToken

interface Props {
  appState: IAppState
  dispatch: Dispatch
  height?: number
  previewPresentationModel: IMapTrackingPreviewPresentationModel
}

type MarkerMaker = (x0: HTMLElement, x1: mapboxgl.MarkerOptions) => Marker

export class PreviewMapbox extends React.Component<Props> {
  intervalMarkers = []
  map: any
  mapboxGL: any
  mapHasLoaded = false
  mapRef: any
  mapResizeTimeout: any

  constructor(props: Props) {
    super(props)
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    const { centerCoordinate } = this.props.previewPresentationModel

    this.map = new mapboxgl.Map({
      container: this.mapRef,
      style: appConfig.styleUrl,
      center: [centerCoordinate.long, centerCoordinate.lat],
      zoom: appConfig.zoomLevel,
      maxZoom: 17,
      minZoom: 10
    })

    this.map.on('load', () => {
      this.drawMap()
    })
  }

  componentDidUpdate(prev: Props) {
    if (prev.height !== this.props.height) {
      if (this.mapResizeTimeout) {
        clearTimeout(this.mapResizeTimeout)
      }
      this.mapResizeTimeout = setTimeout(() => {
        this.map.resize()
      }, 100)
    }
    if (isEqual(prev.previewPresentationModel, this.props.previewPresentationModel)) {
      return
    }
    this.drawMap()
  }

  componentWillUnmount() {
    this.map.remove()
  }

  clearCanvas() {
    ['upcoming-splits']
      .forEach((layerName) => {
        if (this.map.getLayer(layerName)) {
          this.map.removeLayer(layerName)
          this.map.removeSource(layerName)
        }
      })

    this.intervalMarkers.forEach((marker) => { marker.remove() })
    this.intervalMarkers = []
  }

  mapMarkerMaker = (markerMaker: MarkerMaker): MarkerMaker =>
    pipe(
      markerMaker,
      markerAdder(this.map)
    )

  renderSplit = (
    split: ICourseSplit
  ) => {
    const markerMaker =
      this.mapMarkerMaker(
        createMapboxMarker(split.coordinates)
      )

    const ivName = split.intervalName

    this.intervalMarkers.push(
      markerMaker(
        getNextSplitSummary(ivName),
        { offset: [123, -3] }
      )
    )
    this.intervalMarkers.push(
      markerMaker(
        getSplitIconMarker(false),
        {}
      )
    )
  }

  drawMap() {
    const model = this.props.previewPresentationModel
    this.clearCanvas()

    model.courseSplits.forEach(this.renderSplit)

    this.map.addLayer(splitLine(model.coordinatesForIntervals))
    this.centerMap()

    this.mapMarkerMaker(createMapboxMarker(model.startingCoordinate))
      (getStartIndicator(), { offset: [-15, 15] })
    this.mapMarkerMaker(createMapboxMarker(model.endingCoordinate))
      (getFinishIndicator(), { offset: [-20, 15] })

    this.mapHasLoaded = true
  }

  centerMap = () => {
    const { boundingBox, centerCoordinate } = this.props.previewPresentationModel
    // skip for [0, 0, 0, 0]
    if (boundingBox && boundingBox.filter(x => x).length) {
      this.map.fitBounds(
        new mapboxgl.LngLatBounds(boundingBox as [number, number, number, number]),
        { padding: 200 }
      )
    }
    else this.map.setCenter([centerCoordinate.long, centerCoordinate.lat])
  }

  onLegendToggleClicked = () =>
    this.props.dispatch(
      updateShowLegend(
        !this.props.appState.showLegend
      )
    )

  onRecenterClicked = () => {
    this.centerMap()
  }

  render() {
    return (
      <div className={styles.container}>
        <div ref={div => this.mapRef = div} className={styles.mapBoxContainer} />
        <div onClick={() => this.map.zoomIn()} className={styles.zoomInBtn}>+</div>
        <div onClick={() => this.map.zoomOut()} className={styles.zoomOutBtn}>-</div>
        <div onClick={this.onRecenterClicked} className={styles.centerBtn}>
          {renderCenterIcon()}
        </div>
        <div onClick={this.onLegendToggleClicked} className={styles.legendToggle}>
          {this.props.appState.showLegend ? 'X' : '?'}
        </div>
      </div>
    )
  }
}
