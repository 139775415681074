import React, { Component } from 'react'
import {
  AreaSeries,
  DecorativeAxis,
  GradientDefs,
  HorizontalGridLines,
  LineSeries,
  XYPlot
} from 'react-vis'

import {
  getAdjustedMax,
  getAdjustedMin,
  getRange,
  getTickValues,
  IChartData
} from '../../util/chartHelpers'

const styles = require('./Chart.scss')

interface Props {
  data: IChartData[]
  height?: number
  max: number
  min: number
  numberOfTicks?: number
  rangeTolerance?: number
  yAxisLabel?: string
}

export default class Chart extends Component<Props> {
  chartId: string = Math.random().toString(36).substr(2, 9)
  lastUpdate: number = 0
  width: number = 0

  constructor(props) {
    super(props)

    this.updateWidth = this.updateWidth.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
  }

  updateWidth() {
    const now = Date.now()
    if (now - this.lastUpdate >= 50) {
      this.lastUpdate = now
      this.width = document.getElementById(this.chartId).clientWidth || 200
      this.forceUpdate()
    }
  }

  renderAreaSeries(chartData: IChartData) {
    const { data, type } = chartData
    if (data.length && type === 'area') {
      return (
        <AreaSeries
          color={`url(#AreaColorGradient)`}
          curve={'curveMonotoneX'}
          data={data}
          stroke={'white'}
          style={{strokeWidth: 6}}
        />
      )
    }
    return null
  }

  renderGradient(chartData: IChartData) {
    const { data, type } = chartData
    if (data.length && type !== 'grey') {
      const startOpacity = type === 'solid' ? .5 : .25
      return (
        <GradientDefs>
          <linearGradient id={`AreaColorGradient`} x1='0' x2='0' y1='0' y2='1'>
            <stop offset='0%' stopColor='rgba(22, 169, 225)' stopOpacity={startOpacity}/>
            <stop offset='100%' stopColor='rgba(22, 169, 225)' stopOpacity={0}/>
          </linearGradient>
        </GradientDefs>
      )
    }
    return null
  }

  renderLineSeries(chartData: IChartData) {
    const { data, type } = chartData
    if (data.length && type !== 'area') {
      const color = type === 'grey' ? 'rgb(155, 155, 155)' : 'rgba(22, 169, 225)'
      const style = type === 'solid'
        ? {strokeLinecap: 'round'}
        : {strokeDasharray: '6, 2'}
      return (
        <LineSeries
          curve={'curveMonotoneX'}
          color={color}
          data={data}
          strokeWidth={2}
          style={style}
        />
      )
    }
    return null
  }

  render() {
    const {
      data,
      height = 200,
      max,
      min,
      numberOfTicks = 5,
      rangeTolerance = 30,
      yAxisLabel = ''
    } = this.props

    const yDomain = getRange(
      getAdjustedMin(min, numberOfTicks),
      getAdjustedMax(max, numberOfTicks),
      rangeTolerance
    )
    const adjustedMin = yDomain[0]
    const adjustedMax = yDomain[1]

    const tickValues = getTickValues(adjustedMax, adjustedMin, numberOfTicks)

    return (
      <div className={styles['chart-container']} id={this.chartId}>
        <XYPlot
          className={styles['chart']}
          height={height}
          margin={{top: 10, left: 0, right: 0, bottom: 5}}
          width={this.width}
          yDomain={yDomain}
        >
          <HorizontalGridLines tickValues={tickValues}/>
          {
            data.map(_ => [
              this.renderGradient(_),
              this.renderAreaSeries(_),
              this.renderLineSeries(_)
            ])
          }
          <DecorativeAxis
            axisStart={{x: 0, y: adjustedMin}}
            axisEnd={{x: 0, y: adjustedMax}}
            axisDomain={yDomain}
            numberOfTicks={numberOfTicks}
            style={{text: {fill: 'red'}}}
            tickSize={0}
            tickValue={(text) => yAxisLabel ? `${text} ${yAxisLabel}` : text}
          />
        </XYPlot>
      </div>
    )
  }
}
