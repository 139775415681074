import { camelCase, isFinite, keys } from 'lodash'
import { lensProp, set } from 'ramda'
import * as qs from 'qs'

import { ILocationProps, IRouteProps } from '../models/routeProps'

type ParamValue = boolean | string | number
interface IParamObject {
  [index: string]: ParamValue
}
interface IQueryParamsReturn {
  [index: string]: ParamValue | ParamValue[]
}
export function extractQueryParams(props: ILocationProps): IQueryParamsReturn {
  const { search: queryString } = props.location

  return queryString
    ? queryString.slice(1).split('&').reduce((acc, curr) => {
      const pair = curr.split('=')
      const key = pair[0]
      const value = pair[1]

      const isArray = value.includes(',')

      return {
        ...acc,
        [camelCase(key)]: isArray
          ? value.split(',').map((val => getProperType(val)))
          : getProperType(value)
      }
    }, {})
    : {}
}

// export const extractUrlParams = ({
//   match: {
//     params
//   }
// }: IRouteProps): {[index: string]: ParamValue} => flow([
//   keys
// ])(params)

export const extractUrlParams = (
  { match: { params } }: IRouteProps
): {[index: string]: ParamValue} => keys(params).reduce(
  (acc: IParamObject, key: string) => {
    return {
      ...acc,
      [key]: getProperType(params[key])
    }
  }, {}
)

export function getProperType(value: string): ParamValue {
  if (isFinite(Number(value))) {
    return Number(value)
  }
  if (value.toLowerCase() === 'true') {
    return true
  }
  if (value.toLowerCase() === 'false') {
    return false
  }
  return value
}

export const returnURL = (originalURL: URL, eventCourseId: number): URL => {
  const res = qs.parse(originalURL.search.slice(1))
  const eventCourseIdLens = lensProp('event-course-id')
  const newres = set(eventCourseIdLens, eventCourseId, res)
  const newUrl = `${originalURL.protocol}//${originalURL.host}` +
                 `${originalURL.pathname}?${qs.stringify(newres)}`
  return new URL(newUrl)
}