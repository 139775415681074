import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import { configureStore } from './store/index'
import Error404 from './containers/error/404'
import Homepage from './containers/homepage/Homepage'
import { Routes } from './util/routes'
import * as serviceWorker from './serviceWorker'
import TestPage from './containers/testPage/TestPage'
import Tracking from './containers/tracking/Tracking'
import Preview from './containers/preview/Preview'
import { configureMapTrackingClient } from 'athlinks-map-tracking-client'
import './i18n'
import './index.scss'
import { getAppConfig } from './config'
import VersionError from './containers/versionError/versionError'

const store = configureStore()

const appConfig = getAppConfig()

configureMapTrackingClient({
  resultsLambdaBaseUrl: appConfig.resultsLambdaBaseUrl,
  s3BucketName: appConfig.s3BucketName
})

/**
 * If you do not add any new Routes to the Routes enum, users will be logged
 * into Routes.HOME until they are added.
 */
const routing = (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.HOME} component={Homepage} />
        <Route exact path={Routes.MAP} component={Tracking} />
        <Route exact path={Routes.TEST} component={TestPage} />
        <Route exact path={Routes.VERSION_ERROR} component={VersionError} />
        <Route exact path={Routes.PREVIEW} component={Preview} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
