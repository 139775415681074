import React from 'react'

import {
  getCourseRouteSvg,
  getNextSplitSvg,
  getPassedSplitSvg,
  getUpcomingSplitSvg
} from '../../util/svgHelpers'
import { findHotJar, hideHotJar, revealHotJar } from '../../util/hotjarUtils'

const styles = require('./Legend.scss')

interface Props {
  isMobile: boolean,
  racerInitials: string,
  showAthlete: boolean,
  showCompletedSegment: boolean,
  showCurrentSegment: boolean,
  showUpcomingSegment: boolean,
  showSplitLabel: boolean,
  showSplitMarkerPassed: boolean
  showSplitMarkerCurrent: boolean,
  showSplitMarkerUpcoming: boolean
}

export class Legend extends React.Component<Props> {

  componentDidMount() {
    findHotJar(document).filter((_) => this.props.isMobile).map(hideHotJar)
  }

  componentWillUnmount() {
    findHotJar(document).filter((_) => this.props.isMobile).map(revealHotJar)
  }

  completedSegment() {
    return (
      this.props.showCompletedSegment ?
        <tr>
          <td><div className={styles.completedSegment}></div></td>
          <td>Course Route - Completed Segment</td>
        </tr>
        : null
    )
  }

  upComingSegment() {
    return (
      this.props.showUpcomingSegment ?
        <tr>
          <td><div className={styles.upcomingSegment}></div></td>
          <td>Course Route - Upcoming Segment</td>
        </tr>
        : null
    )
  }

  currentSegment() {
    return (
      this.props.showCurrentSegment ?
        <tr>
          <td>
            <div className={styles.currentSegment}
              dangerouslySetInnerHTML={{ __html: getCourseRouteSvg() }}
            />
          </td>
          <td>Course Route - Current Segment</td>
        </tr>
        : null
    )
  }

  showAthlete() {
    return (
      this.props.showAthlete ?
        <tr>
          <td>
            <div className={styles.selectedRunner}>
              {this.props.racerInitials}
            </div>
          </td>
          <td>Selected Athlete</td>
        </tr>
        : null
    )
  }

  showSplitLabel() {
    return (
      this.props.showSplitLabel ?
        <tr>
          <td>
            <div className={styles.passedSplitArrow}></div>
            <div className={styles.splitName}>Split 1</div></td>
          <td>Split Name Label</td>
        </tr>
        : null
    )
  }

  showSplitmarkerPassed() {
    return (
      this.props.showSplitMarkerPassed ?
        <tr>
          <td><div dangerouslySetInnerHTML={{ __html: getPassedSplitSvg() }}></div></td>
          <td>Split Marker - Passed</td>
        </tr>
        : null
    )
  }

  showSplitmarkerCurrent() {
    return (
      this.props.showSplitMarkerCurrent ?
        <tr>
          <td><div dangerouslySetInnerHTML={{ __html: getNextSplitSvg() }}></div></td>
          <td>Split Marker - Current</td>
        </tr>
        : null
    )
  }

  showSplitmarkerUpcoming() {
    return (
      this.props.showSplitMarkerUpcoming ?
        <tr>
          <td><div dangerouslySetInnerHTML={{ __html: getUpcomingSplitSvg() }}></div></td>
          <td>Split Marker - Upcoming</td>
        </tr>
        : null
    )
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.mapInformationHeader}>Map Information</div>
        <table className={styles.table}>
          <tbody className={styles.tableBody}>
            {this.completedSegment()}
            {this.upComingSegment()}
            {this.currentSegment()}
            {this.showAthlete()}
            {this.showSplitLabel()}
            {this.showSplitmarkerPassed()}
            {this.showSplitmarkerCurrent()}
            {this.showSplitmarkerUpcoming()}
          </tbody>
        </table>
      </div>
    )
  }
}
