export const miamiCourseJson: any = {
  type: 'FeatureCollection',
  features: [
     {
        type: 'Feature',
        properties: {
           name: 'Marathon',
           time: '2019-01-09T00:00:00Z',
           athlinksFeatureType: 'COURSE',
           'eventCourseId': 1157480
        },
        geometry: {
           type: 'LineString',
           'coordinates': [
              [
                 -80.1892613,
                 25.7821872,
                 11
              ],
              [
                 -80.1892532,
                 25.7823702,
                 10
              ],
              [
                 -80.1892586,
                 25.7827796,
                 10
              ],
              [
                 -80.1892519,
                 25.7832155,
                 10
              ],
              [
                 -80.1892492,
                 25.7836744,
                 9
              ],
              [
                 -80.1892465,
                 25.7841333,
                 8
              ],
              [
                 -80.1892385,
                 25.7842975,
                 8
              ],
              [
                 -80.1892251,
                 25.7844279,
                 8
              ],
              [
                 -80.1891983,
                 25.7845656,
                 8
              ],
              [
                 -80.1891527,
                 25.7848047,
                 9
              ],
              [
                 -80.1891071,
                 25.785039,
                 9
              ],
              [
                 -80.1890588,
                 25.7853143,
                 9
              ],
              [
                 -80.1890025,
                 25.7855268,
                 9
              ],
              [
                 -80.1889408,
                 25.7856524,
                 9
              ],
              [
                 -80.1888174,
                 25.78572,
                 9
              ],
              [
                 -80.1886377,
                 25.7858118,
                 10
              ],
              [
                 -80.1884177,
                 25.7859036,
                 10
              ],
              [
                 -80.1881844,
                 25.7859978,
                 11
              ],
              [
                 -80.187656,
                 25.7862489,
                 12
              ],
              [
                 -80.187369,
                 25.7863866,
                 12
              ],
              [
                 -80.1869398,
                 25.7865967,
                 13
              ],
              [
                 -80.1864919,
                 25.7867851,
                 13
              ],
              [
                 -80.1862183,
                 25.7868575,
                 13
              ],
              [
                 -80.1859501,
                 25.7869058,
                 12
              ],
              [
                 -80.1856926,
                 25.7869276,
                 11
              ],
              [
                 -80.1852071,
                 25.7869566,
                 9
              ],
              [
                 -80.1847216,
                 25.786988,
                 10
              ],
              [
                 -80.1842174,
                 25.7870194,
                 13
              ],
              [
                 -80.1837131,
                 25.7870483,
                 6
              ],
              [
                 -80.182997,
                 25.7870894,
                 3
              ],
              [
                 -80.1822728,
                 25.7871232,
                 6
              ],
              [
                 -80.1815352,
                 25.7871667,
                 2
              ],
              [
                 -80.1807976,
                 25.7872102,
                 2
              ],
              [
                 -80.18006,
                 25.7872536,
                 2
              ],
              [
                 -80.1795316,
                 25.7872754,
                 5
              ],
              [
                 -80.1792741,
                 25.7872826,
                 6
              ],
              [
                 -80.1790005,
                 25.7872705,
                 7
              ],
              [
                 -80.1787242,
                 25.7872343,
                 8
              ],
              [
                 -80.1784506,
                 25.7871908,
                 8
              ],
              [
                 -80.1781932,
                 25.7871184,
                 8
              ],
              [
                 -80.1779437,
                 25.7870411,
                 8
              ],
              [
                 -80.1777372,
                 25.7869614,
                 8
              ],
              [
                 -80.1775146,
                 25.78686,
                 9
              ],
              [
                 -80.1772839,
                 25.7867344,
                 10
              ],
              [
                 -80.1770639,
                 25.7865895,
                 10
              ],
              [
                 -80.1768735,
                 25.7864301,
                 9
              ],
              [
                 -80.1767609,
                 25.7863214,
                 9
              ],
              [
                 -80.1766482,
                 25.7862127,
                 8
              ],
              [
                 -80.1765463,
                 25.7861016,
                 8
              ],
              [
                 -80.1764444,
                 25.7859688,
                 8
              ],
              [
                 -80.1763478,
                 25.7858287,
                 8
              ],
              [
                 -80.1762512,
                 25.785679,
                 8
              ],
              [
                 -80.1761547,
                 25.7855172,
                 9
              ],
              [
                 -80.176042,
                 25.7853481,
                 9
              ],
              [
                 -80.1759294,
                 25.785179,
                 10
              ],
              [
                 -80.1757872,
                 25.7849496,
                 10
              ],
              [
                 -80.1756477,
                 25.7847081,
                 10
              ],
              [
                 -80.1755083,
                 25.7844641,
                 10
              ],
              [
                 -80.1753688,
                 25.7842226,
                 10
              ],
              [
                 -80.1752052,
                 25.7839859,
                 10
              ],
              [
                 -80.1750416,
                 25.7837493,
                 9
              ],
              [
                 -80.1748484,
                 25.7835126,
                 9
              ],
              [
                 -80.1746473,
                 25.7832807,
                 9
              ],
              [
                 -80.1744434,
                 25.7830271,
                 9
              ],
              [
                 -80.1742262,
                 25.7827784,
                 10
              ],
              [
                 -80.1740357,
                 25.7825706,
                 10
              ],
              [
                 -80.1737541,
                 25.7822929,
                 8
              ],
              [
                 -80.173628,
                 25.7821721,
                 8
              ],
              [
                 -80.1733786,
                 25.7819234,
                 8
              ],
              [
                 -80.1730031,
                 25.7816022,
                 7
              ],
              [
                 -80.1727456,
                 25.7814041,
                 6
              ],
              [
                 -80.1724881,
                 25.7812109,
                 5
              ],
              [
                 -80.1722735,
                 25.7810732,
                 6
              ],
              [
                 -80.1720455,
                 25.7809331,
                 7
              ],
              [
                 -80.1718578,
                 25.7808438,
                 8
              ],
              [
                 -80.1714903,
                 25.7806602,
                 7
              ],
              [
                 -80.1712865,
                 25.7805636,
                 6
              ],
              [
                 -80.1709244,
                 25.7804235,
                 5
              ],
              [
                 -80.1705757,
                 25.7802665,
                 2
              ],
              [
                 -80.1702055,
                 25.7801071,
                 0
              ],
              [
                 -80.1698354,
                 25.7799477,
                 0
              ],
              [
                 -80.1694438,
                 25.7797666,
                 0
              ],
              [
                 -80.1689959,
                 25.7795734,
                 0
              ],
              [
                 -80.1684836,
                 25.7793656,
                 0
              ],
              [
                 -80.1679713,
                 25.7791555,
                 0
              ],
              [
                 -80.1673543,
                 25.7788729,
                 0
              ],
              [
                 -80.1667053,
                 25.7785831,
                 0
              ],
              [
                 -80.1660562,
                 25.7782933,
                 0
              ],
              [
                 -80.1653561,
                 25.7779986,
                 0
              ],
              [
                 -80.164656,
                 25.7776967,
                 0
              ],
              [
                 -80.1639104,
                 25.7773779,
                 1
              ],
              [
                 -80.1631647,
                 25.777059,
                 1
              ],
              [
                 -80.1624325,
                 25.7767402,
                 0
              ],
              [
                 -80.1618746,
                 25.7764939,
                 0
              ],
              [
                 -80.1612899,
                 25.7762354,
                 0
              ],
              [
                 -80.160681,
                 25.775977,
                 0
              ],
              [
                 -80.1601285,
                 25.7757451,
                 0
              ],
              [
                 -80.1595759,
                 25.7755132,
                 0
              ],
              [
                 -80.1592943,
                 25.7753876,
                 0
              ],
              [
                 -80.1589376,
                 25.7752379,
                 0
              ],
              [
                 -80.1585701,
                 25.7750736,
                 0
              ],
              [
                 -80.1581758,
                 25.7749046,
                 0
              ],
              [
                 -80.1577815,
                 25.7747355,
                 0
              ],
              [
                 -80.1573873,
                 25.7745664,
                 0
              ],
              [
                 -80.156993,
                 25.7743949,
                 0
              ],
              [
                 -80.1565719,
                 25.774221,
                 0
              ],
              [
                 -80.15614,
                 25.7740326,
                 0
              ],
              [
                 -80.1556921,
                 25.7738442,
                 0
              ],
              [
                 -80.1552415,
                 25.7736365,
                 0
              ],
              [
                 -80.1547587,
                 25.7734312,
                 0
              ],
              [
                 -80.1542759,
                 25.7732235,
                 0
              ],
              [
                 -80.1537931,
                 25.7730157,
                 0
              ],
              [
                 -80.1533049,
                 25.772808,
                 0
              ],
              [
                 -80.1528168,
                 25.7726003,
                 0
              ],
              [
                 -80.1523286,
                 25.7723926,
                 1
              ],
              [
                 -80.1520953,
                 25.7723008,
                 3
              ],
              [
                 -80.1518539,
                 25.772209,
                 4
              ],
              [
                 -80.1515964,
                 25.7721317,
                 4
              ],
              [
                 -80.1513281,
                 25.7720544,
                 4
              ],
              [
                 -80.1510116,
                 25.7719795,
                 4
              ],
              [
                 -80.1506951,
                 25.7719047,
                 5
              ],
              [
                 -80.1503733,
                 25.7718539,
                 5
              ],
              [
                 -80.1499495,
                 25.7718032,
                 5
              ],
              [
                 -80.1496115,
                 25.7717839,
                 5
              ],
              [
                 -80.1492977,
                 25.7717766,
                 4
              ],
              [
                 -80.1489893,
                 25.7718225,
                 5
              ],
              [
                 -80.1487264,
                 25.7718877,
                 5
              ],
              [
                 -80.1481605,
                 25.7720568,
                 5
              ],
              [
                 -80.1476562,
                 25.7722452,
                 5
              ],
              [
                 -80.1471332,
                 25.7724481,
                 6
              ],
              [
                 -80.1466101,
                 25.772651,
                 6
              ],
              [
                 -80.1460871,
                 25.7728491,
                 6
              ],
              [
                 -80.1455587,
                 25.773052,
                 3
              ],
              [
                 -80.1450303,
                 25.7732549,
                 1
              ],
              [
                 -80.1445019,
                 25.7734578,
                 0
              ],
              [
                 -80.1439735,
                 25.7736606,
                 0
              ],
              [
                 -80.1434451,
                 25.7738635,
                 1
              ],
              [
                 -80.1429167,
                 25.7740664,
                 1
              ],
              [
                 -80.1424554,
                 25.7742307,
                 2
              ],
              [
                 -80.1423347,
                 25.7742669,
                 3
              ],
              [
                 -80.1422086,
                 25.7742911,
                 3
              ],
              [
                 -80.1420853,
                 25.7743104,
                 4
              ],
              [
                 -80.1418707,
                 25.7743514,
                 5
              ],
              [
                 -80.1415434,
                 25.7743442,
                 6
              ],
              [
                 -80.1412269,
                 25.774349,
                 6
              ],
              [
                 -80.141058,
                 25.7743297,
                 6
              ],
              [
                 -80.1409426,
                 25.7743031,
                 6
              ],
              [
                 -80.14083,
                 25.7742645,
                 6
              ],
              [
                 -80.1407495,
                 25.7741896,
                 6
              ],
              [
                 -80.1406771,
                 25.7740761,
                 6
              ],
              [
                 -80.1405966,
                 25.7739529,
                 7
              ],
              [
                 -80.1404974,
                 25.7738297,
                 7
              ],
              [
                 -80.1402962,
                 25.7735278,
                 8
              ],
              [
                 -80.140087,
                 25.7731945,
                 8
              ],
              [
                 -80.1398322,
                 25.7728201,
                 7
              ],
              [
                 -80.1396042,
                 25.7725061,
                 7
              ],
              [
                 -80.1394916,
                 25.7723177,
                 8
              ],
              [
                 -80.1393306,
                 25.7720761,
                 9
              ],
              [
                 -80.1391563,
                 25.7718322,
                 11
              ],
              [
                 -80.1389632,
                 25.7715544,
                 11
              ],
              [
                 -80.13877,
                 25.7712597,
                 11
              ],
              [
                 -80.1386413,
                 25.7710786,
                 10
              ],
              [
                 -80.1385018,
                 25.7708805,
                 11
              ],
              [
                 -80.138306,
                 25.7705955,
                 12
              ],
              [
                 -80.13807,
                 25.7702428,
                 13
              ],
              [
                 -80.1378071,
                 25.7698781,
                 13
              ],
              [
                 -80.1375443,
                 25.7694796,
                 13
              ],
              [
                 -80.1372412,
                 25.7690617,
                 10
              ],
              [
                 -80.1370776,
                 25.7688153,
                 8
              ],
              [
                 -80.1370507,
                 25.7687042,
                 8
              ],
              [
                 -80.1370239,
                 25.7684989,
                 7
              ],
              [
                 -80.1370078,
                 25.7683225,
                 7
              ],
              [
                 -80.1369622,
                 25.7682356,
                 6
              ],
              [
                 -80.1368415,
                 25.7682066,
                 6
              ],
              [
                 -80.1366994,
                 25.7681776,
                 5
              ],
              [
                 -80.1364285,
                 25.7681849,
                 6
              ],
              [
                 -80.1360261,
                 25.7682018,
                 6
              ],
              [
                 -80.1355782,
                 25.7682235,
                 8
              ],
              [
                 -80.1341754,
                 25.768267,
                 10
              ],
              [
                 -80.1332903,
                 25.7683008,
                 8
              ],
              [
                 -80.1332018,
                 25.7684554,
                 8
              ],
              [
                 -80.1331991,
                 25.7686438,
                 7
              ],
              [
                 -80.1331964,
                 25.7687887,
                 7
              ],
              [
                 -80.1331481,
                 25.7689868,
                 7
              ],
              [
                 -80.1330891,
                 25.7692477,
                 8
              ],
              [
                 -80.1330274,
                 25.7695254,
                 9
              ],
              [
                 -80.1329657,
                 25.7698105,
                 10
              ],
              [
                 -80.1329041,
                 25.7700955,
                 11
              ],
              [
                 -80.1328424,
                 25.7703805,
                 11
              ],
              [
                 -80.1327807,
                 25.7706655,
                 11
              ],
              [
                 -80.1327136,
                 25.7709554,
                 11
              ],
              [
                 -80.1326573,
                 25.771267,
                 12
              ],
              [
                 -80.1325956,
                 25.7715882,
                 12
              ],
              [
                 -80.1325071,
                 25.7719505,
                 12
              ],
              [
                 -80.1324132,
                 25.7723467,
                 12
              ],
              [
                 -80.132263,
                 25.7729215,
                 13
              ],
              [
                 -80.1321423,
                 25.7735689,
                 13
              ],
              [
                 -80.1320136,
                 25.7742427,
                 11
              ],
              [
                 -80.1318312,
                 25.7749432,
                 14
              ],
              [
                 -80.1316488,
                 25.7756437,
                 11
              ],
              [
                 -80.1314932,
                 25.7763538,
                 10
              ],
              [
                 -80.1313376,
                 25.7770639,
                 10
              ],
              [
                 -80.1311901,
                 25.7778005,
                 11
              ],
              [
                 -80.1310346,
                 25.7785565,
                 10
              ],
              [
                 -80.1308575,
                 25.7793222,
                 10
              ],
              [
                 -80.1306912,
                 25.7800878,
                 11
              ],
              [
                 -80.1305249,
                 25.7808486,
                 10
              ],
              [
                 -80.130364,
                 25.7815925,
                 9
              ],
              [
                 -80.1301897,
                 25.7823509,
                 11
              ],
              [
                 -80.1301065,
                 25.7827131,
                 12
              ],
              [
                 -80.1299623,
                 25.7836472,
                 12
              ],
              [
                 -80.1298785,
                 25.7841647,
                 14
              ],
              [
                 -80.1297927,
                 25.7847443,
                 14
              ],
              [
                 -80.1297444,
                 25.7851742,
                 13
              ],
              [
                 -80.1307583,
                 25.785307,
                 11
              ],
              [
                 -80.1318419,
                 25.7854544,
                 8
              ],
              [
                 -80.1318553,
                 25.785563,
                 8
              ],
              [
                 -80.1317963,
                 25.7859615,
                 8
              ],
              [
                 -80.1317346,
                 25.7863697,
                 9
              ],
              [
                 -80.1316917,
                 25.7866547,
                 9
              ],
              [
                 -80.1316971,
                 25.7869904,
                 9
              ],
              [
                 -80.1317078,
                 25.7874372,
                 8
              ],
              [
                 -80.1317266,
                 25.7879033,
                 9
              ],
              [
                 -80.1317453,
                 25.7883791,
                 10
              ],
              [
                 -80.1317641,
                 25.7888669,
                 10
              ],
              [
                 -80.1317775,
                 25.789362,
                 11
              ],
              [
                 -80.1317909,
                 25.7898571,
                 13
              ],
              [
                 -80.1318097,
                 25.7907941,
                 16
              ],
              [
                 -80.1318339,
                 25.7917311,
                 10
              ],
              [
                 -80.1318687,
                 25.7926682,
                 12
              ],
              [
                 -80.1318875,
                 25.7936172,
                 15
              ],
              [
                 -80.131909,
                 25.794547,
                 15
              ],
              [
                 -80.1319841,
                 25.7958112,
                 12
              ],
              [
                 -80.1320136,
                 25.7970513,
                 10
              ],
              [
                 -80.1320833,
                 25.7982394,
                 10
              ],
              [
                 -80.1321584,
                 25.7983384,
                 9
              ],
              [
                 -80.1322925,
                 25.798336,
                 9
              ],
              [
                 -80.1325205,
                 25.7982225,
                 9
              ],
              [
                 -80.1329067,
                 25.7980052,
                 9
              ],
              [
                 -80.1333761,
                 25.7977492,
                 9
              ],
              [
                 -80.1338965,
                 25.7974715,
                 9
              ],
              [
                 -80.1344168,
                 25.7971938,
                 9
              ],
              [
                 -80.1345161,
                 25.7971431,
                 9
              ],
              [
                 -80.1346233,
                 25.7971044,
                 9
              ],
              [
                 -80.1346877,
                 25.7971358,
                 9
              ],
              [
                 -80.1347467,
                 25.7972034,
                 9
              ],
              [
                 -80.1347199,
                 25.7973121,
                 9
              ],
              [
                 -80.1346716,
                 25.797457,
                 9
              ],
              [
                 -80.134559,
                 25.7977419,
                 9
              ],
              [
                 -80.1343954,
                 25.7981114,
                 9
              ],
              [
                 -80.1342022,
                 25.798534,
                 9
              ],
              [
                 -80.1339823,
                 25.7990146,
                 9
              ],
              [
                 -80.1337624,
                 25.7994951,
                 9
              ],
              [
                 -80.1336122,
                 25.7998284,
                 9
              ],
              [
                 -80.1335317,
                 25.8002679,
                 8
              ],
              [
                 -80.1334646,
                 25.8008933,
                 7
              ],
              [
                 -80.1333895,
                 25.8015284,
                 8
              ],
              [
                 -80.1333144,
                 25.8021635,
                 9
              ],
              [
                 -80.1332313,
                 25.8028083,
                 9
              ],
              [
                 -80.1331481,
                 25.803453,
                 9
              ],
              [
                 -80.1331267,
                 25.803651,
                 9
              ],
              [
                 -80.1340735,
                 25.8037573,
                 9
              ],
              [
                 -80.1341908,
                 25.8037494,
                 8
              ],
              [
                 -80.1342693,
                 25.803709,
                 8
              ],
              [
                 -80.1343283,
                 25.8036196,
                 8
              ],
              [
                 -80.1343524,
                 25.8034941,
                 8
              ],
              [
                 -80.1343659,
                 25.803325,
                 8
              ],
              [
                 -80.1343927,
                 25.8030715,
                 8
              ],
              [
                 -80.1344275,
                 25.8027745,
                 9
              ],
              [
                 -80.1344785,
                 25.8024437,
                 9
              ],
              [
                 -80.1345268,
                 25.802079,
                 8
              ],
              [
                 -80.1345751,
                 25.8017144,
                 8
              ],
              [
                 -80.1346233,
                 25.8013328,
                 7
              ],
              [
                 -80.1346716,
                 25.8009537,
                 7
              ],
              [
                 -80.1347199,
                 25.8005746,
                 7
              ],
              [
                 -80.1347682,
                 25.800193,
                 8
              ],
              [
                 -80.1348272,
                 25.8000071,
                 9
              ],
              [
                 -80.1350867,
                 25.7994408,
                 8
              ],
              [
                 -80.1353697,
                 25.7988359,
                 9
              ],
              [
                 -80.1355615,
                 25.7984706,
                 7
              ],
              [
                 -80.1358867,
                 25.7977269,
                 7
              ],
              [
                 -80.1362085,
                 25.7970845,
                 8
              ],
              [
                 -80.1365069,
                 25.7964101,
                 7
              ],
              [
                 -80.136692,
                 25.7960231,
                 7
              ],
              [
                 -80.1367423,
                 25.7959428,
                 7
              ],
              [
                 -80.1369381,
                 25.7958149,
                 7
              ],
              [
                 -80.1378286,
                 25.795315,
                 8
              ],
              [
                 -80.1385769,
                 25.7949141,
                 7
              ],
              [
                 -80.139387,
                 25.7944866,
                 7
              ],
              [
                 -80.1402426,
                 25.794023,
                 9
              ],
              [
                 -80.1410982,
                 25.7935593,
                 9
              ],
              [
                 -80.1419833,
                 25.7930521,
                 8
              ],
              [
                 -80.1428524,
                 25.7925498,
                 9
              ],
              [
                 -80.1434961,
                 25.7921924,
                 10
              ],
              [
                 -80.143885,
                 25.7920161,
                 8
              ],
              [
                 -80.1444617,
                 25.7917553,
                 5
              ],
              [
                 -80.144923,
                 25.7916273,
                 4
              ],
              [
                 -80.1456821,
                 25.7915838,
                 2
              ],
              [
                 -80.1465645,
                 25.7915404,
                 5
              ],
              [
                 -80.1472136,
                 25.7915041,
                 6
              ],
              [
                 -80.1479083,
                 25.7914776,
                 6
              ],
              [
                 -80.1488069,
                 25.7914365,
                 10
              ],
              [
                 -80.1497108,
                 25.7913955,
                 10
              ],
              [
                 -80.1506147,
                 25.7913544,
                 4
              ],
              [
                 -80.1515186,
                 25.7913133,
                 2
              ],
              [
                 -80.1524225,
                 25.7912723,
                 2
              ],
              [
                 -80.1533264,
                 25.7912312,
                 3
              ],
              [
                 -80.1542303,
                 25.7911805,
                 7
              ],
              [
                 -80.155153,
                 25.7911443,
                 8
              ],
              [
                 -80.1561132,
                 25.7910936,
                 6
              ],
              [
                 -80.1569635,
                 25.791038,
                 2
              ],
              [
                 -80.1579264,
                 25.790997,
                 5
              ],
              [
                 -80.1588196,
                 25.7909535,
                 9
              ],
              [
                 -80.1597315,
                 25.7909173,
                 9
              ],
              [
                 -80.1606515,
                 25.790869,
                 6
              ],
              [
                 -80.1615715,
                 25.7908207,
                 5
              ],
              [
                 -80.1625344,
                 25.7907772,
                 9
              ],
              [
                 -80.1635778,
                 25.790741,
                 9
              ],
              [
                 -80.1646453,
                 25.7906854,
                 3
              ],
              [
                 -80.1657182,
                 25.7906226,
                 6
              ],
              [
                 -80.1663297,
                 25.7905937,
                 8
              ],
              [
                 -80.1665631,
                 25.7906226,
                 9
              ],
              [
                 -80.1668715,
                 25.7907144,
                 9
              ],
              [
                 -80.1673061,
                 25.790869,
                 9
              ],
              [
                 -80.1676816,
                 25.7909052,
                 9
              ],
              [
                 -80.1680973,
                 25.7908811,
                 9
              ],
              [
                 -80.1686257,
                 25.790869,
                 10
              ],
              [
                 -80.1691729,
                 25.7908521,
                 10
              ],
              [
                 -80.169543,
                 25.7907845,
                 9
              ],
              [
                 -80.1698461,
                 25.7906613,
                 9
              ],
              [
                 -80.170168,
                 25.7905212,
                 8
              ],
              [
                 -80.1704577,
                 25.7904222,
                 7
              ],
              [
                 -80.1708198,
                 25.7903908,
                 6
              ],
              [
                 -80.1713508,
                 25.7903546,
                 6
              ],
              [
                 -80.1717398,
                 25.7903449,
                 7
              ],
              [
                 -80.1720428,
                 25.7903425,
                 7
              ],
              [
                 -80.1723593,
                 25.7903401,
                 8
              ],
              [
                 -80.1727161,
                 25.790328,
                 8
              ],
              [
                 -80.1731291,
                 25.7903135,
                 9
              ],
              [
                 -80.1735717,
                 25.7902894,
                 9
              ],
              [
                 -80.1743335,
                 25.7902507,
                 9
              ],
              [
                 -80.1758422,
                 25.7901753,
                 9
              ],
              [
                 -80.1771726,
                 25.7900901,
                 9
              ],
              [
                 -80.1777211,
                 25.7900744,
                 8
              ],
              [
                 -80.1781368,
                 25.7900793,
                 8
              ],
              [
                 -80.1784533,
                 25.7900551,
                 8
              ],
              [
                 -80.1787993,
                 25.7900165,
                 9
              ],
              [
                 -80.179199,
                 25.7899875,
                 10
              ],
              [
                 -80.1796255,
                 25.7899802,
                 5
              ],
              [
                 -80.1809116,
                 25.7899205,
                 0
              ],
              [
                 -80.1817659,
                 25.7898667,
                 0
              ],
              [
                 -80.1826295,
                 25.7898305,
                 0
              ],
              [
                 -80.1843837,
                 25.7897484,
                 5
              ],
              [
                 -80.1855545,
                 25.7896959,
                 22
              ],
              [
                 -80.1863216,
                 25.7896627,
                 20
              ],
              [
                 -80.187538,
                 25.7896228,
                 15
              ],
              [
                 -80.1887691,
                 25.7895963,
                 13
              ],
              [
                 -80.190255,
                 25.78956,
                 15
              ],
              [
                 -80.1922439,
                 25.7894966,
                 12
              ],
              [
                 -80.1946297,
                 25.7894393,
                 11
              ],
              [
                 -80.1946881,
                 25.789391,
                 11
              ],
              [
                 -80.1946968,
                 25.7892702,
                 11
              ],
              [
                 -80.1946566,
                 25.7889418,
                 11
              ],
              [
                 -80.1946244,
                 25.7886085,
                 11
              ],
              [
                 -80.1945895,
                 25.7884757,
                 11
              ],
              [
                 -80.1944822,
                 25.7883766,
                 11
              ],
              [
                 -80.1943347,
                 25.7882849,
                 11
              ],
              [
                 -80.1941979,
                 25.7881931,
                 11
              ],
              [
                 -80.1941174,
                 25.7880699,
                 11
              ],
              [
                 -80.1941121,
                 25.7878719,
                 11
              ],
              [
                 -80.1940987,
                 25.7875821,
                 11
              ],
              [
                 -80.1940852,
                 25.7872899,
                 11
              ],
              [
                 -80.1940718,
                 25.7869976,
                 11
              ],
              [
                 -80.1940584,
                 25.786703,
                 11
              ],
              [
                 -80.194045,
                 25.7863721,
                 11
              ],
              [
                 -80.1940316,
                 25.786034,
                 11
              ],
              [
                 -80.1939927,
                 25.7851374,
                 11
              ],
              [
                 -80.1939531,
                 25.7839002,
                 11
              ],
              [
                 -80.1939109,
                 25.7826824,
                 14
              ],
              [
                 -80.1938774,
                 25.7817157,
                 18
              ],
              [
                 -80.1937721,
                 25.7791066,
                 15
              ],
              [
                 -80.1937506,
                 25.7781296,
                 20
              ],
              [
                 -80.1937158,
                 25.777056,
                 18
              ],
              [
                 -80.1936279,
                 25.7751316,
                 18
              ],
              [
                 -80.1936883,
                 25.7751171,
                 17
              ],
              [
                 -80.1946049,
                 25.7750767,
                 17
              ],
              [
                 -80.1953781,
                 25.7750591,
                 20
              ],
              [
                 -80.1954961,
                 25.775035,
                 20
              ],
              [
                 -80.1955605,
                 25.7749577,
                 21
              ],
              [
                 -80.1955551,
                 25.7747814,
                 20
              ],
              [
                 -80.1955497,
                 25.7745447,
                 19
              ],
              [
                 -80.1955444,
                 25.7742983,
                 18
              ],
              [
                 -80.1955283,
                 25.7741944,
                 18
              ],
              [
                 -80.1954934,
                 25.7741316,
                 18
              ],
              [
                 -80.1953191,
                 25.7741244,
                 18
              ],
              [
                 -80.1949543,
                 25.7741437,
                 18
              ],
              [
                 -80.1936615,
                 25.7741679,
                 25
              ],
              [
                 -80.193581,
                 25.7740809,
                 25
              ],
              [
                 -80.1935729,
                 25.7736745,
                 23
              ],
              [
                 -80.1935582,
                 25.7729849,
                 19
              ],
              [
                 -80.1935367,
                 25.7721915,
                 14
              ],
              [
                 -80.19352,
                 25.7714983,
                 9
              ],
              [
                 -80.1935568,
                 25.7712332,
                 8
              ],
              [
                 -80.1936159,
                 25.7708056,
                 7
              ],
              [
                 -80.1936346,
                 25.7706341,
                 6
              ],
              [
                 -80.1936185,
                 25.7701849,
                 5
              ],
              [
                 -80.1936024,
                 25.7697308,
                 6
              ],
              [
                 -80.1935863,
                 25.7692767,
                 9
              ],
              [
                 -80.1935703,
                 25.7688225,
                 9
              ],
              [
                 -80.193589,
                 25.7686124,
                 9
              ],
              [
                 -80.1936373,
                 25.7684361,
                 9
              ],
              [
                 -80.1937285,
                 25.7682646,
                 9
              ],
              [
                 -80.1938814,
                 25.7681196,
                 9
              ],
              [
                 -80.1941603,
                 25.7679554,
                 8
              ],
              [
                 -80.1945359,
                 25.7677307,
                 9
              ],
              [
                 -80.1946512,
                 25.7677863,
                 9
              ],
              [
                 -80.1947719,
                 25.7679264,
                 10
              ],
              [
                 -80.1949033,
                 25.7681124,
                 10
              ],
              [
                 -80.1949275,
                 25.7681824,
                 10
              ],
              [
                 -80.1948845,
                 25.7682428,
                 10
              ],
              [
                 -80.1948148,
                 25.7682718,
                 10
              ],
              [
                 -80.194678,
                 25.7682791,
                 10
              ],
              [
                 -80.1945305,
                 25.7682863,
                 9
              ],
              [
                 -80.1943642,
                 25.7682984,
                 9
              ],
              [
                 -80.1942569,
                 25.7683757,
                 8
              ],
              [
                 -80.1941442,
                 25.768494,
                 8
              ],
              [
                 -80.1939297,
                 25.768639,
                 9
              ],
              [
                 -80.1937258,
                 25.7688008,
                 9
              ],
              [
                 -80.1935622,
                 25.7688346,
                 9
              ],
              [
                 -80.1933128,
                 25.7688225,
                 10
              ],
              [
                 -80.1922104,
                 25.7688165,
                 12
              ],
              [
                 -80.1921869,
                 25.7683612,
                 12
              ],
              [
                 -80.1903536,
                 25.7679699,
                 18
              ],
              [
                 -80.1904589,
                 25.7675617,
                 19
              ],
              [
                 -80.1905635,
                 25.7671607,
                 23
              ],
              [
                 -80.1908257,
                 25.7662808,
                 29
              ],
              [
                 -80.1908445,
                 25.7660169,
                 33
              ],
              [
                 -80.1909786,
                 25.7654952,
                 33
              ],
              [
                 -80.1911006,
                 25.7650682,
                 30
              ],
              [
                 -80.191291,
                 25.7642965,
                 19
              ],
              [
                 -80.1917329,
                 25.7626967,
                 23
              ],
              [
                 -80.1919757,
                 25.7618199,
                 32
              ],
              [
                 -80.192038,
                 25.7615396,
                 32
              ],
              [
                 -80.1920387,
                 25.7614358,
                 30
              ],
              [
                 -80.1922875,
                 25.7603862,
                 17
              ],
              [
                 -80.1925296,
                 25.7594362,
                 12
              ],
              [
                 -80.1928199,
                 25.7583649,
                 12
              ],
              [
                 -80.1929091,
                 25.7581257,
                 12
              ],
              [
                 -80.1930305,
                 25.7578884,
                 13
              ],
              [
                 -80.1936977,
                 25.7574397,
                 14
              ],
              [
                 -80.1949657,
                 25.7565664,
                 21
              ],
              [
                 -80.1967058,
                 25.755367,
                 14
              ],
              [
                 -80.1974762,
                 25.7548379,
                 16
              ],
              [
                 -80.1988468,
                 25.7538921,
                 15
              ],
              [
                 -80.1998446,
                 25.7532061,
                 15
              ],
              [
                 -80.2009034,
                 25.7525031,
                 12
              ],
              [
                 -80.2028742,
                 25.7510952,
                 15
              ],
              [
                 -80.2029976,
                 25.7509738,
                 15
              ],
              [
                 -80.2029996,
                 25.7508585,
                 15
              ],
              [
                 -80.2035588,
                 25.7504925,
                 14
              ],
              [
                 -80.2036205,
                 25.7504273,
                 14
              ],
              [
                 -80.2036527,
                 25.7503113,
                 15
              ],
              [
                 -80.2036393,
                 25.7502195,
                 15
              ],
              [
                 -80.203591,
                 25.7501422,
                 15
              ],
              [
                 -80.2035213,
                 25.7500576,
                 15
              ],
              [
                 -80.2034542,
                 25.7499731,
                 15
              ],
              [
                 -80.2033737,
                 25.7498837,
                 14
              ],
              [
                 -80.2032584,
                 25.7497581,
                 15
              ],
              [
                 -80.2031377,
                 25.74963,
                 15
              ],
              [
                 -80.2029634,
                 25.7494827,
                 14
              ],
              [
                 -80.2027622,
                 25.7493353,
                 14
              ],
              [
                 -80.2025878,
                 25.7491613,
                 12
              ],
              [
                 -80.2024055,
                 25.7489729,
                 12
              ],
              [
                 -80.2022097,
                 25.7487555,
                 12
              ],
              [
                 -80.2020139,
                 25.748526,
                 12
              ],
              [
                 -80.2017912,
                 25.7483013,
                 12
              ],
              [
                 -80.2012762,
                 25.7478084,
                 8
              ],
              [
                 -80.2009597,
                 25.7475427,
                 7
              ],
              [
                 -80.2005923,
                 25.7473059,
                 6
              ],
              [
                 -80.2002543,
                 25.7470879,
                 6
              ],
              [
                 -80.1998037,
                 25.7468493,
                 10
              ],
              [
                 -80.198983,
                 25.7465183,
                 16
              ],
              [
                 -80.1984572,
                 25.7463492,
                 13
              ],
              [
                 -80.1978779,
                 25.7462284,
                 11
              ],
              [
                 -80.1972342,
                 25.746139,
                 9
              ],
              [
                 -80.1963007,
                 25.74611,
                 6
              ],
              [
                 -80.1953807,
                 25.7461173,
                 7
              ],
              [
                 -80.1944876,
                 25.746139,
                 9
              ],
              [
                 -80.1935461,
                 25.7461946,
                 7
              ],
              [
                 -80.1925591,
                 25.7463009,
                 6
              ],
              [
                 -80.1916069,
                 25.7464531,
                 4
              ],
              [
                 -80.1906654,
                 25.7465497,
                 3
              ],
              [
                 -80.1897642,
                 25.7465715,
                 3
              ],
              [
                 -80.189563,
                 25.7465461,
                 3
              ],
              [
                 -80.1895148,
                 25.7464265,
                 4
              ],
              [
                 -80.1895148,
                 25.7462647,
                 4
              ],
              [
                 -80.1895684,
                 25.746156,
                 5
              ],
              [
                 -80.1896703,
                 25.7461197,
                 5
              ],
              [
                 -80.1905487,
                 25.7461088,
                 6
              ],
              [
                 -80.1916713,
                 25.7461149,
                 6
              ],
              [
                 -80.1924679,
                 25.7460714,
                 7
              ],
              [
                 -80.1934717,
                 25.7459796,
                 8
              ],
              [
                 -80.1940906,
                 25.7459119,
                 11
              ],
              [
                 -80.1945519,
                 25.7458709,
                 11
              ],
              [
                 -80.1952165,
                 25.7458503,
                 9
              ],
              [
                 -80.1959158,
                 25.7458395,
                 7
              ],
              [
                 -80.1969163,
                 25.7458467,
                 9
              ],
              [
                 -80.1975533,
                 25.7459089,
                 11
              ],
              [
                 -80.198295,
                 25.7460207,
                 13
              ],
              [
                 -80.1990983,
                 25.7462453,
                 17
              ],
              [
                 -80.1999298,
                 25.7465715,
                 11
              ],
              [
                 -80.2008337,
                 25.7469701,
                 7
              ],
              [
                 -80.201472,
                 25.7473615,
                 5
              ],
              [
                 -80.2020487,
                 25.7478133,
                 7
              ],
              [
                 -80.2025288,
                 25.7482312,
                 10
              ],
              [
                 -80.2027488,
                 25.7484342,
                 12
              ],
              [
                 -80.2029821,
                 25.7487361,
                 13
              ],
              [
                 -80.2031806,
                 25.7490478,
                 15
              ],
              [
                 -80.2034193,
                 25.7493715,
                 16
              ],
              [
                 -80.2036956,
                 25.7497049,
                 16
              ],
              [
                 -80.2039638,
                 25.7500238,
                 16
              ],
              [
                 -80.2040952,
                 25.7501422,
                 16
              ],
              [
                 -80.2042776,
                 25.7501977,
                 16
              ],
              [
                 -80.2044654,
                 25.750205,
                 16
              ],
              [
                 -80.2051252,
                 25.7501301,
                 15
              ],
              [
                 -80.206237,
                 25.7500733,
                 14
              ],
              [
                 -80.2064744,
                 25.7500625,
                 13
              ],
              [
                 -80.2065414,
                 25.7500383,
                 12
              ],
              [
                 -80.2067875,
                 25.7498831,
                 12
              ],
              [
                 -80.207381,
                 25.7494198,
                 13
              ],
              [
                 -80.2084351,
                 25.7486842,
                 11
              ],
              [
                 -80.2093524,
                 25.7480736,
                 13
              ],
              [
                 -80.2112192,
                 25.7467587,
                 18
              ],
              [
                 -80.2121392,
                 25.7461221,
                 18
              ],
              [
                 -80.2136546,
                 25.7450803,
                 17
              ],
              [
                 -80.2150836,
                 25.7440843,
                 13
              ],
              [
                 -80.2157092,
                 25.743653,
                 12
              ],
              [
                 -80.2165326,
                 25.7430901,
                 14
              ],
              [
                 -80.2188628,
                 25.7415257,
                 11
              ],
              [
                 -80.2194133,
                 25.74115,
                 11
              ],
              [
                 -80.2202851,
                 25.7405678,
                 13
              ],
              [
                 -80.2217516,
                 25.7397469,
                 12
              ],
              [
                 -80.2223772,
                 25.739425,
                 12
              ],
              [
                 -80.2230075,
                 25.7390964,
                 14
              ],
              [
                 -80.2236459,
                 25.7387774,
                 13
              ],
              [
                 -80.224303,
                 25.7384513,
                 11
              ],
              [
                 -80.2250353,
                 25.7380695,
                 11
              ],
              [
                 -80.225797,
                 25.7376709,
                 11
              ],
              [
                 -80.2265936,
                 25.737265,
                 13
              ],
              [
                 -80.2271354,
                 25.7369871,
                 12
              ],
              [
                 -80.2276772,
                 25.7366803,
                 12
              ],
              [
                 -80.2281386,
                 25.7363493,
                 14
              ],
              [
                 -80.2286401,
                 25.7359506,
                 13
              ],
              [
                 -80.2291444,
                 25.7355471,
                 13
              ],
              [
                 -80.2296621,
                 25.7351605,
                 13
              ],
              [
                 -80.2302173,
                 25.7347715,
                 13
              ],
              [
                 -80.2307913,
                 25.7343728,
                 12
              ],
              [
                 -80.2313814,
                 25.73395,
                 11
              ],
              [
                 -80.23206,
                 25.7334499,
                 10
              ],
              [
                 -80.2326554,
                 25.7330004,
                 12
              ],
              [
                 -80.2333099,
                 25.7325148,
                 16
              ],
              [
                 -80.2337846,
                 25.7321209,
                 14
              ],
              [
                 -80.2339858,
                 25.7319397,
                 13
              ],
              [
                 -80.2342647,
                 25.7317319,
                 13
              ],
              [
                 -80.234592,
                 25.7314975,
                 15
              ],
              [
                 -80.2349085,
                 25.7312777,
                 16
              ],
              [
                 -80.23518,
                 25.7310638,
                 15
              ],
              [
                 -80.2354885,
                 25.7307135,
                 14
              ],
              [
                 -80.2359311,
                 25.7301891,
                 12
              ],
              [
                 -80.2363642,
                 25.729666,
                 10
              ],
              [
                 -80.2365446,
                 25.7294703,
                 10
              ],
              [
                 -80.2366707,
                 25.7293253,
                 10
              ],
              [
                 -80.2368021,
                 25.7290789,
                 10
              ],
              [
                 -80.2369335,
                 25.7289025,
                 11
              ],
              [
                 -80.2371159,
                 25.7287068,
                 11
              ],
              [
                 -80.2373761,
                 25.7285207,
                 10
              ],
              [
                 -80.237706,
                 25.7282839,
                 12
              ],
              [
                 -80.2380788,
                 25.727994,
                 14
              ],
              [
                 -80.2384758,
                 25.7277088,
                 15
              ],
              [
                 -80.2387393,
                 25.7274829,
                 16
              ],
              [
                 -80.239141,
                 25.7270951,
                 15
              ],
              [
                 -80.2397599,
                 25.7264197,
                 11
              ],
              [
                 -80.2399161,
                 25.7262977,
                 11
              ],
              [
                 -80.2400744,
                 25.7262373,
                 11
              ],
              [
                 -80.2402541,
                 25.7262874,
                 11
              ],
              [
                 -80.2411319,
                 25.7270105,
                 14
              ],
              [
                 -80.2413069,
                 25.7272274,
                 14
              ],
              [
                 -80.2421665,
                 25.7279637,
                 15
              ],
              [
                 -80.2422684,
                 25.7280199,
                 15
              ],
              [
                 -80.2424113,
                 25.7279952,
                 14
              ],
              [
                 -80.2437215,
                 25.7268015,
                 15
              ],
              [
                 -80.2438121,
                 25.7268093,
                 15
              ],
              [
                 -80.2439468,
                 25.7269096,
                 15
              ],
              [
                 -80.2450117,
                 25.7278635,
                 14
              ],
              [
                 -80.2450016,
                 25.7279021,
                 14
              ],
              [
                 -80.2448353,
                 25.7279311,
                 14
              ],
              [
                 -80.2441353,
                 25.727948,
                 14
              ],
              [
                 -80.2434218,
                 25.7279794,
                 13
              ],
              [
                 -80.242601,
                 25.7280229,
                 14
              ],
              [
                 -80.2419707,
                 25.7281051,
                 15
              ],
              [
                 -80.2414048,
                 25.7281268,
                 16
              ],
              [
                 -80.2406645,
                 25.7281317,
                 17
              ],
              [
                 -80.2395111,
                 25.7281558,
                 18
              ],
              [
                 -80.2393448,
                 25.7281728,
                 18
              ],
              [
                 -80.2388808,
                 25.7277185,
                 17
              ],
              [
                 -80.2388272,
                 25.7276798,
                 17
              ],
              [
                 -80.2386662,
                 25.7278055,
                 16
              ],
              [
                 -80.2382746,
                 25.7280833,
                 16
              ],
              [
                 -80.2376845,
                 25.7285159,
                 12
              ],
              [
                 -80.2372822,
                 25.7288469,
                 11
              ],
              [
                 -80.236904,
                 25.7292939,
                 11
              ],
              [
                 -80.2367216,
                 25.7295235,
                 11
              ],
              [
                 -80.2364883,
                 25.7297965,
                 11
              ],
              [
                 -80.2362201,
                 25.7301396,
                 12
              ],
              [
                 -80.235866,
                 25.7305866,
                 15
              ],
              [
                 -80.2354905,
                 25.7310312,
                 17
              ],
              [
                 -80.2352169,
                 25.7312559,
                 17
              ],
              [
                 -80.2346081,
                 25.7316957,
                 17
              ],
              [
                 -80.2339724,
                 25.7321548,
                 15
              ],
              [
                 -80.2335875,
                 25.7324544,
                 16
              ],
              [
                 -80.233568,
                 25.7325311,
                 17
              ],
              [
                 -80.2346134,
                 25.7336685,
                 16
              ],
              [
                 -80.2346094,
                 25.7337126,
                 16
              ],
              [
                 -80.2344612,
                 25.7338117,
                 16
              ],
              [
                 -80.2343144,
                 25.7339325,
                 15
              ],
              [
                 -80.2336646,
                 25.7344574,
                 14
              ],
              [
                 -80.2323369,
                 25.735477,
                 15
              ],
              [
                 -80.2313036,
                 25.7362683,
                 15
              ],
              [
                 -80.2303433,
                 25.7369871,
                 16
              ],
              [
                 -80.2296346,
                 25.7375712,
                 16
              ],
              [
                 -80.2288433,
                 25.7383637,
                 17
              ],
              [
                 -80.2283639,
                 25.7387454,
                 17
              ],
              [
                 -80.2275136,
                 25.7391701,
                 15
              ],
              [
                 -80.2258647,
                 25.7400133,
                 16
              ],
              [
                 -80.2246852,
                 25.74061,
                 15
              ],
              [
                 -80.2230585,
                 25.7414297,
                 16
              ],
              [
                 -80.2216986,
                 25.7421068,
                 17
              ],
              [
                 -80.2214599,
                 25.7422215,
                 17
              ],
              [
                 -80.2210736,
                 25.7423598,
                 17
              ],
              [
                 -80.2203897,
                 25.7427923,
                 16
              ],
              [
                 -80.2196018,
                 25.7433317,
                 15
              ],
              [
                 -80.2176196,
                 25.7446732,
                 13
              ],
              [
                 -80.2176726,
                 25.7447487,
                 13
              ],
              [
                 -80.2177906,
                 25.7449117,
                 12
              ],
              [
                 -80.2187562,
                 25.746069,
                 12
              ],
              [
                 -80.2188098,
                 25.7461825,
                 12
              ],
              [
                 -80.2187133,
                 25.7463275,
                 11
              ],
              [
                 -80.2185631,
                 25.7464193,
                 11
              ],
              [
                 -80.2179998,
                 25.7466029,
                 11
              ],
              [
                 -80.2154785,
                 25.7474968,
                 11
              ],
              [
                 -80.2141804,
                 25.7479945,
                 12
              ],
              [
                 -80.2137083,
                 25.7481527,
                 12
              ],
              [
                 -80.2135849,
                 25.7481491,
                 12
              ],
              [
                 -80.2133864,
                 25.7480621,
                 12
              ],
              [
                 -80.2130914,
                 25.7477239,
                 12
              ],
              [
                 -80.2126193,
                 25.7471537,
                 12
              ],
              [
                 -80.2123886,
                 25.7468312,
                 13
              ],
              [
                 -80.2123618,
                 25.746714,
                 14
              ],
              [
                 -80.2124369,
                 25.7466174,
                 14
              ],
              [
                 -80.2129573,
                 25.7462937,
                 14
              ],
              [
                 -80.2128339,
                 25.7461004,
                 15
              ],
              [
                 -80.212689,
                 25.7459941,
                 16
              ],
              [
                 -80.2125764,
                 25.7460037,
                 16
              ],
              [
                 -80.2121311,
                 25.7462453,
                 17
              ],
              [
                 -80.2113909,
                 25.746772,
                 18
              ],
              [
                 -80.2100068,
                 25.7477577,
                 16
              ],
              [
                 -80.2085155,
                 25.7487675,
                 11
              ],
              [
                 -80.2070886,
                 25.7497339,
                 12
              ],
              [
                 -80.2064583,
                 25.7501905,
                 13
              ],
              [
                 -80.2059191,
                 25.7506326,
                 15
              ],
              [
                 -80.2053693,
                 25.7510288,
                 14
              ],
              [
                 -80.2048436,
                 25.7513718,
                 13
              ],
              [
                 -80.2043286,
                 25.7517294,
                 12
              ],
              [
                 -80.2037385,
                 25.7521087,
                 12
              ],
              [
                 -80.203025,
                 25.7526087,
                 13
              ],
              [
                 -80.2022982,
                 25.7531016,
                 13
              ],
              [
                 -80.2015123,
                 25.7536524,
                 12
              ],
              [
                 -80.2007023,
                 25.7542297,
                 15
              ],
              [
                 -80.1998493,
                 25.754795,
                 13
              ],
              [
                 -80.1989964,
                 25.7553821,
                 12
              ],
              [
                 -80.1981434,
                 25.7559691,
                 11
              ],
              [
                 -80.1972905,
                 25.7565537,
                 13
              ],
              [
                 -80.1964509,
                 25.7571335,
                 14
              ],
              [
                 -80.1956168,
                 25.7577084,
                 15
              ],
              [
                 -80.1947826,
                 25.7582858,
                 18
              ],
              [
                 -80.1944473,
                 25.7585104,
                 15
              ],
              [
                 -80.1943079,
                 25.7586191,
                 15
              ],
              [
                 -80.1942891,
                 25.7587133,
                 14
              ],
              [
                 -80.1942328,
                 25.7587906,
                 14
              ],
              [
                 -80.1941711,
                 25.75881,
                 14
              ],
              [
                 -80.1940906,
                 25.7588341,
                 14
              ],
              [
                 -80.1940289,
                 25.7589114,
                 14
              ],
              [
                 -80.1939484,
                 25.7592206,
                 13
              ],
              [
                 -80.1938063,
                 25.7597472,
                 13
              ],
              [
                 -80.1936293,
                 25.760356,
                 13
              ],
              [
                 -80.1934737,
                 25.7609188,
                 13
              ],
              [
                 -80.1933342,
                 25.7614913,
                 14
              ],
              [
                 -80.1932269,
                 25.7617788,
                 16
              ],
              [
                 -80.1931706,
                 25.7620614,
                 17
              ],
              [
                 -80.1931572,
                 25.7624817,
                 17
              ],
              [
                 -80.1931572,
                 25.7628996,
                 15
              ],
              [
                 -80.1931572,
                 25.7633103,
                 12
              ],
              [
                 -80.193176,
                 25.7637499,
                 12
              ],
              [
                 -80.1932028,
                 25.7642137,
                 11
              ],
              [
                 -80.1932135,
                 25.7647089,
                 13
              ],
              [
                 -80.1932403,
                 25.7654336,
                 13
              ],
              [
                 -80.1932779,
                 25.7661679,
                 13
              ],
              [
                 -80.1932859,
                 25.7669046,
                 13
              ],
              [
                 -80.1933315,
                 25.7676534,
                 12
              ],
              [
                 -80.1933691,
                 25.7684192,
                 10
              ],
              [
                 -80.1933879,
                 25.7691655,
                 10
              ],
              [
                 -80.1934227,
                 25.7699119,
                 5
              ],
              [
                 -80.1934415,
                 25.7706583,
                 5
              ],
              [
                 -80.193463,
                 25.7710399,
                 7
              ],
              [
                 -80.1934442,
                 25.7713129,
                 8
              ],
              [
                 -80.1934227,
                 25.7715278,
                 9
              ],
              [
                 -80.1934737,
                 25.7721534,
                 13
              ],
              [
                 -80.1935005,
                 25.7726462,
                 17
              ],
              [
                 -80.1935139,
                 25.7733104,
                 21
              ],
              [
                 -80.1925564,
                 25.7733225,
                 21
              ],
              [
                 -80.1915184,
                 25.7733418,
                 19
              ],
              [
                 -80.1904804,
                 25.773378,
                 26
              ],
              [
                 -80.1894423,
                 25.7734143,
                 39
              ],
              [
                 -80.1884043,
                 25.7734553,
                 31
              ],
              [
                 -80.1875031,
                 25.7734771,
                 20
              ],
              [
                 -80.187149,
                 25.7735012,
                 18
              ],
              [
                 -80.1870391,
                 25.7735471,
                 17
              ],
              [
                 -80.1870096,
                 25.7736558,
                 17
              ]
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 0,
           eventCourseId: 1157480,
           timingPointId: 321200,
           timingPointType: 'START'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.1892613,
              25.7821872,
              11
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 50,
           endIntervalId: 268763,
           eventCourseId: 1157480,
           timingPointId: 369327,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.18925675795951,
              25.782639946358607
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 5000,
           endIntervalId: 268764,
           eventCourseId: 1157480,
           timingPointId: 369328,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.1484336734332,
              25.771975171728425
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 10000,
           endIntervalId: 268765,
           eventCourseId: 1157480,
           timingPointId: 369329,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.1318958163042,
              25.793976852875815
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 15000,
           endIntervalId: 268766,
           eventCourseId: 1157480,
           timingPointId: 369330,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.16019064559646,
              25.790893195584488
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 20000,
           endIntervalId: 268767,
           eventCourseId: 1157480,
           timingPointId: 369331,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.19364717860314,
              25.775553672969593
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 25000,
           endIntervalId: 268768,
           eventCourseId: 1157480,
           timingPointId: 369332,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.19910395640478,
              25.746567083845825
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 30000,
           endIntervalId: 268769,
           eventCourseId: 1157480,
           timingPointId: 369333,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.22376916718785,
              25.738716226369277
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 35000,
           endIntervalId: 268770,
           eventCourseId: 1157480,
           timingPointId: 369334,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.2299240635864,
              25.73733262952491
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 40000,
           endIntervalId: 268771,
           eventCourseId: 1157480,
           timingPointId: 369335,
           timingPointType: 'SPLIT'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.1938397916244,
              25.75962308579974
           ]
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'POINT',
           distanceFromStart: 42164.8128,
           endIntervalId: 230078,
           eventCourseId: 1157480,
           timingPointId: 321201,
           timingPointType: 'FINISH'
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.1870466273397,
              25.773543956192192
           ]
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.1892613,
                 25.7821872,
                 11
              ],
              [
                 -80.1892532,
                 25.7823702,
                 10
              ],
              [
                 -80.18925675795965,
                 25.782639946051795,
                 10
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268763,
           intervalName: 'Interval 1'
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.18925675795965,
                 25.782639946051795,
                 10
              ],
              [
                 -80.1892586,
                 25.7827796,
                 10
              ],
              [
                 -80.1892519,
                 25.7832155,
                 10
              ],
              [
                 -80.1892492,
                 25.7836744,
                 9
              ],
              [
                 -80.1892465,
                 25.7841333,
                 8
              ],
              [
                 -80.1892385,
                 25.7842975,
                 8
              ],
              [
                 -80.1892251,
                 25.7844279,
                 8
              ],
              [
                 -80.1891983,
                 25.7845656,
                 8
              ],
              [
                 -80.1891527,
                 25.7848047,
                 9
              ],
              [
                 -80.1891071,
                 25.785039,
                 9
              ],
              [
                 -80.1890588,
                 25.7853143,
                 9
              ],
              [
                 -80.1890025,
                 25.7855268,
                 9
              ],
              [
                 -80.1889408,
                 25.7856524,
                 9
              ],
              [
                 -80.1888174,
                 25.78572,
                 9
              ],
              [
                 -80.1886377,
                 25.7858118,
                 10
              ],
              [
                 -80.1884177,
                 25.7859036,
                 10
              ],
              [
                 -80.1881844,
                 25.7859978,
                 11
              ],
              [
                 -80.187656,
                 25.7862489,
                 12
              ],
              [
                 -80.187369,
                 25.7863866,
                 12
              ],
              [
                 -80.1869398,
                 25.7865967,
                 13
              ],
              [
                 -80.1864919,
                 25.7867851,
                 13
              ],
              [
                 -80.1862183,
                 25.7868575,
                 13
              ],
              [
                 -80.1859501,
                 25.7869058,
                 12
              ],
              [
                 -80.1856926,
                 25.7869276,
                 11
              ],
              [
                 -80.1852071,
                 25.7869566,
                 9
              ],
              [
                 -80.1847216,
                 25.786988,
                 10
              ],
              [
                 -80.1842174,
                 25.7870194,
                 13
              ],
              [
                 -80.1837131,
                 25.7870483,
                 6
              ],
              [
                 -80.182997,
                 25.7870894,
                 3
              ],
              [
                 -80.1822728,
                 25.7871232,
                 6
              ],
              [
                 -80.1815352,
                 25.7871667,
                 2
              ],
              [
                 -80.1807976,
                 25.7872102,
                 2
              ],
              [
                 -80.18006,
                 25.7872536,
                 2
              ],
              [
                 -80.1795316,
                 25.7872754,
                 5
              ],
              [
                 -80.1792741,
                 25.7872826,
                 6
              ],
              [
                 -80.1790005,
                 25.7872705,
                 7
              ],
              [
                 -80.1787242,
                 25.7872343,
                 8
              ],
              [
                 -80.1784506,
                 25.7871908,
                 8
              ],
              [
                 -80.1781932,
                 25.7871184,
                 8
              ],
              [
                 -80.1779437,
                 25.7870411,
                 8
              ],
              [
                 -80.1777372,
                 25.7869614,
                 8
              ],
              [
                 -80.1775146,
                 25.78686,
                 9
              ],
              [
                 -80.1772839,
                 25.7867344,
                 10
              ],
              [
                 -80.1770639,
                 25.7865895,
                 10
              ],
              [
                 -80.1768735,
                 25.7864301,
                 9
              ],
              [
                 -80.1767609,
                 25.7863214,
                 9
              ],
              [
                 -80.1766482,
                 25.7862127,
                 8
              ],
              [
                 -80.1765463,
                 25.7861016,
                 8
              ],
              [
                 -80.1764444,
                 25.7859688,
                 8
              ],
              [
                 -80.1763478,
                 25.7858287,
                 8
              ],
              [
                 -80.1762512,
                 25.785679,
                 8
              ],
              [
                 -80.1761547,
                 25.7855172,
                 9
              ],
              [
                 -80.176042,
                 25.7853481,
                 9
              ],
              [
                 -80.1759294,
                 25.785179,
                 10
              ],
              [
                 -80.1757872,
                 25.7849496,
                 10
              ],
              [
                 -80.1756477,
                 25.7847081,
                 10
              ],
              [
                 -80.1755083,
                 25.7844641,
                 10
              ],
              [
                 -80.1753688,
                 25.7842226,
                 10
              ],
              [
                 -80.1752052,
                 25.7839859,
                 10
              ],
              [
                 -80.1750416,
                 25.7837493,
                 9
              ],
              [
                 -80.1748484,
                 25.7835126,
                 9
              ],
              [
                 -80.1746473,
                 25.7832807,
                 9
              ],
              [
                 -80.1744434,
                 25.7830271,
                 9
              ],
              [
                 -80.1742262,
                 25.7827784,
                 10
              ],
              [
                 -80.1740357,
                 25.7825706,
                 10
              ],
              [
                 -80.1737541,
                 25.7822929,
                 8
              ],
              [
                 -80.173628,
                 25.7821721,
                 8
              ],
              [
                 -80.1733786,
                 25.7819234,
                 8
              ],
              [
                 -80.1730031,
                 25.7816022,
                 7
              ],
              [
                 -80.1727456,
                 25.7814041,
                 6
              ],
              [
                 -80.1724881,
                 25.7812109,
                 5
              ],
              [
                 -80.1722735,
                 25.7810732,
                 6
              ],
              [
                 -80.1720455,
                 25.7809331,
                 7
              ],
              [
                 -80.1718578,
                 25.7808438,
                 8
              ],
              [
                 -80.1714903,
                 25.7806602,
                 7
              ],
              [
                 -80.1712865,
                 25.7805636,
                 6
              ],
              [
                 -80.1709244,
                 25.7804235,
                 5
              ],
              [
                 -80.1705757,
                 25.7802665,
                 2
              ],
              [
                 -80.1702055,
                 25.7801071,
                 0
              ],
              [
                 -80.1698354,
                 25.7799477,
                 0
              ],
              [
                 -80.1694438,
                 25.7797666,
                 0
              ],
              [
                 -80.1689959,
                 25.7795734,
                 0
              ],
              [
                 -80.1684836,
                 25.7793656,
                 0
              ],
              [
                 -80.1679713,
                 25.7791555,
                 0
              ],
              [
                 -80.1673543,
                 25.7788729,
                 0
              ],
              [
                 -80.1667053,
                 25.7785831,
                 0
              ],
              [
                 -80.1660562,
                 25.7782933,
                 0
              ],
              [
                 -80.1653561,
                 25.7779986,
                 0
              ],
              [
                 -80.164656,
                 25.7776967,
                 0
              ],
              [
                 -80.1639104,
                 25.7773779,
                 1
              ],
              [
                 -80.1631647,
                 25.777059,
                 1
              ],
              [
                 -80.1624325,
                 25.7767402,
                 0
              ],
              [
                 -80.1618746,
                 25.7764939,
                 0
              ],
              [
                 -80.1612899,
                 25.7762354,
                 0
              ],
              [
                 -80.160681,
                 25.775977,
                 0
              ],
              [
                 -80.1601285,
                 25.7757451,
                 0
              ],
              [
                 -80.1595759,
                 25.7755132,
                 0
              ],
              [
                 -80.1592943,
                 25.7753876,
                 0
              ],
              [
                 -80.1589376,
                 25.7752379,
                 0
              ],
              [
                 -80.1585701,
                 25.7750736,
                 0
              ],
              [
                 -80.1581758,
                 25.7749046,
                 0
              ],
              [
                 -80.1577815,
                 25.7747355,
                 0
              ],
              [
                 -80.1573873,
                 25.7745664,
                 0
              ],
              [
                 -80.156993,
                 25.7743949,
                 0
              ],
              [
                 -80.1565719,
                 25.774221,
                 0
              ],
              [
                 -80.15614,
                 25.7740326,
                 0
              ],
              [
                 -80.1556921,
                 25.7738442,
                 0
              ],
              [
                 -80.1552415,
                 25.7736365,
                 0
              ],
              [
                 -80.1547587,
                 25.7734312,
                 0
              ],
              [
                 -80.1542759,
                 25.7732235,
                 0
              ],
              [
                 -80.1537931,
                 25.7730157,
                 0
              ],
              [
                 -80.1533049,
                 25.772808,
                 0
              ],
              [
                 -80.1528168,
                 25.7726003,
                 0
              ],
              [
                 -80.1523286,
                 25.7723926,
                 1
              ],
              [
                 -80.1520953,
                 25.7723008,
                 3
              ],
              [
                 -80.1518539,
                 25.772209,
                 4
              ],
              [
                 -80.1515964,
                 25.7721317,
                 4
              ],
              [
                 -80.1513281,
                 25.7720544,
                 4
              ],
              [
                 -80.1510116,
                 25.7719795,
                 4
              ],
              [
                 -80.1506951,
                 25.7719047,
                 5
              ],
              [
                 -80.1503733,
                 25.7718539,
                 5
              ],
              [
                 -80.1499495,
                 25.7718032,
                 5
              ],
              [
                 -80.1496115,
                 25.7717839,
                 5
              ],
              [
                 -80.1492977,
                 25.7717766,
                 4
              ],
              [
                 -80.1489893,
                 25.7718225,
                 5
              ],
              [
                 -80.1487264,
                 25.7718877,
                 5
              ],
              [
                 -80.1484336735276,
                 25.77197517136682
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268764,
           intervalName: 'Interval 2'
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.1484336735276,
                 25.77197517136682
              ],
              [
                 -80.1481605,
                 25.7720568,
                 5
              ],
              [
                 -80.1476562,
                 25.7722452,
                 5
              ],
              [
                 -80.1471332,
                 25.7724481,
                 6
              ],
              [
                 -80.1466101,
                 25.772651,
                 6
              ],
              [
                 -80.1460871,
                 25.7728491,
                 6
              ],
              [
                 -80.1455587,
                 25.773052,
                 3
              ],
              [
                 -80.1450303,
                 25.7732549,
                 1
              ],
              [
                 -80.1445019,
                 25.7734578,
                 0
              ],
              [
                 -80.1439735,
                 25.7736606,
                 0
              ],
              [
                 -80.1434451,
                 25.7738635,
                 1
              ],
              [
                 -80.1429167,
                 25.7740664,
                 1
              ],
              [
                 -80.1424554,
                 25.7742307,
                 2
              ],
              [
                 -80.1423347,
                 25.7742669,
                 3
              ],
              [
                 -80.1422086,
                 25.7742911,
                 3
              ],
              [
                 -80.1420853,
                 25.7743104,
                 4
              ],
              [
                 -80.1418707,
                 25.7743514,
                 5
              ],
              [
                 -80.1415434,
                 25.7743442,
                 6
              ],
              [
                 -80.1412269,
                 25.774349,
                 6
              ],
              [
                 -80.141058,
                 25.7743297,
                 6
              ],
              [
                 -80.1409426,
                 25.7743031,
                 6
              ],
              [
                 -80.14083,
                 25.7742645,
                 6
              ],
              [
                 -80.1407495,
                 25.7741896,
                 6
              ],
              [
                 -80.1406771,
                 25.7740761,
                 6
              ],
              [
                 -80.1405966,
                 25.7739529,
                 7
              ],
              [
                 -80.1404974,
                 25.7738297,
                 7
              ],
              [
                 -80.1402962,
                 25.7735278,
                 8
              ],
              [
                 -80.140087,
                 25.7731945,
                 8
              ],
              [
                 -80.1398322,
                 25.7728201,
                 7
              ],
              [
                 -80.1396042,
                 25.7725061,
                 7
              ],
              [
                 -80.1394916,
                 25.7723177,
                 8
              ],
              [
                 -80.1393306,
                 25.7720761,
                 9
              ],
              [
                 -80.1391563,
                 25.7718322,
                 11
              ],
              [
                 -80.1389632,
                 25.7715544,
                 11
              ],
              [
                 -80.13877,
                 25.7712597,
                 11
              ],
              [
                 -80.1386413,
                 25.7710786,
                 10
              ],
              [
                 -80.1385018,
                 25.7708805,
                 11
              ],
              [
                 -80.138306,
                 25.7705955,
                 12
              ],
              [
                 -80.13807,
                 25.7702428,
                 13
              ],
              [
                 -80.1378071,
                 25.7698781,
                 13
              ],
              [
                 -80.1375443,
                 25.7694796,
                 13
              ],
              [
                 -80.1372412,
                 25.7690617,
                 10
              ],
              [
                 -80.1370776,
                 25.7688153,
                 8
              ],
              [
                 -80.1370507,
                 25.7687042,
                 8
              ],
              [
                 -80.1370239,
                 25.7684989,
                 7
              ],
              [
                 -80.1370078,
                 25.7683225,
                 7
              ],
              [
                 -80.1369622,
                 25.7682356,
                 6
              ],
              [
                 -80.1368415,
                 25.7682066,
                 6
              ],
              [
                 -80.1366994,
                 25.7681776,
                 5
              ],
              [
                 -80.1364285,
                 25.7681849,
                 6
              ],
              [
                 -80.1360261,
                 25.7682018,
                 6
              ],
              [
                 -80.1355782,
                 25.7682235,
                 8
              ],
              [
                 -80.1341754,
                 25.768267,
                 10
              ],
              [
                 -80.1332903,
                 25.7683008,
                 8
              ],
              [
                 -80.1332018,
                 25.7684554,
                 8
              ],
              [
                 -80.1331991,
                 25.7686438,
                 7
              ],
              [
                 -80.1331964,
                 25.7687887,
                 7
              ],
              [
                 -80.1331481,
                 25.7689868,
                 7
              ],
              [
                 -80.1330891,
                 25.7692477,
                 8
              ],
              [
                 -80.1330274,
                 25.7695254,
                 9
              ],
              [
                 -80.1329657,
                 25.7698105,
                 10
              ],
              [
                 -80.1329041,
                 25.7700955,
                 11
              ],
              [
                 -80.1328424,
                 25.7703805,
                 11
              ],
              [
                 -80.1327807,
                 25.7706655,
                 11
              ],
              [
                 -80.1327136,
                 25.7709554,
                 11
              ],
              [
                 -80.1326573,
                 25.771267,
                 12
              ],
              [
                 -80.1325956,
                 25.7715882,
                 12
              ],
              [
                 -80.1325071,
                 25.7719505,
                 12
              ],
              [
                 -80.1324132,
                 25.7723467,
                 12
              ],
              [
                 -80.132263,
                 25.7729215,
                 13
              ],
              [
                 -80.1321423,
                 25.7735689,
                 13
              ],
              [
                 -80.1320136,
                 25.7742427,
                 11
              ],
              [
                 -80.1318312,
                 25.7749432,
                 14
              ],
              [
                 -80.1316488,
                 25.7756437,
                 11
              ],
              [
                 -80.1314932,
                 25.7763538,
                 10
              ],
              [
                 -80.1313376,
                 25.7770639,
                 10
              ],
              [
                 -80.1311901,
                 25.7778005,
                 11
              ],
              [
                 -80.1310346,
                 25.7785565,
                 10
              ],
              [
                 -80.1308575,
                 25.7793222,
                 10
              ],
              [
                 -80.1306912,
                 25.7800878,
                 11
              ],
              [
                 -80.1305249,
                 25.7808486,
                 10
              ],
              [
                 -80.130364,
                 25.7815925,
                 9
              ],
              [
                 -80.1301897,
                 25.7823509,
                 11
              ],
              [
                 -80.1301065,
                 25.7827131,
                 12
              ],
              [
                 -80.1299623,
                 25.7836472,
                 12
              ],
              [
                 -80.1298785,
                 25.7841647,
                 14
              ],
              [
                 -80.1297927,
                 25.7847443,
                 14
              ],
              [
                 -80.1297444,
                 25.7851742,
                 13
              ],
              [
                 -80.1307583,
                 25.785307,
                 11
              ],
              [
                 -80.1318419,
                 25.7854544,
                 8
              ],
              [
                 -80.1318553,
                 25.785563,
                 8
              ],
              [
                 -80.1317963,
                 25.7859615,
                 8
              ],
              [
                 -80.1317346,
                 25.7863697,
                 9
              ],
              [
                 -80.1316917,
                 25.7866547,
                 9
              ],
              [
                 -80.1316971,
                 25.7869904,
                 9
              ],
              [
                 -80.1317078,
                 25.7874372,
                 8
              ],
              [
                 -80.1317266,
                 25.7879033,
                 9
              ],
              [
                 -80.1317453,
                 25.7883791,
                 10
              ],
              [
                 -80.1317641,
                 25.7888669,
                 10
              ],
              [
                 -80.1317775,
                 25.789362,
                 11
              ],
              [
                 -80.1317909,
                 25.7898571,
                 13
              ],
              [
                 -80.1318097,
                 25.7907941,
                 16
              ],
              [
                 -80.1318339,
                 25.7917311,
                 10
              ],
              [
                 -80.1318687,
                 25.7926682,
                 12
              ],
              [
                 -80.1318875,
                 25.7936172,
                 15
              ],
              [
                 -80.13189581631246,
                 25.793976851503498
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268765,
           intervalName: 'Interval 3'
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.13189581631246,
                 25.793976851503498
              ],
              [
                 -80.131909,
                 25.794547,
                 15
              ],
              [
                 -80.1319841,
                 25.7958112,
                 12
              ],
              [
                 -80.1320136,
                 25.7970513,
                 10
              ],
              [
                 -80.1320833,
                 25.7982394,
                 10
              ],
              [
                 -80.1321584,
                 25.7983384,
                 9
              ],
              [
                 -80.1322925,
                 25.798336,
                 9
              ],
              [
                 -80.1325205,
                 25.7982225,
                 9
              ],
              [
                 -80.1329067,
                 25.7980052,
                 9
              ],
              [
                 -80.1333761,
                 25.7977492,
                 9
              ],
              [
                 -80.1338965,
                 25.7974715,
                 9
              ],
              [
                 -80.1344168,
                 25.7971938,
                 9
              ],
              [
                 -80.1345161,
                 25.7971431,
                 9
              ],
              [
                 -80.1346233,
                 25.7971044,
                 9
              ],
              [
                 -80.1346877,
                 25.7971358,
                 9
              ],
              [
                 -80.1347467,
                 25.7972034,
                 9
              ],
              [
                 -80.1347199,
                 25.7973121,
                 9
              ],
              [
                 -80.1346716,
                 25.797457,
                 9
              ],
              [
                 -80.134559,
                 25.7977419,
                 9
              ],
              [
                 -80.1343954,
                 25.7981114,
                 9
              ],
              [
                 -80.1342022,
                 25.798534,
                 9
              ],
              [
                 -80.1339823,
                 25.7990146,
                 9
              ],
              [
                 -80.1337624,
                 25.7994951,
                 9
              ],
              [
                 -80.1336122,
                 25.7998284,
                 9
              ],
              [
                 -80.1335317,
                 25.8002679,
                 8
              ],
              [
                 -80.1334646,
                 25.8008933,
                 7
              ],
              [
                 -80.1333895,
                 25.8015284,
                 8
              ],
              [
                 -80.1333144,
                 25.8021635,
                 9
              ],
              [
                 -80.1332313,
                 25.8028083,
                 9
              ],
              [
                 -80.1331481,
                 25.803453,
                 9
              ],
              [
                 -80.1331267,
                 25.803651,
                 9
              ],
              [
                 -80.1340735,
                 25.8037573,
                 9
              ],
              [
                 -80.1341908,
                 25.8037494,
                 8
              ],
              [
                 -80.1342693,
                 25.803709,
                 8
              ],
              [
                 -80.1343283,
                 25.8036196,
                 8
              ],
              [
                 -80.1343524,
                 25.8034941,
                 8
              ],
              [
                 -80.1343659,
                 25.803325,
                 8
              ],
              [
                 -80.1343927,
                 25.8030715,
                 8
              ],
              [
                 -80.1344275,
                 25.8027745,
                 9
              ],
              [
                 -80.1344785,
                 25.8024437,
                 9
              ],
              [
                 -80.1345268,
                 25.802079,
                 8
              ],
              [
                 -80.1345751,
                 25.8017144,
                 8
              ],
              [
                 -80.1346233,
                 25.8013328,
                 7
              ],
              [
                 -80.1346716,
                 25.8009537,
                 7
              ],
              [
                 -80.1347199,
                 25.8005746,
                 7
              ],
              [
                 -80.1347682,
                 25.800193,
                 8
              ],
              [
                 -80.1348272,
                 25.8000071,
                 9
              ],
              [
                 -80.1350867,
                 25.7994408,
                 8
              ],
              [
                 -80.1353697,
                 25.7988359,
                 9
              ],
              [
                 -80.1355615,
                 25.7984706,
                 7
              ],
              [
                 -80.1358867,
                 25.7977269,
                 7
              ],
              [
                 -80.1362085,
                 25.7970845,
                 8
              ],
              [
                 -80.1365069,
                 25.7964101,
                 7
              ],
              [
                 -80.136692,
                 25.7960231,
                 7
              ],
              [
                 -80.1367423,
                 25.7959428,
                 7
              ],
              [
                 -80.1369381,
                 25.7958149,
                 7
              ],
              [
                 -80.1378286,
                 25.795315,
                 8
              ],
              [
                 -80.1385769,
                 25.7949141,
                 7
              ],
              [
                 -80.139387,
                 25.7944866,
                 7
              ],
              [
                 -80.1402426,
                 25.794023,
                 9
              ],
              [
                 -80.1410982,
                 25.7935593,
                 9
              ],
              [
                 -80.1419833,
                 25.7930521,
                 8
              ],
              [
                 -80.1428524,
                 25.7925498,
                 9
              ],
              [
                 -80.1434961,
                 25.7921924,
                 10
              ],
              [
                 -80.143885,
                 25.7920161,
                 8
              ],
              [
                 -80.1444617,
                 25.7917553,
                 5
              ],
              [
                 -80.144923,
                 25.7916273,
                 4
              ],
              [
                 -80.1456821,
                 25.7915838,
                 2
              ],
              [
                 -80.1465645,
                 25.7915404,
                 5
              ],
              [
                 -80.1472136,
                 25.7915041,
                 6
              ],
              [
                 -80.1479083,
                 25.7914776,
                 6
              ],
              [
                 -80.1488069,
                 25.7914365,
                 10
              ],
              [
                 -80.1497108,
                 25.7913955,
                 10
              ],
              [
                 -80.1506147,
                 25.7913544,
                 4
              ],
              [
                 -80.1515186,
                 25.7913133,
                 2
              ],
              [
                 -80.1524225,
                 25.7912723,
                 2
              ],
              [
                 -80.1533264,
                 25.7912312,
                 3
              ],
              [
                 -80.1542303,
                 25.7911805,
                 7
              ],
              [
                 -80.155153,
                 25.7911443,
                 8
              ],
              [
                 -80.1561132,
                 25.7910936,
                 6
              ],
              [
                 -80.1569635,
                 25.791038,
                 2
              ],
              [
                 -80.1579264,
                 25.790997,
                 5
              ],
              [
                 -80.1588196,
                 25.7909535,
                 9
              ],
              [
                 -80.1597315,
                 25.7909173,
                 9
              ],
              [
                 -80.16019064564333,
                 25.790893194853727
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268766
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.16019064564333,
                 25.790893194853727
              ],
              [
                 -80.1606515,
                 25.790869,
                 6
              ],
              [
                 -80.1615715,
                 25.7908207,
                 5
              ],
              [
                 -80.1625344,
                 25.7907772,
                 9
              ],
              [
                 -80.1635778,
                 25.790741,
                 9
              ],
              [
                 -80.1646453,
                 25.7906854,
                 3
              ],
              [
                 -80.1657182,
                 25.7906226,
                 6
              ],
              [
                 -80.1663297,
                 25.7905937,
                 8
              ],
              [
                 -80.1665631,
                 25.7906226,
                 9
              ],
              [
                 -80.1668715,
                 25.7907144,
                 9
              ],
              [
                 -80.1673061,
                 25.790869,
                 9
              ],
              [
                 -80.1676816,
                 25.7909052,
                 9
              ],
              [
                 -80.1680973,
                 25.7908811,
                 9
              ],
              [
                 -80.1686257,
                 25.790869,
                 10
              ],
              [
                 -80.1691729,
                 25.7908521,
                 10
              ],
              [
                 -80.169543,
                 25.7907845,
                 9
              ],
              [
                 -80.1698461,
                 25.7906613,
                 9
              ],
              [
                 -80.170168,
                 25.7905212,
                 8
              ],
              [
                 -80.1704577,
                 25.7904222,
                 7
              ],
              [
                 -80.1708198,
                 25.7903908,
                 6
              ],
              [
                 -80.1713508,
                 25.7903546,
                 6
              ],
              [
                 -80.1717398,
                 25.7903449,
                 7
              ],
              [
                 -80.1720428,
                 25.7903425,
                 7
              ],
              [
                 -80.1723593,
                 25.7903401,
                 8
              ],
              [
                 -80.1727161,
                 25.790328,
                 8
              ],
              [
                 -80.1731291,
                 25.7903135,
                 9
              ],
              [
                 -80.1735717,
                 25.7902894,
                 9
              ],
              [
                 -80.1743335,
                 25.7902507,
                 9
              ],
              [
                 -80.1758422,
                 25.7901753,
                 9
              ],
              [
                 -80.1771726,
                 25.7900901,
                 9
              ],
              [
                 -80.1777211,
                 25.7900744,
                 8
              ],
              [
                 -80.1781368,
                 25.7900793,
                 8
              ],
              [
                 -80.1784533,
                 25.7900551,
                 8
              ],
              [
                 -80.1787993,
                 25.7900165,
                 9
              ],
              [
                 -80.179199,
                 25.7899875,
                 10
              ],
              [
                 -80.1796255,
                 25.7899802,
                 5
              ],
              [
                 -80.1809116,
                 25.7899205,
                 0
              ],
              [
                 -80.1817659,
                 25.7898667,
                 0
              ],
              [
                 -80.1826295,
                 25.7898305,
                 0
              ],
              [
                 -80.1843837,
                 25.7897484,
                 5
              ],
              [
                 -80.1855545,
                 25.7896959,
                 22
              ],
              [
                 -80.1863216,
                 25.7896627,
                 20
              ],
              [
                 -80.187538,
                 25.7896228,
                 15
              ],
              [
                 -80.1887691,
                 25.7895963,
                 13
              ],
              [
                 -80.190255,
                 25.78956,
                 15
              ],
              [
                 -80.1922439,
                 25.7894966,
                 12
              ],
              [
                 -80.1946297,
                 25.7894393,
                 11
              ],
              [
                 -80.1946881,
                 25.789391,
                 11
              ],
              [
                 -80.1946968,
                 25.7892702,
                 11
              ],
              [
                 -80.1946566,
                 25.7889418,
                 11
              ],
              [
                 -80.1946244,
                 25.7886085,
                 11
              ],
              [
                 -80.1945895,
                 25.7884757,
                 11
              ],
              [
                 -80.1944822,
                 25.7883766,
                 11
              ],
              [
                 -80.1943347,
                 25.7882849,
                 11
              ],
              [
                 -80.1941979,
                 25.7881931,
                 11
              ],
              [
                 -80.1941174,
                 25.7880699,
                 11
              ],
              [
                 -80.1941121,
                 25.7878719,
                 11
              ],
              [
                 -80.1940987,
                 25.7875821,
                 11
              ],
              [
                 -80.1940852,
                 25.7872899,
                 11
              ],
              [
                 -80.1940718,
                 25.7869976,
                 11
              ],
              [
                 -80.1940584,
                 25.786703,
                 11
              ],
              [
                 -80.194045,
                 25.7863721,
                 11
              ],
              [
                 -80.1940316,
                 25.786034,
                 11
              ],
              [
                 -80.1939927,
                 25.7851374,
                 11
              ],
              [
                 -80.1939531,
                 25.7839002,
                 11
              ],
              [
                 -80.1939109,
                 25.7826824,
                 14
              ],
              [
                 -80.1938774,
                 25.7817157,
                 18
              ],
              [
                 -80.1937721,
                 25.7791066,
                 15
              ],
              [
                 -80.1937506,
                 25.7781296,
                 20
              ],
              [
                 -80.1937158,
                 25.777056,
                 18
              ],
              [
                 -80.19364717841187,
                 25.775553663433474
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268767
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.19364717841187,
                 25.775553663433474
              ],
              [
                 -80.1936279,
                 25.7751316,
                 18
              ],
              [
                 -80.1936883,
                 25.7751171,
                 17
              ],
              [
                 -80.1946049,
                 25.7750767,
                 17
              ],
              [
                 -80.1953781,
                 25.7750591,
                 20
              ],
              [
                 -80.1954961,
                 25.775035,
                 20
              ],
              [
                 -80.1955605,
                 25.7749577,
                 21
              ],
              [
                 -80.1955551,
                 25.7747814,
                 20
              ],
              [
                 -80.1955497,
                 25.7745447,
                 19
              ],
              [
                 -80.1955444,
                 25.7742983,
                 18
              ],
              [
                 -80.1955283,
                 25.7741944,
                 18
              ],
              [
                 -80.1954934,
                 25.7741316,
                 18
              ],
              [
                 -80.1953191,
                 25.7741244,
                 18
              ],
              [
                 -80.1949543,
                 25.7741437,
                 18
              ],
              [
                 -80.1936615,
                 25.7741679,
                 25
              ],
              [
                 -80.193581,
                 25.7740809,
                 25
              ],
              [
                 -80.1935729,
                 25.7736745,
                 23
              ],
              [
                 -80.1935582,
                 25.7729849,
                 19
              ],
              [
                 -80.1935367,
                 25.7721915,
                 14
              ],
              [
                 -80.19352,
                 25.7714983,
                 9
              ],
              [
                 -80.1935568,
                 25.7712332,
                 8
              ],
              [
                 -80.1936159,
                 25.7708056,
                 7
              ],
              [
                 -80.1936346,
                 25.7706341,
                 6
              ],
              [
                 -80.1936185,
                 25.7701849,
                 5
              ],
              [
                 -80.1936024,
                 25.7697308,
                 6
              ],
              [
                 -80.1935863,
                 25.7692767,
                 9
              ],
              [
                 -80.1935703,
                 25.7688225,
                 9
              ],
              [
                 -80.193589,
                 25.7686124,
                 9
              ],
              [
                 -80.1936373,
                 25.7684361,
                 9
              ],
              [
                 -80.1937285,
                 25.7682646,
                 9
              ],
              [
                 -80.1938814,
                 25.7681196,
                 9
              ],
              [
                 -80.1941603,
                 25.7679554,
                 8
              ],
              [
                 -80.1945359,
                 25.7677307,
                 9
              ],
              [
                 -80.1946512,
                 25.7677863,
                 9
              ],
              [
                 -80.1947719,
                 25.7679264,
                 10
              ],
              [
                 -80.1949033,
                 25.7681124,
                 10
              ],
              [
                 -80.1949275,
                 25.7681824,
                 10
              ],
              [
                 -80.1948845,
                 25.7682428,
                 10
              ],
              [
                 -80.1948148,
                 25.7682718,
                 10
              ],
              [
                 -80.194678,
                 25.7682791,
                 10
              ],
              [
                 -80.1945305,
                 25.7682863,
                 9
              ],
              [
                 -80.1943642,
                 25.7682984,
                 9
              ],
              [
                 -80.1942569,
                 25.7683757,
                 8
              ],
              [
                 -80.1941442,
                 25.768494,
                 8
              ],
              [
                 -80.1939297,
                 25.768639,
                 9
              ],
              [
                 -80.1937258,
                 25.7688008,
                 9
              ],
              [
                 -80.1935622,
                 25.7688346,
                 9
              ],
              [
                 -80.1933128,
                 25.7688225,
                 10
              ],
              [
                 -80.1922104,
                 25.7688165,
                 12
              ],
              [
                 -80.1921869,
                 25.7683612,
                 12
              ],
              [
                 -80.1903536,
                 25.7679699,
                 18
              ],
              [
                 -80.1904589,
                 25.7675617,
                 19
              ],
              [
                 -80.1905635,
                 25.7671607,
                 23
              ],
              [
                 -80.1908257,
                 25.7662808,
                 29
              ],
              [
                 -80.1908445,
                 25.7660169,
                 33
              ],
              [
                 -80.1909786,
                 25.7654952,
                 33
              ],
              [
                 -80.1911006,
                 25.7650682,
                 30
              ],
              [
                 -80.191291,
                 25.7642965,
                 19
              ],
              [
                 -80.1917329,
                 25.7626967,
                 23
              ],
              [
                 -80.1919757,
                 25.7618199,
                 32
              ],
              [
                 -80.192038,
                 25.7615396,
                 32
              ],
              [
                 -80.1920387,
                 25.7614358,
                 30
              ],
              [
                 -80.1922875,
                 25.7603862,
                 17
              ],
              [
                 -80.1925296,
                 25.7594362,
                 12
              ],
              [
                 -80.1928199,
                 25.7583649,
                 12
              ],
              [
                 -80.1929091,
                 25.7581257,
                 12
              ],
              [
                 -80.1930305,
                 25.7578884,
                 13
              ],
              [
                 -80.1936977,
                 25.7574397,
                 14
              ],
              [
                 -80.1949657,
                 25.7565664,
                 21
              ],
              [
                 -80.1967058,
                 25.755367,
                 14
              ],
              [
                 -80.1974762,
                 25.7548379,
                 16
              ],
              [
                 -80.1988468,
                 25.7538921,
                 15
              ],
              [
                 -80.1998446,
                 25.7532061,
                 15
              ],
              [
                 -80.2009034,
                 25.7525031,
                 12
              ],
              [
                 -80.2028742,
                 25.7510952,
                 15
              ],
              [
                 -80.2029976,
                 25.7509738,
                 15
              ],
              [
                 -80.2029996,
                 25.7508585,
                 15
              ],
              [
                 -80.2035588,
                 25.7504925,
                 14
              ],
              [
                 -80.2036205,
                 25.7504273,
                 14
              ],
              [
                 -80.2036527,
                 25.7503113,
                 15
              ],
              [
                 -80.2036393,
                 25.7502195,
                 15
              ],
              [
                 -80.203591,
                 25.7501422,
                 15
              ],
              [
                 -80.2035213,
                 25.7500576,
                 15
              ],
              [
                 -80.2034542,
                 25.7499731,
                 15
              ],
              [
                 -80.2033737,
                 25.7498837,
                 14
              ],
              [
                 -80.2032584,
                 25.7497581,
                 15
              ],
              [
                 -80.2031377,
                 25.74963,
                 15
              ],
              [
                 -80.2029634,
                 25.7494827,
                 14
              ],
              [
                 -80.2027622,
                 25.7493353,
                 14
              ],
              [
                 -80.2025878,
                 25.7491613,
                 12
              ],
              [
                 -80.2024055,
                 25.7489729,
                 12
              ],
              [
                 -80.2022097,
                 25.7487555,
                 12
              ],
              [
                 -80.2020139,
                 25.748526,
                 12
              ],
              [
                 -80.2017912,
                 25.7483013,
                 12
              ],
              [
                 -80.2012762,
                 25.7478084,
                 8
              ],
              [
                 -80.2009597,
                 25.7475427,
                 7
              ],
              [
                 -80.2005923,
                 25.7473059,
                 6
              ],
              [
                 -80.2002543,
                 25.7470879,
                 6
              ],
              [
                 -80.1998037,
                 25.7468493,
                 10
              ],
              [
                 -80.19910395504904,
                 25.74656708289416
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268768
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.19910395504904,
                 25.74656708289416
              ],
              [
                 -80.198983,
                 25.7465183,
                 16
              ],
              [
                 -80.1984572,
                 25.7463492,
                 13
              ],
              [
                 -80.1978779,
                 25.7462284,
                 11
              ],
              [
                 -80.1972342,
                 25.746139,
                 9
              ],
              [
                 -80.1963007,
                 25.74611,
                 6
              ],
              [
                 -80.1953807,
                 25.7461173,
                 7
              ],
              [
                 -80.1944876,
                 25.746139,
                 9
              ],
              [
                 -80.1935461,
                 25.7461946,
                 7
              ],
              [
                 -80.1925591,
                 25.7463009,
                 6
              ],
              [
                 -80.1916069,
                 25.7464531,
                 4
              ],
              [
                 -80.1906654,
                 25.7465497,
                 3
              ],
              [
                 -80.1897642,
                 25.7465715,
                 3
              ],
              [
                 -80.189563,
                 25.7465461,
                 3
              ],
              [
                 -80.1895148,
                 25.7464265,
                 4
              ],
              [
                 -80.1895148,
                 25.7462647,
                 4
              ],
              [
                 -80.1895684,
                 25.746156,
                 5
              ],
              [
                 -80.1896703,
                 25.7461197,
                 5
              ],
              [
                 -80.1905487,
                 25.7461088,
                 6
              ],
              [
                 -80.1916713,
                 25.7461149,
                 6
              ],
              [
                 -80.1924679,
                 25.7460714,
                 7
              ],
              [
                 -80.1934717,
                 25.7459796,
                 8
              ],
              [
                 -80.1940906,
                 25.7459119,
                 11
              ],
              [
                 -80.1945519,
                 25.7458709,
                 11
              ],
              [
                 -80.1952165,
                 25.7458503,
                 9
              ],
              [
                 -80.1959158,
                 25.7458395,
                 7
              ],
              [
                 -80.1969163,
                 25.7458467,
                 9
              ],
              [
                 -80.1975533,
                 25.7459089,
                 11
              ],
              [
                 -80.198295,
                 25.7460207,
                 13
              ],
              [
                 -80.1990983,
                 25.7462453,
                 17
              ],
              [
                 -80.1999298,
                 25.7465715,
                 11
              ],
              [
                 -80.2008337,
                 25.7469701,
                 7
              ],
              [
                 -80.201472,
                 25.7473615,
                 5
              ],
              [
                 -80.2020487,
                 25.7478133,
                 7
              ],
              [
                 -80.2025288,
                 25.7482312,
                 10
              ],
              [
                 -80.2027488,
                 25.7484342,
                 12
              ],
              [
                 -80.2029821,
                 25.7487361,
                 13
              ],
              [
                 -80.2031806,
                 25.7490478,
                 15
              ],
              [
                 -80.2034193,
                 25.7493715,
                 16
              ],
              [
                 -80.2036956,
                 25.7497049,
                 16
              ],
              [
                 -80.2039638,
                 25.7500238,
                 16
              ],
              [
                 -80.2040952,
                 25.7501422,
                 16
              ],
              [
                 -80.2042776,
                 25.7501977,
                 16
              ],
              [
                 -80.2044654,
                 25.750205,
                 16
              ],
              [
                 -80.2051252,
                 25.7501301,
                 15
              ],
              [
                 -80.206237,
                 25.7500733,
                 14
              ],
              [
                 -80.2064744,
                 25.7500625,
                 13
              ],
              [
                 -80.2065414,
                 25.7500383,
                 12
              ],
              [
                 -80.2067875,
                 25.7498831,
                 12
              ],
              [
                 -80.207381,
                 25.7494198,
                 13
              ],
              [
                 -80.2084351,
                 25.7486842,
                 11
              ],
              [
                 -80.2093524,
                 25.7480736,
                 13
              ],
              [
                 -80.2112192,
                 25.7467587,
                 18
              ],
              [
                 -80.2121392,
                 25.7461221,
                 18
              ],
              [
                 -80.2136546,
                 25.7450803,
                 17
              ],
              [
                 -80.2150836,
                 25.7440843,
                 13
              ],
              [
                 -80.2157092,
                 25.743653,
                 12
              ],
              [
                 -80.2165326,
                 25.7430901,
                 14
              ],
              [
                 -80.2188628,
                 25.7415257,
                 11
              ],
              [
                 -80.2194133,
                 25.74115,
                 11
              ],
              [
                 -80.2202851,
                 25.7405678,
                 13
              ],
              [
                 -80.2217516,
                 25.7397469,
                 12
              ],
              [
                 -80.2223772,
                 25.739425,
                 12
              ],
              [
                 -80.2230075,
                 25.7390964,
                 14
              ],
              [
                 -80.2236459,
                 25.7387774,
                 13
              ],
              [
                 -80.22376916806992,
                 25.73871622557053
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268769
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.22376916806992,
                 25.73871622557053
              ],
              [
                 -80.224303,
                 25.7384513,
                 11
              ],
              [
                 -80.2250353,
                 25.7380695,
                 11
              ],
              [
                 -80.225797,
                 25.7376709,
                 11
              ],
              [
                 -80.2265936,
                 25.737265,
                 13
              ],
              [
                 -80.2271354,
                 25.7369871,
                 12
              ],
              [
                 -80.2276772,
                 25.7366803,
                 12
              ],
              [
                 -80.2281386,
                 25.7363493,
                 14
              ],
              [
                 -80.2286401,
                 25.7359506,
                 13
              ],
              [
                 -80.2291444,
                 25.7355471,
                 13
              ],
              [
                 -80.2296621,
                 25.7351605,
                 13
              ],
              [
                 -80.2302173,
                 25.7347715,
                 13
              ],
              [
                 -80.2307913,
                 25.7343728,
                 12
              ],
              [
                 -80.2313814,
                 25.73395,
                 11
              ],
              [
                 -80.23206,
                 25.7334499,
                 10
              ],
              [
                 -80.2326554,
                 25.7330004,
                 12
              ],
              [
                 -80.2333099,
                 25.7325148,
                 16
              ],
              [
                 -80.2337846,
                 25.7321209,
                 14
              ],
              [
                 -80.2339858,
                 25.7319397,
                 13
              ],
              [
                 -80.2342647,
                 25.7317319,
                 13
              ],
              [
                 -80.234592,
                 25.7314975,
                 15
              ],
              [
                 -80.2349085,
                 25.7312777,
                 16
              ],
              [
                 -80.23518,
                 25.7310638,
                 15
              ],
              [
                 -80.2354885,
                 25.7307135,
                 14
              ],
              [
                 -80.2359311,
                 25.7301891,
                 12
              ],
              [
                 -80.2363642,
                 25.729666,
                 10
              ],
              [
                 -80.2365446,
                 25.7294703,
                 10
              ],
              [
                 -80.2366707,
                 25.7293253,
                 10
              ],
              [
                 -80.2368021,
                 25.7290789,
                 10
              ],
              [
                 -80.2369335,
                 25.7289025,
                 11
              ],
              [
                 -80.2371159,
                 25.7287068,
                 11
              ],
              [
                 -80.2373761,
                 25.7285207,
                 10
              ],
              [
                 -80.237706,
                 25.7282839,
                 12
              ],
              [
                 -80.2380788,
                 25.727994,
                 14
              ],
              [
                 -80.2384758,
                 25.7277088,
                 15
              ],
              [
                 -80.2387393,
                 25.7274829,
                 16
              ],
              [
                 -80.239141,
                 25.7270951,
                 15
              ],
              [
                 -80.2397599,
                 25.7264197,
                 11
              ],
              [
                 -80.2399161,
                 25.7262977,
                 11
              ],
              [
                 -80.2400744,
                 25.7262373,
                 11
              ],
              [
                 -80.2402541,
                 25.7262874,
                 11
              ],
              [
                 -80.2411319,
                 25.7270105,
                 14
              ],
              [
                 -80.2413069,
                 25.7272274,
                 14
              ],
              [
                 -80.2421665,
                 25.7279637,
                 15
              ],
              [
                 -80.2422684,
                 25.7280199,
                 15
              ],
              [
                 -80.2424113,
                 25.7279952,
                 14
              ],
              [
                 -80.2437215,
                 25.7268015,
                 15
              ],
              [
                 -80.2438121,
                 25.7268093,
                 15
              ],
              [
                 -80.2439468,
                 25.7269096,
                 15
              ],
              [
                 -80.2450117,
                 25.7278635,
                 14
              ],
              [
                 -80.2450016,
                 25.7279021,
                 14
              ],
              [
                 -80.2448353,
                 25.7279311,
                 14
              ],
              [
                 -80.2441353,
                 25.727948,
                 14
              ],
              [
                 -80.2434218,
                 25.7279794,
                 13
              ],
              [
                 -80.242601,
                 25.7280229,
                 14
              ],
              [
                 -80.2419707,
                 25.7281051,
                 15
              ],
              [
                 -80.2414048,
                 25.7281268,
                 16
              ],
              [
                 -80.2406645,
                 25.7281317,
                 17
              ],
              [
                 -80.2395111,
                 25.7281558,
                 18
              ],
              [
                 -80.2393448,
                 25.7281728,
                 18
              ],
              [
                 -80.2388808,
                 25.7277185,
                 17
              ],
              [
                 -80.2388272,
                 25.7276798,
                 17
              ],
              [
                 -80.2386662,
                 25.7278055,
                 16
              ],
              [
                 -80.2382746,
                 25.7280833,
                 16
              ],
              [
                 -80.2376845,
                 25.7285159,
                 12
              ],
              [
                 -80.2372822,
                 25.7288469,
                 11
              ],
              [
                 -80.236904,
                 25.7292939,
                 11
              ],
              [
                 -80.2367216,
                 25.7295235,
                 11
              ],
              [
                 -80.2364883,
                 25.7297965,
                 11
              ],
              [
                 -80.2362201,
                 25.7301396,
                 12
              ],
              [
                 -80.235866,
                 25.7305866,
                 15
              ],
              [
                 -80.2354905,
                 25.7310312,
                 17
              ],
              [
                 -80.2352169,
                 25.7312559,
                 17
              ],
              [
                 -80.2346081,
                 25.7316957,
                 17
              ],
              [
                 -80.2339724,
                 25.7321548,
                 15
              ],
              [
                 -80.2335875,
                 25.7324544,
                 16
              ],
              [
                 -80.233568,
                 25.7325311,
                 17
              ],
              [
                 -80.2346134,
                 25.7336685,
                 16
              ],
              [
                 -80.2346094,
                 25.7337126,
                 16
              ],
              [
                 -80.2344612,
                 25.7338117,
                 16
              ],
              [
                 -80.2343144,
                 25.7339325,
                 15
              ],
              [
                 -80.2336646,
                 25.7344574,
                 14
              ],
              [
                 -80.2323369,
                 25.735477,
                 15
              ],
              [
                 -80.2313036,
                 25.7362683,
                 15
              ],
              [
                 -80.2303433,
                 25.7369871,
                 16
              ],
              [
                 -80.229924063915,
                 25.73733262814624
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268770
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.229924063915,
                 25.73733262814624
              ],
              [
                 -80.2296346,
                 25.7375712,
                 16
              ],
              [
                 -80.2288433,
                 25.7383637,
                 17
              ],
              [
                 -80.2283639,
                 25.7387454,
                 17
              ],
              [
                 -80.2275136,
                 25.7391701,
                 15
              ],
              [
                 -80.2258647,
                 25.7400133,
                 16
              ],
              [
                 -80.2246852,
                 25.74061,
                 15
              ],
              [
                 -80.2230585,
                 25.7414297,
                 16
              ],
              [
                 -80.2216986,
                 25.7421068,
                 17
              ],
              [
                 -80.2214599,
                 25.7422215,
                 17
              ],
              [
                 -80.2210736,
                 25.7423598,
                 17
              ],
              [
                 -80.2203897,
                 25.7427923,
                 16
              ],
              [
                 -80.2196018,
                 25.7433317,
                 15
              ],
              [
                 -80.2176196,
                 25.7446732,
                 13
              ],
              [
                 -80.2176726,
                 25.7447487,
                 13
              ],
              [
                 -80.2177906,
                 25.7449117,
                 12
              ],
              [
                 -80.2187562,
                 25.746069,
                 12
              ],
              [
                 -80.2188098,
                 25.7461825,
                 12
              ],
              [
                 -80.2187133,
                 25.7463275,
                 11
              ],
              [
                 -80.2185631,
                 25.7464193,
                 11
              ],
              [
                 -80.2179998,
                 25.7466029,
                 11
              ],
              [
                 -80.2154785,
                 25.7474968,
                 11
              ],
              [
                 -80.2141804,
                 25.7479945,
                 12
              ],
              [
                 -80.2137083,
                 25.7481527,
                 12
              ],
              [
                 -80.2135849,
                 25.7481491,
                 12
              ],
              [
                 -80.2133864,
                 25.7480621,
                 12
              ],
              [
                 -80.2130914,
                 25.7477239,
                 12
              ],
              [
                 -80.2126193,
                 25.7471537,
                 12
              ],
              [
                 -80.2123886,
                 25.7468312,
                 13
              ],
              [
                 -80.2123618,
                 25.746714,
                 14
              ],
              [
                 -80.2124369,
                 25.7466174,
                 14
              ],
              [
                 -80.2129573,
                 25.7462937,
                 14
              ],
              [
                 -80.2128339,
                 25.7461004,
                 15
              ],
              [
                 -80.212689,
                 25.7459941,
                 16
              ],
              [
                 -80.2125764,
                 25.7460037,
                 16
              ],
              [
                 -80.2121311,
                 25.7462453,
                 17
              ],
              [
                 -80.2113909,
                 25.746772,
                 18
              ],
              [
                 -80.2100068,
                 25.7477577,
                 16
              ],
              [
                 -80.2085155,
                 25.7487675,
                 11
              ],
              [
                 -80.2070886,
                 25.7497339,
                 12
              ],
              [
                 -80.2064583,
                 25.7501905,
                 13
              ],
              [
                 -80.2059191,
                 25.7506326,
                 15
              ],
              [
                 -80.2053693,
                 25.7510288,
                 14
              ],
              [
                 -80.2048436,
                 25.7513718,
                 13
              ],
              [
                 -80.2043286,
                 25.7517294,
                 12
              ],
              [
                 -80.2037385,
                 25.7521087,
                 12
              ],
              [
                 -80.203025,
                 25.7526087,
                 13
              ],
              [
                 -80.2022982,
                 25.7531016,
                 13
              ],
              [
                 -80.2015123,
                 25.7536524,
                 12
              ],
              [
                 -80.2007023,
                 25.7542297,
                 15
              ],
              [
                 -80.1998493,
                 25.754795,
                 13
              ],
              [
                 -80.1989964,
                 25.7553821,
                 12
              ],
              [
                 -80.1981434,
                 25.7559691,
                 11
              ],
              [
                 -80.1972905,
                 25.7565537,
                 13
              ],
              [
                 -80.1964509,
                 25.7571335,
                 14
              ],
              [
                 -80.1956168,
                 25.7577084,
                 15
              ],
              [
                 -80.1947826,
                 25.7582858,
                 18
              ],
              [
                 -80.1944473,
                 25.7585104,
                 15
              ],
              [
                 -80.1943079,
                 25.7586191,
                 15
              ],
              [
                 -80.1942891,
                 25.7587133,
                 14
              ],
              [
                 -80.1942328,
                 25.7587906,
                 14
              ],
              [
                 -80.1941711,
                 25.75881,
                 14
              ],
              [
                 -80.1940906,
                 25.7588341,
                 14
              ],
              [
                 -80.1940289,
                 25.7589114,
                 14
              ],
              [
                 -80.1939484,
                 25.7592206,
                 13
              ],
              [
                 -80.19383979170838,
                 25.759623085055367
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 268771
        }
     },
     {
        geometry: {
           type: 'LineString',
           coordinates: [
              [
                 -80.19383979170838,
                 25.759623085055367
              ],
              [
                 -80.1938063,
                 25.7597472,
                 13
              ],
              [
                 -80.1936293,
                 25.760356,
                 13
              ],
              [
                 -80.1934737,
                 25.7609188,
                 13
              ],
              [
                 -80.1933342,
                 25.7614913,
                 14
              ],
              [
                 -80.1932269,
                 25.7617788,
                 16
              ],
              [
                 -80.1931706,
                 25.7620614,
                 17
              ],
              [
                 -80.1931572,
                 25.7624817,
                 17
              ],
              [
                 -80.1931572,
                 25.7628996,
                 15
              ],
              [
                 -80.1931572,
                 25.7633103,
                 12
              ],
              [
                 -80.193176,
                 25.7637499,
                 12
              ],
              [
                 -80.1932028,
                 25.7642137,
                 11
              ],
              [
                 -80.1932135,
                 25.7647089,
                 13
              ],
              [
                 -80.1932403,
                 25.7654336,
                 13
              ],
              [
                 -80.1932779,
                 25.7661679,
                 13
              ],
              [
                 -80.1932859,
                 25.7669046,
                 13
              ],
              [
                 -80.1933315,
                 25.7676534,
                 12
              ],
              [
                 -80.1933691,
                 25.7684192,
                 10
              ],
              [
                 -80.1933879,
                 25.7691655,
                 10
              ],
              [
                 -80.1934227,
                 25.7699119,
                 5
              ],
              [
                 -80.1934415,
                 25.7706583,
                 5
              ],
              [
                 -80.193463,
                 25.7710399,
                 7
              ],
              [
                 -80.1934442,
                 25.7713129,
                 8
              ],
              [
                 -80.1934227,
                 25.7715278,
                 9
              ],
              [
                 -80.1934737,
                 25.7721534,
                 13
              ],
              [
                 -80.1935005,
                 25.7726462,
                 17
              ],
              [
                 -80.1935139,
                 25.7733104,
                 21
              ],
              [
                 -80.1925564,
                 25.7733225,
                 21
              ],
              [
                 -80.1915184,
                 25.7733418,
                 19
              ],
              [
                 -80.1904804,
                 25.773378,
                 26
              ],
              [
                 -80.1894423,
                 25.7734143,
                 39
              ],
              [
                 -80.1884043,
                 25.7734553,
                 31
              ],
              [
                 -80.1875031,
                 25.7734771,
                 20
              ],
              [
                 -80.187149,
                 25.7735012,
                 18
              ],
              [
                 -80.18704662737173,
                 25.773543956175047
              ]
           ]
        },
        properties: {
           athlinksFeatureType: 'INTERVAL',
           eventCourseId: 1157480,
           intervalId: 230078
        }
     },
     {
        type: 'Feature',
        properties: {
           athlinksFeatureType: 'CENTER',
           latitudeDelta: 0.1786643149999989,
           longitudeDelta: 0.12015599999999971
        },
        geometry: {
           type: 'Point',
           coordinates: [
              -80.18737805,
              25.7649973
           ]
        }
     }
  ]
}