import { combineReducers } from 'redux'
import trackingInfo from './trackingInfo'
import appState from './appState'
import mapPreviewReducer from './mapPreviewReducer'

export const combinedReducers =
  combineReducers({
    appState,
    mapPreviewState: mapPreviewReducer,
    trackingInfo
  })
