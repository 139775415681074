import React, { Component } from 'react'
import { connect } from 'react-redux'
import SplitPane from 'react-split-pane'
import { Button } from 'athlinks-component-library'
import { subscribeToTrackingData } from 'athlinks-map-tracking-client'
import { extract, ITrackingExtracted, ITrackingProps as Props } from './extract'

import { updateShowLegend } from '../../actions/updateShowLegendAction'
import AthleteInfo from '../../components/athleteInfo/AthleteInfo'
import { Legend } from '../../components/legend/Legend'
import { Mapbox } from '../../components/maps/Mapbox'
import { RaceHasNotStartedDialog } from '../../components/dialogs/RaceHasNotStartedDialog'
import {
  RacerHasChangedCourseDialog
} from '../../components/dialogs/RacerHasChangedCourseDialog'
import { RacerHasFinishedDialog } from '../../components/dialogs/RacerHasFinishedDialog'
import { IReduxState } from '../../models/reduxState'
import CloseX from '../../svg/CloseX'
import AthlinksLogo from '../../svg/AthlinksLogo'
import { returnURL } from '../../util/urlHelpers'
import { findHotJar, hideHotJar, revealHotJar } from '../../util/hotjarUtils'
import { Redirect } from 'react-router'
import { Routes } from '../../util/routes'

const styles = require('./Tracking.scss')

interface State {
  maximizeAthleteInfo: boolean,
  splitPaneSize: number
}

const SPLIT_PANE_SIZE_DEFAULT = 480,
  SPLIT_PANE_SIZE_MIN = 100

class TrackingComponent extends Component<Props, State> {
  extracted: ITrackingExtracted
  windowHeight: number = 0
  windowWidth: number = 0
  splitPaneResizeTimeout: any

  constructor(props: Props) {
    super(props)
    this.extracted = extract(props)
    this.state = {
      maximizeAthleteInfo: false,
      splitPaneSize: SPLIT_PANE_SIZE_DEFAULT
    }

    this.updateWindowSize = this.updateWindowSize.bind(this)

    this.handleGesture = this.handleGesture.bind(this)
  }

  componentDidMount() {
    const {
      dispatch,
      entryId,
      eventCourseId,
      eventId,
      gettingTrackingInfo,
      hasGottenTrackingInfo
    } = this.extracted

    if (!gettingTrackingInfo && !hasGottenTrackingInfo) {
      const trackingArgs = {
        entryIds: entryId,
        eventCourseId,
        eventId,
        pollingInterval: 15
      }
      dispatch(subscribeToTrackingData(trackingArgs))
    }

    this.updateWindowSize()
    window.addEventListener('resize', this.updateWindowSize)

    document.addEventListener('gesturestart', this.handleGesture)
    document.addEventListener('gesturechange', this.handleGesture)
    document.addEventListener('gestureend', this.handleGesture)
  }

  componentWillUpdate(props: Props) {
    this.extracted = extract(props)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize)

    document.removeEventListener('gesturestart', this.handleGesture)
    document.removeEventListener('gesturechange', this.handleGesture)
    document.removeEventListener('gestureend', this.handleGesture)
  }

  handleGesture(e) {
    e.preventDefault()
  }

  updateWindowSize() {
    const currWidth = this.windowWidth
    const width = window.innerWidth
    this.windowHeight = window.innerHeight
    this.windowWidth = width

    const isLower = currWidth > 800 && width <= 800
    const isGreater = currWidth <= 800 && width > 800

    if (isLower || isGreater || currWidth === 0) {
      this.forceUpdate()
    }
  }

  renderDesktop() {
    const {
      appState,
      dispatch,
      hasGottenTrackingInfo,
      presentationModel,
      returnUrl
    } = this.extracted

    return (
      <div className={styles['container']}>
        <RacerHasFinishedDialog
          appState={appState}
          dispatch={dispatch}
          hasGottenTrackingInfo={hasGottenTrackingInfo}
          model={presentationModel}
          returnUrl={returnUrl}
        />
        <RacerHasChangedCourseDialog
          model={presentationModel}
          returnUrl={
            returnURL(new URL(window.location.href), presentationModel.eventCourseId)
          }
        />
        <RaceHasNotStartedDialog
          appState={appState}
          dispatch={dispatch}
          hasGottenTrackingInfo={hasGottenTrackingInfo}
          model={presentationModel}
          returnUrl={returnUrl}
        />
        <div className={styles['athlete-info']}>
          <AthleteInfo presentationModel={presentationModel} />
        </div>
        <div className={styles.mapContainer}>
          <Mapbox
            appState={appState}
            dispatch={dispatch}
            presentationModel={presentationModel}
          />
        </div>
        {this.renderLegendDesktop()}
        <div className={styles.athlinksLogoContainer}>
          <AthlinksLogo />
        </div>
      </div>
    )
  }

  renderLegendDesktop() {
    const { appState: { showLegend } } = this.extracted
    if (showLegend) {
      return (
        <div className={styles.legend}>
          <Legend
            racerInitials={
              this.extracted.presentationModel.racerInitials
            }
            isMobile={false}
            showAthlete={true}
            showCompletedSegment={true}
            showUpcomingSegment={true}
            showCurrentSegment={true}
            showSplitLabel={true}
            showSplitMarkerPassed={true}
            showSplitMarkerCurrent={true}
            showSplitMarkerUpcoming={true}
          />
        </div>
      )
    }
    return null
  }

  renderLegendMobile() {
    const { appState: { showLegend }, dispatch } = this.extracted
    if (showLegend) {
      return (
        <div className={styles.fullScreen}>
          <Legend
            racerInitials={
              this.extracted.presentationModel.racerInitials
            }
            isMobile={true}
            showAthlete={true}
            showCompletedSegment={true}
            showUpcomingSegment={true}
            showCurrentSegment={true}
            showSplitLabel={true}
            showSplitMarkerPassed={true}
            showSplitMarkerCurrent={true}
            showSplitMarkerUpcoming={true}
          />
          <div className={styles.doneButton}>
            <Button
              color={'dark-blue'}
              onClick={() => dispatch(updateShowLegend(!showLegend))}
              text={'done'}
            />
          </div>
          <div
            className={styles.closeX}
            onClick={() => dispatch(updateShowLegend(!showLegend))}
          >
            <CloseX />
          </div>
        </div>
      )
    }
    return null
  }

  renderMobile() {
    const {
      appState,
      dispatch,
      hasGottenTrackingInfo,
      presentationModel,
      returnUrl
    } = this.extracted

    findHotJar(document)
      .map(this.state.maximizeAthleteInfo ? hideHotJar : revealHotJar)
      .orLazy(() => {
        console.log('unable to find hotjar!')
        return null
      })

    return (
      <div className={styles.fullScreen}>
        <RacerHasFinishedDialog
          appState={appState}
          dispatch={dispatch}
          hasGottenTrackingInfo={hasGottenTrackingInfo}
          isMobile={true}
          model={presentationModel}
          returnUrl={returnUrl}
        />
        <RacerHasChangedCourseDialog
          isMobile={true}
          model={presentationModel}
          returnUrl={
            returnURL(new URL(window.location.href), presentationModel.eventCourseId)
          }
        />
        <RaceHasNotStartedDialog
          appState={appState}
          dispatch={dispatch}
          hasGottenTrackingInfo={hasGottenTrackingInfo}
          isMobile={true}
          model={presentationModel}
          returnUrl={returnUrl}
        />
        {this.state.maximizeAthleteInfo
          ? <div className={styles.fullScreen}>
            <div
              className={styles.closeX}
              style={{ right: '.9375rem' }}
              onClick={() => this.setState({ maximizeAthleteInfo: false })}
            >
              <CloseX />
            </div>
            <AthleteInfo
              presentationModel={presentationModel}
            />
          </div>
          : <SplitPane
            defaultSize={this.windowHeight / 2}
            maxSize={-SPLIT_PANE_SIZE_MIN}
            minSize={SPLIT_PANE_SIZE_MIN}
            primary='first'
            onChange={(splitPaneSize) => {
              if (this.splitPaneResizeTimeout) {
                clearTimeout(this.splitPaneResizeTimeout)
              }
              if (splitPaneSize <= SPLIT_PANE_SIZE_MIN) {
                this.setState({ maximizeAthleteInfo: true })
              }
              else this.splitPaneResizeTimeout = setTimeout(() => {
                this.setState({ splitPaneSize })
              }, 100)
            }}
            pane2Style={{
              height: this.windowHeight - this.state.splitPaneSize
            }}
            resizerClassName={styles.splitPaneResizer}
            split='horizontal'
          >
            <div className={styles.mobileMapContainer}>
              <Mapbox
                appState={appState}
                dispatch={dispatch}
                height={this.state.splitPaneSize}
                presentationModel={presentationModel}
              />
            </div>
            <div className={styles.mobileAthleteContainer}>
              <AthleteInfo
                presentationModel={presentationModel}
              />
            </div>
          </SplitPane>
        }
        {this.renderLegendMobile()}
        {this.state.maximizeAthleteInfo ||
          <div className={styles.athlinksLogoContainer}>
            <AthlinksLogo />
          </div>
        }
      </div>
    )
  }

  render() {
    const {
      failedGettingTrackingInfo,
      hasGottenTrackingInfo,
      incompatibleVersionInfo: {
        incompatibleVersion
      }
    } = this.extracted

    if (incompatibleVersion) {
      return (
        <Redirect to={Routes.VERSION_ERROR + window.location.search} />
      )
    }

    if (failedGettingTrackingInfo) {
      return (
        <div className={styles['loading-container']}>
          <h2 className={styles['text']}>
            Failed to get data for map tracking
          </h2>
        </div>
      )
    }
    if (!hasGottenTrackingInfo) {
      return (
        <div className={styles['loading-container']}>
          <h2 className={styles['text']}>Loading....</h2>
        </div>
      )
    }

    return this.isDesktop() ? this.renderDesktop() : this.renderMobile()
  }

  isDesktop() { return this.windowWidth > 800 }
}

export default connect((state: IReduxState): Partial<Props> => ({
  appState: state.appState,
  dispatch: undefined,
  trackingInfo: state.trackingInfo
}))(TrackingComponent)
