import { ICoordinate, IMapTrackingPresentationModel } from 'athlinks-map-model'

import { IChartData, IChartLineData } from './chartHelpers'

function extractCoordinates(coordinates: ICoordinate[], firstX: number = 0): IChartLineData[] {
  return coordinates.reduce((acc, curr, idx) => {
    const nextCoordinate = coordinates[acc.length + 1] || {elevation: 0}
    const y = curr.elevation || (acc[acc.length - 1] || {}).y || nextCoordinate.elevation || 0
    return acc.concat({x: idx + firstX, y})
  }, [])
}

export function extractElevationData(
  presentationModel: IMapTrackingPresentationModel
): IChartData[] {
  const {
    coordinatesForCompletedIntervals,
    coordinatesForCurrentInterval,
    coordinatesForUpcomingIntervals
  } = presentationModel

  const completedCoordinates = extractCoordinates(coordinatesForCompletedIntervals)
  const currentCoordinates = extractCoordinates(
    coordinatesForCurrentInterval,
    completedCoordinates.length
  )
  const upcomingCoordinates = extractCoordinates(
    coordinatesForUpcomingIntervals,
    completedCoordinates.length + currentCoordinates.length
  )
  const passedAndCurrent = extractCoordinates([].concat(
    coordinatesForCompletedIntervals,
    coordinatesForCurrentInterval
  ))

  return [
    {type: 'area', data: passedAndCurrent},
    {type: 'solid', data: completedCoordinates},
    {type: 'dotted', data: currentCoordinates},
    {type: 'grey', data: upcomingCoordinates}
  ]
}
