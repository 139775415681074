import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Cell, Grid, GridContainer } from 'react-foundation'
import { Button } from 'athlinks-component-library'
import mapError from '../../shared/images/map_error.svg'
import { IReduxState } from '../../models/reduxState'
import { IIncompatibleVersionInfo } from '../../models/trackingInfo'
import { extractQueryParams } from '../../util/urlHelpers'

const styles = require('./versionError.scss')

class VersionError extends Component<IIncompatibleVersionInfo> {
  referrer: string = ''

  constructor(props: IIncompatibleVersionInfo) {
    super(props)
    const queryParams = extractQueryParams({location: window.location})
    this.referrer = decodeURIComponent((queryParams.referrer || '').toString())
  }

  renderFooter() {
    return (
      <div className={styles['footer-container']}>
      </div>
    )
  }

  renderContent() {
    return (
      <div className={styles['error-header']}>
        <div className={styles['grid-container-wrapper']}>
          <div className={styles['grid-container']}>
            <GridContainer>
              <Grid>
                <Cell medium={12}>
                  <img className={styles['map-error']} src={mapError} />
                </Cell>
                <Cell medium={12}>
                  <div className={styles['error-title']}>
                    The map for this race is out of date.
                  </div>
                  <div className={styles['error-subtitle']}>
                    Please contact the race director to update.
                  </div>
                </Cell>
                <Cell medium={12}>
                  <div data-testid='go-back-btn'
                    className={styles['go-back-btn-container']}>
                    {!this.referrer
                      ? null
                      : this.referrer === 'IRP'
                        ? <Button
                          target='_self'
                          text='Go Back'
                          onClick={() => window.close()}
                          color='dark-blue' />
                      : <Button
                          target='_self'
                          text='Go Back'
                          route={this.referrer}
                          color='dark-blue' />
                    }
                  </div>
                </Cell>
              </Grid>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default connect(({
  trackingInfo: {
    incompatibleVersionInfo: {
      incompatibleVersion,
      error
    }
  }
}: IReduxState): Partial<IIncompatibleVersionInfo> => ({
  incompatibleVersion,
  error
}))(VersionError)