import { Action } from 'redux'
import { actionTypes } from './types'

export interface IUpdateRacerHasFinishedDismissed extends Action {
  type: actionTypes.UPDATE_RACER_HAD_FINISHED_DISMISSED,
  dismissed: boolean
}

export const updateRacerHasFinishedDismissed = (
  dismissed: boolean
): IUpdateRacerHasFinishedDismissed => ({
  type: actionTypes.UPDATE_RACER_HAD_FINISHED_DISMISSED,
  dismissed
})
