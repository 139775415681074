export interface IChartLineData {
  x: number
  y: number
}
export interface IChartData {
  type: 'solid' | 'dotted' | 'grey' | 'area'
  data: IChartLineData[]
}

export function getAdjustedMax(max: number, divisibleBy: number): number {
  if (max % divisibleBy !== 0) {
    return Math.ceil(max / divisibleBy) * divisibleBy
  }
  return max
}

export function getAdjustedMin(min: number, divisibleBy: number): number {
  if (min % divisibleBy !== 0) {
    const adjusted = Math.floor(min / divisibleBy) * divisibleBy
    return adjusted >= 0 ? adjusted : 0
  }
  return min
}

export function getRange(min: number, max: number, rangeTolerance: number): [number, number] {
  const rangeDiff = max - min
  return rangeDiff >= rangeTolerance ? [min, max] : [min, min + rangeTolerance]
}

export function getTickValues(max: number, min: number, numberOfTicks: number): number[] {
  const diff = max - min
  const sectionValue = diff / numberOfTicks
  return Array.apply(null, Array(numberOfTicks)).map((_, idx) => {
    return ((idx + 1) * sectionValue) + min
  })
}