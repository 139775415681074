import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import * as locales from './locales/'
console.log('locales', locales)

const translate =
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      debug: true,
      resources: (locales as unknown) as i18n.Resource,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    })

export default translate
