
import {
  ICoordinate,
  IMapTrackingPresentationModel
} from 'athlinks-map-model'

export const getLineString = (id, coordinates, lineColor, lineWidth) => {
  return {
    'id': id,
    'type': 'line',
    'source': {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': coordinates
        }
      }
    },
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': lineColor,
      'line-width': lineWidth,
      'line-opacity': .8
    }
  }
}

export const mapToLongLat = (coordinate: ICoordinate) => [coordinate.long, coordinate.lat]

export const getCompletedSplitsLine = (model: IMapTrackingPresentationModel) => {
  const currentCoordinates = model.coordinatesForCompletedIntervals.map(mapToLongLat)
  return getLineString('past-split', currentCoordinates, '#16A9E1', 8)
}

export const lineString = (
  width: number,
  color: string
) => (
  name: string
) => (
  coords: ICoordinate[]
) => getLineString(
  name,
  coords.map(mapToLongLat),
  color,
  width)

export const getCurrentSplitLine = (model: IMapTrackingPresentationModel) => {
  const currentCoordinates = model.coordinatesForCurrentInterval.map(mapToLongLat)
  return getLineString('current-split', currentCoordinates, '#73cbed', 6)
}

export const getUpcomingSplitsLine = (model: IMapTrackingPresentationModel) => {
  const currentCoordinates = model.coordinatesForUpcomingIntervals.map(mapToLongLat)
  return getLineString('upcoming-split', currentCoordinates, '#DDDDDD', 4)
}
